import React, { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Context } from '../../../store';
import {Row, Col, Statistic, Card, Tabs, Button} from 'antd';
import { singleCurrencyAllCandlestickFilter, singleCurrencyAllPositionFilter, allCurrencyCandlestickAnalysis, singleCurrencyCandlestickAnalysis } from '../../../libs/analysis';
import {positionTypes} from '../../../libs/enums';
import CurrencyHistoricalTable from '../../../components/CurrencyHistoricalTable';
import CurrencyInfo from '../../../components/CurrencyInfo';
import CurrencyNews from '../../../components/CurrencyNews';
import './style.scss';
import CurrencyAllPositionTable from '../../../components/CurrencyAllPositionTable';
import AnalysisLoading from '../../../components/AnalysisLoading';
import { binanceService } from '../../../services';

const {TabPane} = Tabs;

const FuturesDetails2 = () => {
    let {id} = useParams();

    const {state} = useContext(Context);
    const [currencyInfo, setCurrenyInfo] = useState({});
    const [currencyDeals, setCurrencyDeals] = useState([]);
    const [totalValues, setTotalValues] = useState({
        totalPosProcess : 0,
        totalNegProcess : 0,
        totalProfits : 0,
        totalLoss: 0,
        mostProfit: 0,
        mostLoss: 0
    });
    const [currencyAllPositions, setCurrencyAllPositions] = useState([]);
    const [activePosition, setActivePosition] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [currencyAllKlines, setCurrencyAllKlines] = useState([]);

    const startToFecthData = (symbol, startDate) => {
        binanceService.getFuturesKlinesWithDate(symbol, "5m", '378', startDate)
            .then(res => {
                //res.splice(res.length - 1, 1);
                //allSymbolCandleStickList[state.symbols[index].symbol] = res;
                console.log(res.length);
                if(res.length > 0) {
                    singleCurrencyCandlestickAnalysis(res).then(data => {
                        //console.log('Then içinden gelen data', data);
                        setCurrencyAllKlines(prevState => ([
                            ...prevState,
                            ...data
                        ]));      
                    });
  
                }
                else {
                    singleCurrencyAllCandlestickFilter(symbol, currencyAllKlines, activePosition).then(data => {
                        //console.log('CURRENCY DEALS', data);
                        setCurrencyDeals(data);
                    });
                }
            });
    }

    useEffect(() => {
        //console.log('Currency All Klines', currencyAllKlines);
        const symbol = `${id.toUpperCase()}USDT`;
        if(currencyAllKlines.length > 0) {
            //const newDate = currencyAllKlines[currencyAllKlines.length - 1].candlestick[6] + 1;
            //startToFecthData(symbol, newDate);
            singleCurrencyAllCandlestickFilter(symbol, currencyAllKlines, activePosition).then(data => {
                //console.log('CURRENCY DEALS', data);
                setCurrencyDeals(data);
            });
        }

    }, [currencyAllKlines, id]);

    useEffect(() => {
        const startDate = 1645979400000;//1609448400000;
        //const symbol = `${id.toUpperCase()}USDT`;

        const cInfo = state.symbols.find(x => x.baseAsset === id.toUpperCase());
        setCurrenyInfo(cInfo);
        const activePos = positionTypes.find(x => x.name === cInfo.positionModel)
        setActivePosition(activePos);

        startToFecthData(cInfo.symbol, startDate);
    }, [state.symbols, id]);

    /*
    useEffect(() => {
        const cInfo = state.symbols.find(x => x.baseAsset === id.toUpperCase());
        //console.log(cInfo);
        setCurrenyInfo(cInfo);
        const activePos = positionTypes.find(x => x.name === cInfo.positionModel)
        setActivePosition(activePos);
        const cKlines = state.kLinesList[cInfo.symbol];
        


        //console.log(cKlines);
        singleCurrencyAllCandlestickFilter(cInfo.symbol, cKlines, activePos).then(data => {
            //console.log('CURRENCY DEALS', data);
            setCurrencyDeals(data);
            
        });
    }, [state.symbols, id, state.kLinesList]);
*/


    useEffect(() => {
        if(currencyDeals.length > 0) {
            let posArray = currencyDeals.filter(x => x.pnl > 0);
            let negArray = currencyDeals.filter(x => x.pnl < 0);
            setTotalValues({
                totalPosProcess: posArray.length,
                totalNegProcess: negArray.length,
                totalProfits: posArray.length > 0 ? posArray.length === 1 ? posArray[0].pnl : posArray.map(x => x.pnl).reduce((a, b) => a + b).toFixed(2) : 0,
                totalLoss: negArray.length > 0 ? negArray.length === 1 ? negArray[0].pnl : negArray.map(x => x.pnl).reduce((a, b) => a + b).toFixed(2) : 0,
                mostProfit: posArray.length > 0 ? posArray.length === 1 ? posArray[0].pnl : posArray.map(x => x.pnl).reduce((a, b) => b > a ? b : a).toFixed(2) : 0,
                mostLoss: negArray.length > 0 ? negArray.length === 1 ? negArray[0].pnl : negArray.map(x => x.pnl).reduce((a, b) => b > a ? a : b).toFixed(2) : 0,
            });
        }

    }, [currencyDeals]);

    const tabOnChange = (activeKey) => { 
        if(activeKey === "2" && currencyAllPositions.length === 0) {
            // burası yenilenmiyor fonksiyona ayır ve kLinesList değişirse buda yenilensin
            setIsLoading(true);
            setTimeout(() => {
                singleCurrencyAllPositionFilter(currencyInfo.symbol, currencyAllKlines, state.analysisModel).then((data) => {
                    //console.log('son 24 saattir aktif devam eden fırsatları göster');
                    //console.log('LAST 24 HOURS DEALS', data);
                    setCurrencyAllPositions(data);
                    setIsLoading(false);
                });
            }, 1000);
        }
    }

    const getSelectedPositionType = (pos) => {
        singleCurrencyAllCandlestickFilter(currencyInfo.symbol, currencyAllKlines, pos).then(data => {
            //console.log('CURRENCY DEALS', data);
            setCurrencyDeals(data);
            setActivePosition(pos);
        });
    }

    const positionTypeButtons = positionTypes.map((pos, i) => {
        return <Button key={i} type='default' className={activePosition.name === pos.name ? "active" : ""} onClick={() => getSelectedPositionType(pos)}>
            {pos.name}
        </Button>
    }); 

    return (
        <>
            {isLoading && <AnalysisLoading current={0} total={0} spin={true} />}
            <div className='futures-detail container'>
                <Row gutter="16">
                    <Col span={8}>
                        <CurrencyInfo currency={currencyInfo} />
                    </Col>
                    <Col span={16}>
                        <CurrencyNews symbolName={currencyInfo.name} />
                    </Col>
                    <Col span={24} style={{marginBottom: 20}}>
                        <Row gutter={16}>
                            <Col span={8}>
                                <Card title="Total Position">
                                    <Statistic 
                                        title="Profit"
                                        value={totalValues.totalPosProcess}
                                        precision={0}
                                        valueStyle={{ color: '#3f8600' }}
                                        prefix={''}
                                        suffix="Process"
                                        />
                                    <Statistic
                                        title="Loss"
                                        value={totalValues.totalNegProcess}
                                        precision={0}
                                        valueStyle={{ color: '#cf1322' }}
                                        prefix={''}
                                        suffix="Process"
                                        />
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card title="Total Profit / Loss">
                                    <Statistic 
                                        title="Profit"
                                        value={totalValues.totalProfits}
                                        precision={2}
                                        valueStyle={{ color: '#3f8600' }}
                                        prefix={''}
                                        suffix="%"
                                        />
                                    <Statistic
                                        title="Loss"
                                        value={totalValues.totalLoss}
                                        precision={2}
                                        valueStyle={{ color: '#cf1322' }}
                                        prefix={''} 
                                        suffix="%"
                                        />
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card title="Most Profit / Loss">
                                    <Statistic 
                                        title="Profit"
                                        value={totalValues.mostProfit}
                                        precision={2}
                                        valueStyle={{ color: '#3f8600' }}
                                        prefix={''}
                                        suffix="%"
                                        />
                                    <Statistic
                                        title="Loss"
                                        value={totalValues.mostLoss}
                                        precision={2}
                                        valueStyle={{ color: '#cf1322' }}
                                        prefix={''}
                                        suffix="%"
                                        />
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <div className="deals">
                            <Tabs defaultActiveKey="1" type="card" size={"large"} onChange={tabOnChange}>
                                <TabPane tab="Current Deals" key="1">
                                    <div className="position-buttons">
                                        {positionTypeButtons}
                                    </div>
                                    {currencyDeals && <CurrencyHistoricalTable data={currencyDeals} />}
                                </TabPane>
                                <TabPane tab="All Positions Pair Type" key="2">
                                    {currencyAllPositions && <CurrencyAllPositionTable data={currencyAllPositions} />}
                                </TabPane>
                            </Tabs>
                        </div>                     
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default FuturesDetails2;

/**
                 <Col span={24}>
                    {currencyDeals && <CurrencyHistoricalTable data={currencyDeals} />}
                </Col>
 */