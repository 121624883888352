import {basePoint, cocTotal, fibonacci, coc, positionTypes, analysisModel} from './enums';
import { composition, std } from 'mathjs';

let beforeEMAs = {
    ema5: null,
    ema8: null,
    ema13: null,
    ema21: null,
    ema34: null,
    ema50: null,
    ema55: null,
    ema89: null,
    ema100: null,
    ema144: null,
    ema233: null,
    ema377: null,
    highEma8: null,
    highEma13: null,
    lowEma8: null,
    lowEma13: null
}

let prevAvg = {}
let bottomPrice = 0;
let topPrice = 0;

let rsiValue = 14;
let spotPrevKlines = 30;

export const allCurrencyCandlestickAnalysis = async (symbols, symbolList, analysisModel) => {
    const newMaObj = {}
    
    Object.keys(symbols).map((symbol, i) => {
        newMaObj[symbol] = symbols[symbol].map((kline, ix) => {
            let newObj = {};
            newObj.candlestick = [...kline].splice(0, 7);

            fibonacci.map(fib => {
                newObj[`ma${fib}`] = ix >= fib ? movingAverage([...symbols[symbol]].splice((ix + 1) - fib, fib)) : null;
            
                if(ix === fib) {
                    beforeEMAs[`ema${fib}`] = newObj[`ma${fib}`];
                }

                if(ix >= fib + 1) {
                    newObj[`ema${fib}`] = expMovingAverage(beforeEMAs[`ema${fib}`], kline, fib);  
                    
                    beforeEMAs[`ema${fib}`] = newObj[`ema${fib}`];
                }
                else { 
                    newObj[`ema${fib}`] = null;
                }           
            });
        
            // CALCULATE RSI VALUE
            if(ix >= rsiValue) {
                if(ix === rsiValue) {
                    const redGreenAvg = firstRsiAvgCalculate([...symbols[symbol]].splice((ix + 1) - (rsiValue + 1), rsiValue + 1));
                    prevAvg["prevGreenAvg"] = redGreenAvg.greenAvg;
                    prevAvg["prevRedAvg"] = redGreenAvg.redAvg;
                    //console.log('firstPrevAvg:', prevAvg["prevGreenAvg"], prevAvg["prevRedAvg"]);
                }
                else {
                    const rsiObj = relativeStrengthIndex(prevAvg.prevGreenAvg, prevAvg.prevRedAvg, kline, [...symbols[symbol]][ix - 1]);
                    prevAvg["prevGreenAvg"] = rsiObj.greenAvg;
                    prevAvg["prevRedAvg"] = rsiObj.redAvg;
                    newObj.greenAvg = rsiObj.greenAvg;
                    newObj.redAvg = rsiObj.redAvg;
                    newObj.rsi = rsiObj.rsi;
                    //console.log('prevAvg:', prevAvg["prevGreenAvg"], prevAvg["prevRedAvg"]);
                }           
            }

            // CALCULATE BOLLINGER BANDS
            if(ix >= 21) {
                const calculate21forBoll = [...symbols[symbol]].splice((ix + 1) - 21, 21).map(x => Number(x[4]));

                const fixedValue = kline[4].split('.')[1].length;
                //console.log('ma21: ', newObj.ma21);
                newObj.bollMiddle = newObj.ma21;
                newObj.bollTop = Number((newObj.ma21 + std(calculate21forBoll, 'uncorrected') * 2).toFixed(fixedValue));
                newObj.bollBottom = Number((newObj.ma21 - std(calculate21forBoll, 'uncorrected') * 2).toFixed(fixedValue));
                newObj.bollBandwidth = Number((newObj.bollTop - newObj.bollBottom).toFixed(fixedValue));
            }

            /*
            if(bottomPrice === 0) {
                bottomPrice = newObj.candlestick[4];
                newObj.bottomPrice = bottomPrice; 
            }
            else if(newObj.candlestick[4] < bottomPrice) {
                bottomPrice = newObj.candlestick[4];
                newObj.bottomPrice = bottomPrice;                 
            }
            else if (newObj.candlestick[4] > bottomPrice) {
                let calcPerc = Number(((newObj.candlestick[4] - bottomPrice) * 100 / bottomPrice).toFixed(2));
                if(calcPerc >= 2) {
                    newObj.bottomPrice = bottomPrice;
                    newObj.topPrice = newObj.candlestick[4];
                }
                else {
                    newObj.bottomPrice = bottomPrice;
                }          
            }
            */

            return newObj;
        });

        return true;
    });

    const newDataObj = {};
    const allPastPositionsList = [];

    Object.keys(newMaObj).map((symbol, i) => {
        let prevKline = [];
        let twoPrevKline = [];
        let prevKlines = [];
        let beforeComparisonResult = '';

        let currentDeal = {
            key: symbol,
            status: '',
            symbol: symbol,
            openprice: null,
            highprice: null,
            closeprice: null,
            opendate: null,
            closedate:  null,
            posType: null,
            closeType: null
        };

        newDataObj[symbol] = newMaObj[symbol].map((kline, ix) => {
            let newObj = {};
            prevKline = newMaObj[symbol][ix - 1];
            twoPrevKline = newMaObj[symbol][ix - 2];
            prevKlines = analysisModel === "zombieSpot" ? [...newMaObj[symbol]].splice(ix - spotPrevKlines, spotPrevKlines): [...newMaObj[symbol]].splice(ix - 5, 5);

            if(ix > 200) {
                let comparisonResult = calculatePositions(kline, prevKlines, beforeComparisonResult, currentDeal, analysisModel);

                beforeComparisonResult = comparisonResult.position;            
                
                if(currentDeal.status === "LONG") {
                    currentDeal.candleCount++;

                    if(currentDeal.openprice) {
                        const closeMinusPerc = (Number(kline.candlestick[4]) - currentDeal.openprice) * 100 / currentDeal.openprice;
                        let tempCloseReverse = currentDeal.closeReverse;
                        currentDeal.closeReverse = closeMinusPerc < tempCloseReverse ? Number(closeMinusPerc.toFixed(2)) : currentDeal.closeReverse;
                        currentDeal.closeReverseIndex = closeMinusPerc < tempCloseReverse ? currentDeal.candleCount : currentDeal.closeReverseIndex;
    
                        const maxMinusPerc = (Number(kline.candlestick[3]) - currentDeal.openprice) * 100 / currentDeal.openprice;
                        let tempMaxReverse = currentDeal.maxReverse;
                        currentDeal.maxReverse = maxMinusPerc < tempMaxReverse ? Number(maxMinusPerc.toFixed(2)) : currentDeal.maxReverse;
                        currentDeal.maxReverseIndex = maxMinusPerc < tempMaxReverse ? currentDeal.candleCount : currentDeal.maxReverseIndex;

                        currentDeal.highpriceIndex = Number(kline.candlestick[4]) > currentDeal.highprice ? currentDeal.candleCount : currentDeal.highpriceIndex;
                        currentDeal.highprice = Number(kline.candlestick[4]) > currentDeal.highprice ? Number(kline.candlestick[4]) : currentDeal.highprice;
                        currentDeal.isAboveMiddle = currentDeal.isAboveMiddle || kline.candlestick[4] > kline.bollMiddle;
                        currentDeal.topNeedleIndex = Number(kline.candlestick[2]) > currentDeal.topNeedle ? currentDeal.candleCount : currentDeal.topNeedleIndex;
                        currentDeal.topNeedle = Number(kline.candlestick[2]) > currentDeal.topNeedle ? Number(kline.candlestick[2]) : currentDeal.topNeedle;
                        if(currentDeal.candleCount === 1) {
                            currentDeal.firstReverse = maxMinusPerc < tempMaxReverse ? Number(maxMinusPerc.toFixed(2)) : currentDeal.maxReverse;
                        } 
                    }
                }
                else if (currentDeal.status === "SHORT") {
                    if(currentDeal.openprice) {
                        const closeMinusPerc = (Number(kline.candlestick[4]) - currentDeal.openprice) * 100 / currentDeal.openprice;
                        currentDeal.closeReverse = -1 * closeMinusPerc < currentDeal.closeReverse ? Number((-1 * closeMinusPerc).toFixed('2')) : currentDeal.closeReverse;
    
                        const maxMinusPerc = (Number(kline.candlestick[2]) - currentDeal.openprice) * 100 / currentDeal.openprice;
                        currentDeal.maxReverse = -1 * maxMinusPerc < currentDeal.maxReverse ? Number((-1 * maxMinusPerc).toFixed('2')) : currentDeal.maxReverse;
    
                        currentDeal.highprice = Number(kline.candlestick[4]) < currentDeal.highprice ? Number(kline.candlestick[4]) : currentDeal.highprice;
                        currentDeal.isBelowMiddle = currentDeal.isBelowMiddle || kline.candlestick[4] < kline.bollMiddle;
                        currentDeal.topNeedle = Number(kline.candlestick[2]) < currentDeal.topNeedle ? Number(kline.candlestick[2]) : currentDeal.topNeedle;    
                    }
                }

                if(currentDeal.status !== comparisonResult.position) {     
            
                    if(currentDeal.status !== '' && comparisonResult.position === "CLOSE") {
                        currentDeal.key = ix + symbol;
                        currentDeal.closeprice = Number(kline.candlestick[4]);
                        currentDeal.closedate = kline.candlestick[6];
                        currentDeal.time = (new Date(kline.candlestick[6]).getTime() - new Date(currentDeal.opendate).getTime()) / (1000 * 60 * 60);

                        let pnl = Number(((kline.candlestick[4] - currentDeal.openprice) * 100 / currentDeal.openprice).toFixed(2));
                        pnl = pnl < -5 ? -5 : pnl;

                        currentDeal.pnl = currentDeal.status === "SHORT" ? -1 * pnl : pnl; 
                        currentDeal.closeType = comparisonResult.closeType;
                        currentDeal.maxProfit = Number(((currentDeal.highprice - currentDeal.openprice) * 100 / currentDeal.openprice).toFixed(2));
                        currentDeal.highProfit = Number(((currentDeal.topNeedle - currentDeal.openprice) * 100 / currentDeal.openprice).toFixed(2));
    
                        // all position object push
                        allPastPositionsList.push({...currentDeal});
                        currentDeal.status = "CLOSE";
                        currentDeal.openprice = null;
                        currentDeal.highprice = null;
                        currentDeal.highpriceIndex = 0;
                        currentDeal.topNeedle = null;
                        currentDeal.topNeedleIndex = 0;
                        currentDeal.posType = null;
                        currentDeal.closeReverse = 0;
                        currentDeal.closeReverseIndex = 0;
                        currentDeal.maxReverse = 0;
                        currentDeal.maxReverseIndex = 0;
                        currentDeal.firstReverse = 0;
                        currentDeal.candleCount = 0;                        
                    }
    
                    if(comparisonResult.position === "LONG" || comparisonResult.position === "SHORT") {
                        currentDeal.closeType = null;
                        currentDeal.status = comparisonResult.position;
                        currentDeal.posType = comparisonResult.posType;
                        currentDeal.openprice = Number(kline.candlestick[4]);
                        currentDeal.highprice = Number(kline.candlestick[4]);
                        currentDeal.topNeedle = Number(kline.candlestick[4]);
                        currentDeal.closeprice = null;
                        currentDeal.opendate = kline.candlestick[6];
                        currentDeal.closedate = null;
                        currentDeal.time = null;
                        currentDeal.maxProfit = null;
                        currentDeal.highProfit = null;
                        currentDeal.candleCount = 0;
                        currentDeal.closeReverse = 0;
                        currentDeal.closeReverseIndex = 0;
                        currentDeal.maxReverse = 0;
                        currentDeal.maxReverseIndex = 0;
                        currentDeal.firstReverse = 0;
                        currentDeal.pnl = null;
                        currentDeal.openBollTop = kline.bollTop;
                        currentDeal.openBollBottom = kline.bollBottom;
                        currentDeal.openBollBandwidth = kline.bollBandwidth;
                        currentDeal.isAboveMiddle = comparisonResult.isAboveMiddle;
                        currentDeal.isBelowMiddle = comparisonResult.isBelowMiddle;                        
                    }
                }

                if(ix === newMaObj[symbol].length - 1 && currentDeal.status !== "CLOSE" && currentDeal.status !== '') {
                    currentDeal.key = kline.candlestick[6] + symbol; 
                    currentDeal.closeprice = Number(kline.candlestick[4]);
                    currentDeal.closedate = kline.candlestick[6];
                    currentDeal.time = (new Date(kline.candlestick[6]).getTime() - new Date(currentDeal.opendate).getTime()) / (1000 * 60 * 60);
                    
                    let pnl = Number(((kline.candlestick[4] - currentDeal.openprice) * 100 / currentDeal.openprice).toFixed(2));
    
                    pnl = pnl < -5 ? -5 : pnl;
                    currentDeal.pnl = currentDeal.status === "SHORT" ? -1 * pnl : pnl; 
                    currentDeal.maxProfit = Number(((currentDeal.highprice - currentDeal.openprice) * 100 / currentDeal.openprice).toFixed(2));
                    currentDeal.highProfit = Number(((currentDeal.topNeedle - currentDeal.openprice) * 100 / currentDeal.openprice).toFixed(2));
    
                    currentDeal.closeType = "NC";
    
                    allPastPositionsList.push({...currentDeal});           
                }

                newObj.position = {
                    status: currentDeal.status,
                    openDate: currentDeal.opendate,
                    openPrice: currentDeal.openprice,
                    highPrice: currentDeal.highprice,
                    highPriceIndex: currentDeal.highpriceIndex,
                    posType: currentDeal.posType,
                    closeType: currentDeal.closeType,
                    candleCount: currentDeal.candleCount,
                    closeReverse: currentDeal.closeReverse,
                    closeReverseIndex: currentDeal.closeReverseIndex,
                    maxReverse: currentDeal.maxReverse,
                    maxReverseIndex: currentDeal.maxReverseIndex,
                    isAboveMiddle: currentDeal.isAboveMiddle,
                    topNeedle: currentDeal.topNeedle,
                    topNeedleIndex: currentDeal.topNeedleIndex,
                    firstReverse: currentDeal.firstReverse
                }
            }

            return {...kline, ...newObj};
        });

        return true;
    });

    return {candlesticks: newDataObj, positions: allPastPositionsList };
}

export const allCurrencyLastCandlestickAnalysis = async (symbols, lastKlines, symbolList, analysisModel, positions) => {
    // güncellenen yeni mmumların eski liste ile analiz edilip döndürülmesi.
    const newSingleObj = {};

    Object.keys(lastKlines).map((symbol, i) => {
        let newObj = {};

        newObj.candlestick = [...lastKlines[symbol]].splice(0, 7);

        fibonacci.map(fib => { 
            newObj[`ma${fib}`] = movingAverage([...symbols[symbol]].splice(symbols[symbol].length - (fib - 1), (fib - 1)), newObj.candlestick, symbol);
            newObj[`ema${fib}`] = expMovingAverage(symbols[symbol][symbols[symbol].length - 1][`ema${fib}`], newObj.candlestick, fib);
        }); 
   
        const prevKline = symbols[symbol][symbols[symbol].length - 1];

        // CALCULATE RSI VALUE
        const rsiObj = relativeStrengthIndex(prevKline.greenAvg, prevKline.redAvg, newObj.candlestick, prevKline.candlestick);
        newObj.greenAvg = rsiObj.greenAvg;
        newObj.redAvg = rsiObj.redAvg;
        newObj.rsi = rsiObj.rsi;

        // CALCULATE BOLLINGER BANDS
        const calculate21forBoll = [...symbols[symbol]].splice(symbols[symbol].length - 21, 21).map(x => Number(x.candlestick[4]));

        const fixedValue = newObj.candlestick[4].split('.')[1].length;
        newObj.bollMiddle = newObj.ma21;
        newObj.bollTop = Number((newObj.ma21 + std(calculate21forBoll, 'uncorrected') * 2).toFixed(fixedValue));
        newObj.bollBottom = Number((newObj.ma21 - std(calculate21forBoll, 'uncorrected') * 2).toFixed(fixedValue));
        newObj.bollBandwidth = Number((newObj.bollTop - newObj.bollBottom).toFixed(fixedValue));

        newSingleObj[symbol] = newObj;

        return true;
    });

    const newDataObj = {};
    let updatedPositions = [];

    Object.keys(newSingleObj).map((symbol, i) => {
        let lastKline = newSingleObj[symbol];
        let prevKline = symbols[symbol][symbols[symbol].length - 1];
        let twoPrevKline = symbols[symbol][symbols[symbol].length - 2];
        //let prevKlines = [...symbols[symbol]].splice(symbols[symbol].length - 5, 5);
        let prevKlines = analysisModel === "zombieSpot" ? [...symbols[symbol]].splice(symbols[symbol].length - spotPrevKlines, spotPrevKlines) : [...symbols[symbol]].splice(symbols[symbol].length - 5, 5);

        let beforeComparisonResult = prevKline.position.status;
        let currentDeal = {
            symbol: symbol,
            status: prevKline.position.status,
            opendate: prevKline.position.openDate,
            openprice: prevKline.position.openPrice,
            highprice: prevKline.position.highPrice,
            highpriceIndex: prevKline.position.highPriceIndex,
            posType: prevKline.position.posType,
            closeType: prevKline.position.closeType,
            candleCount: prevKline.position.candleCount,
            closeReverse: prevKline.position.closeReverse,
            closeReverseIndex: prevKline.position.closeReverseIndex,
            maxReverse: prevKline.position.maxReverse,
            maxReverseIndex: prevKline.position.maxReverseIndex,
            isAboveMiddle: prevKline.position.isAboveMiddle,
            topNeedle: prevKline.position.topNeedle,
            topNeedleIndex: prevKline.position.topNeedleIndex,
            firstReverse: prevKline.position.firstReverse,
        }

        let newObj = {};

        let openPosition = positions && positions.find(p => p.symbol === symbol && p.closeType === "NC");
        //console.log('OPEN', symbol, openPosition);

        let comparisonResult = calculatePositions(lastKline, prevKlines, beforeComparisonResult, currentDeal, analysisModel);

        if(beforeComparisonResult === "LONG") {
            currentDeal.candleCount++;

            if(currentDeal.openprice) {
                const closeMinusPerc = (Number(lastKline.candlestick[4]) - currentDeal.openprice) * 100 / currentDeal.openprice;
                let tempCloseReverse = currentDeal.closeReverse;
                currentDeal.closeReverse = closeMinusPerc < tempCloseReverse ? Number(closeMinusPerc.toFixed(2)) : currentDeal.closeReverse;
                currentDeal.closeReverseIndex = closeMinusPerc < tempCloseReverse ? currentDeal.candleCount : currentDeal.closeReverseIndex;

                const maxMinusPerc = (Number(lastKline.candlestick[3]) - currentDeal.openprice) * 100 / currentDeal.openprice;
                let tempMaxReverse = currentDeal.maxReverse;
                currentDeal.maxReverse = maxMinusPerc < tempMaxReverse ? Number(maxMinusPerc.toFixed(2)) : currentDeal.maxReverse;
                currentDeal.maxReverseIndex = maxMinusPerc < tempMaxReverse ? currentDeal.candleCount : currentDeal.maxReverseIndex;

                currentDeal.highpriceIndex = Number(lastKline.candlestick[4]) > currentDeal.highprice ? currentDeal.candleCount : currentDeal.highpriceIndex;
                currentDeal.highprice = Number(lastKline.candlestick[4]) > currentDeal.highprice ? Number(lastKline.candlestick[4]) : currentDeal.highprice;
                currentDeal.isAboveMiddle = currentDeal.isAboveMiddle || lastKline.candlestick[4] > lastKline.bollMiddle;
                currentDeal.topNeedleIndex = Number(lastKline.candlestick[2]) > currentDeal.topNeedle ? currentDeal.candleCount : currentDeal.topNeedleIndex;
                currentDeal.topNeedle = Number(lastKline.candlestick[2]) > currentDeal.topNeedle ? Number(lastKline.candlestick[2]) : currentDeal.topNeedle;
                if(currentDeal.candleCount === 1) {
                    currentDeal.firstReverse = maxMinusPerc < tempMaxReverse ? Number(maxMinusPerc.toFixed(2)) : currentDeal.maxReverse;
                } 
                currentDeal.closeprice = Number(lastKline.candlestick[4]);
                currentDeal.closedate = lastKline.candlestick[6];
                currentDeal.time = (new Date(lastKline.candlestick[6]).getTime() - new Date(currentDeal.opendate).getTime()) / (1000 * 60 * 60);

                let pnl = Number(((lastKline.candlestick[4] - currentDeal.openprice) * 100 / currentDeal.openprice).toFixed(2));
                pnl = pnl < -5 ? -5 : pnl;

                currentDeal.pnl = currentDeal.status === "SHORT" ? -1 * pnl : pnl; 
                currentDeal.maxProfit = Number(((currentDeal.highprice - currentDeal.openprice) * 100 / currentDeal.openprice).toFixed(2));
                currentDeal.highProfit = Number(((currentDeal.topNeedle - currentDeal.openprice) * 100 / currentDeal.openprice).toFixed(2));

                if(openPosition) {
                    openPosition.candleCount = currentDeal.candleCount;
                    openPosition.closeReverse = currentDeal.closeReverse;
                    openPosition.closeReverseIndex = currentDeal.closeReverseIndex;
                    openPosition.maxReverse = currentDeal.maxReverse;
                    openPosition.maxReverseIndex = currentDeal.maxReverseIndex;
                    openPosition.highprice = currentDeal.highprice;
                    openPosition.highpriceIndex = currentDeal.highpriceIndex;
                    openPosition.isAboveMiddle = currentDeal.isAboveMiddle;
                    openPosition.topNeedle = currentDeal.topNeedle;
                    openPosition.topNeedleIndex = currentDeal.topNeedleIndex;
                    openPosition.firstReverse = currentDeal.firstReverse;
                    openPosition.closeprice = currentDeal.closeprice;
                    openPosition.closedate = currentDeal.closedate;
                    openPosition.time = currentDeal.time;
                    openPosition.pnl = currentDeal.pnl;
                    openPosition.maxProfit = currentDeal.maxProfit;
                    openPosition.highProfit = currentDeal.highProfit;       
                }
            }
        }
        else if (beforeComparisonResult === "SHORT") {
            if(currentDeal.openprice) {            
                const closeMinusPerc = (Number(lastKline.candlestick[4]) - currentDeal.openprice) * 100 / currentDeal.openprice;
                currentDeal.closeReverse = -1 * closeMinusPerc < currentDeal.closeReverse ? Number((-1 * closeMinusPerc).toFixed('2')) : currentDeal.closeReverse;
    
                const maxMinusPerc = (Number(lastKline.candlestick[2]) - currentDeal.openprice) * 100 / currentDeal.openprice;
                currentDeal.maxReverse = -1 * maxMinusPerc < currentDeal.maxReverse ? Number((-1 * maxMinusPerc).toFixed('2')) : currentDeal.maxReverse;
    
                currentDeal.highprice = Number(lastKline.candlestick[4]) < currentDeal.highprice ? Number(lastKline.candlestick[4]) : currentDeal.highprice;
                currentDeal.isBelowMiddle = currentDeal.isBelowMiddle || lastKline.candlestick[4] < lastKline.bollMiddle;
                currentDeal.topNeedle = Number(lastKline.candlestick[2]) < currentDeal.topNeedle ? Number(lastKline.candlestick[2]) : currentDeal.topNeedle;    
            }
        }

        if(beforeComparisonResult !== comparisonResult.position) {
            if(comparisonResult.position === "CLOSE") {
                currentDeal.closeType = comparisonResult.closeType;

                if(openPosition) {
                    openPosition.closeType = currentDeal.closeType;
                }

                currentDeal.status = "CLOSE";
                currentDeal.openprice = null;
                currentDeal.highprice = null;
                currentDeal.highpriceIndex = 0;
                currentDeal.topNeedle = null;
                currentDeal.topNeedleIndex = 0;
                currentDeal.posType = null;
                currentDeal.closeReverse = 0;
                currentDeal.closeReverseIndex = 0;
                currentDeal.maxReverse = 0;
                currentDeal.maxReverseIndex = 0;
                currentDeal.firstReverse = 0;
                currentDeal.candleCount = 0;  

                // var olan açık pozisyonu güncelle
            }
            else {
                currentDeal.key = lastKline.candlestick[6] + symbol;
                currentDeal.closeType = 'NC';
                currentDeal.status = comparisonResult.position;
                currentDeal.posType = comparisonResult.posType;
                currentDeal.openprice = Number(lastKline.candlestick[4]);
                currentDeal.highprice = Number(lastKline.candlestick[4]);
                currentDeal.topNeedle = Number(lastKline.candlestick[4]);
                currentDeal.closeprice = Number(lastKline.candlestick[4]);
                currentDeal.opendate = lastKline.candlestick[6];
                currentDeal.closedate = lastKline.candlestick[6];

                currentDeal.time = (new Date(lastKline.candlestick[6]).getTime() - new Date(currentDeal.opendate).getTime()) / (1000 * 60 * 60);
                currentDeal.maxProfit = 0;
                currentDeal.highProfit = 0;
                currentDeal.candleCount = 0;
                currentDeal.closeReverse = 0;
                currentDeal.closeReverseIndex = 0;
                currentDeal.maxReverse = 0;
                currentDeal.maxReverseIndex = 0;
                currentDeal.firstReverse = 0;
                currentDeal.pnl = 0;
                currentDeal.openBollTop = lastKline.bollTop;
                currentDeal.openBollBottom = lastKline.bollBottom;
                currentDeal.openBollBandwidth = lastKline.bollBandwidth;
                currentDeal.isAboveMiddle = comparisonResult.isAboveMiddle;
                currentDeal.isBelowMiddle = comparisonResult.isBelowMiddle; 
                
                // yeni pozisyon ekle
                positions.push({...currentDeal});
                updatedPositions = positions;
            }
        }

        if(openPosition) {
            // açık pozisyon bulunup yeni verilerle güncelleniyor.
            updatedPositions = positions.map(pos => {
                if(pos.symbol === symbol && pos.closeType === "NC") {
                    //console.log('oldPos', pos);
                    //console.log('updatedPos', openPosition);
                    return {...pos, ...openPosition}
                }
                return pos;
            });
        }

        newObj.position = {
            status: comparisonResult.position,
            openDate: currentDeal.opendate,
            openPrice: currentDeal.openprice,
            highPrice: currentDeal.highprice,
            highPriceIndex: currentDeal.highpriceIndex,
            posType: currentDeal.posType,
            closeType: currentDeal.closeType,
            candleCount: currentDeal.candleCount,
            closeReverse: currentDeal.closeReverse,
            closeReverseIndex: currentDeal.closeReverseIndex,
            maxReverse: currentDeal.maxReverse,
            maxReverseIndex: currentDeal.maxReverseIndex,
            isAboveMiddle: currentDeal.isAboveMiddle,
            topNeedle: currentDeal.topNeedle,
            topNeedleIndex: currentDeal.topNeedleIndex,
            firstReverse: currentDeal.firstReverse,
        }

        newDataObj[symbol] = {...newSingleObj[symbol], ...newObj}

        return true;
    });

    return {candlesticks: newDataObj, positions: updatedPositions };
}

export const allCurrencyUpdateNewModelAnalysis = async (symbols, analysisModel) => {
    const newDataObj = {};
    const allPastPositionsList = [];

    Object.keys(symbols).map((symbol, i) => {
        let prevKlines = [];
        let beforeComparisonResult = '';

        let currentDeal = {
            key: symbol,
            status: '',
            symbol: symbol,
            openprice: null,
            highprice: null,
            closeprice: null,
            opendate: null,
            closedate:  null,
            posType: null,
            closeType: null
        };

        newDataObj[symbol] = symbols[symbol].map((kline, ix) => {
            let newObj = {};
            //prevKlines = [...symbols[symbol]].splice(ix - 5, 5);
            prevKlines = analysisModel === "zombieSpot" ? [...symbols[symbol]].splice(ix - spotPrevKlines, spotPrevKlines) : [...symbols[symbol]].splice(ix - 5, 5);

            if(ix > 200) {
                let comparisonResult = calculatePositions(kline, prevKlines, beforeComparisonResult, currentDeal, analysisModel);

                beforeComparisonResult = comparisonResult.position;            
                
                if(currentDeal.status === "LONG") {
                    currentDeal.candleCount++;

                    if(currentDeal.openprice) {
                        const closeMinusPerc = (Number(kline.candlestick[4]) - currentDeal.openprice) * 100 / currentDeal.openprice;
                        let tempCloseReverse = currentDeal.closeReverse;
                        currentDeal.closeReverse = closeMinusPerc < tempCloseReverse ? Number(closeMinusPerc.toFixed(2)) : currentDeal.closeReverse;
                        currentDeal.closeReverseIndex = closeMinusPerc < tempCloseReverse ? currentDeal.candleCount : currentDeal.closeReverseIndex;
    
                        const maxMinusPerc = (Number(kline.candlestick[3]) - currentDeal.openprice) * 100 / currentDeal.openprice;
                        let tempMaxReverse = currentDeal.maxReverse;
                        currentDeal.maxReverse = maxMinusPerc < tempMaxReverse ? Number(maxMinusPerc.toFixed(2)) : currentDeal.maxReverse;
                        currentDeal.maxReverseIndex = maxMinusPerc < tempMaxReverse ? currentDeal.candleCount : currentDeal.maxReverseIndex;

                        currentDeal.highpriceIndex = Number(kline.candlestick[4]) > currentDeal.highprice ? currentDeal.candleCount : currentDeal.highpriceIndex;
                        currentDeal.highprice = Number(kline.candlestick[4]) > currentDeal.highprice ? Number(kline.candlestick[4]) : currentDeal.highprice;
                        currentDeal.isAboveMiddle = currentDeal.isAboveMiddle || kline.candlestick[4] > kline.bollMiddle;
                        currentDeal.topNeedleIndex = Number(kline.candlestick[2]) > currentDeal.topNeedle ? currentDeal.candleCount : currentDeal.topNeedleIndex;
                        currentDeal.topNeedle = Number(kline.candlestick[2]) > currentDeal.topNeedle ? Number(kline.candlestick[2]) : currentDeal.topNeedle;
                        if(currentDeal.candleCount === 1) {
                            currentDeal.firstReverse = maxMinusPerc < tempMaxReverse ? Number(maxMinusPerc.toFixed(2)) : currentDeal.maxReverse;
                        } 
                    }
                }
                else if (currentDeal.status === "SHORT") {
                    if(currentDeal.openprice) {
                        const closeMinusPerc = (Number(kline.candlestick[4]) - currentDeal.openprice) * 100 / currentDeal.openprice;
                        currentDeal.closeReverse = -1 * closeMinusPerc < currentDeal.closeReverse ? Number((-1 * closeMinusPerc).toFixed('2')) : currentDeal.closeReverse;
    
                        const maxMinusPerc = (Number(kline.candlestick[2]) - currentDeal.openprice) * 100 / currentDeal.openprice;
                        currentDeal.maxReverse = -1 * maxMinusPerc < currentDeal.maxReverse ? Number((-1 * maxMinusPerc).toFixed('2')) : currentDeal.maxReverse;
    
                        currentDeal.highprice = Number(kline.candlestick[4]) < currentDeal.highprice ? Number(kline.candlestick[4]) : currentDeal.highprice;
                        currentDeal.isBelowMiddle = currentDeal.isBelowMiddle || kline.candlestick[4] < kline.bollMiddle;
                        currentDeal.topNeedle = Number(kline.candlestick[2]) < currentDeal.topNeedle ? Number(kline.candlestick[2]) : currentDeal.topNeedle;    
                    }
                }

                if(currentDeal.status !== comparisonResult.position) {     
            
                    if(currentDeal.status !== '' && comparisonResult.position === "CLOSE") {
                        currentDeal.key = ix + symbol;
                        currentDeal.closeprice = Number(kline.candlestick[4]);
                        currentDeal.closedate = kline.candlestick[6];
                        currentDeal.time = (new Date(kline.candlestick[6]).getTime() - new Date(currentDeal.opendate).getTime()) / (1000 * 60 * 60);

                        let pnl = Number(((kline.candlestick[4] - currentDeal.openprice) * 100 / currentDeal.openprice).toFixed(2));
                        pnl = pnl < -5 ? -5 : pnl;

                        currentDeal.pnl = currentDeal.status === "SHORT" ? -1 * pnl : pnl; 
                        currentDeal.closeType = comparisonResult.closeType;
                        currentDeal.maxProfit = Number(((currentDeal.highprice - currentDeal.openprice) * 100 / currentDeal.openprice).toFixed(2));
                        currentDeal.highProfit = Number(((currentDeal.topNeedle - currentDeal.openprice) * 100 / currentDeal.openprice).toFixed(2));
    
                        // all position object push
                        allPastPositionsList.push({...currentDeal});
                        currentDeal.status = "CLOSE";
                        currentDeal.openprice = null;
                        currentDeal.highprice = null;
                        currentDeal.highpriceIndex = 0;
                        currentDeal.topNeedle = null;
                        currentDeal.topNeedleIndex = 0;
                        currentDeal.posType = null;
                        currentDeal.closeReverse = 0;
                        currentDeal.closeReverseIndex = 0;
                        currentDeal.maxReverse = 0;
                        currentDeal.maxReverseIndex = 0;
                        currentDeal.firstReverse = 0;
                        currentDeal.candleCount = 0;                        
                    }
    
                    if(comparisonResult.position === "LONG" || comparisonResult.position === "SHORT") {
                        currentDeal.closeType = null;
                        currentDeal.status = comparisonResult.position;
                        currentDeal.posType = comparisonResult.posType;
                        currentDeal.openprice = Number(kline.candlestick[4]);
                        currentDeal.highprice = Number(kline.candlestick[4]);
                        currentDeal.topNeedle = Number(kline.candlestick[4]);
                        currentDeal.closeprice = null;
                        currentDeal.opendate = kline.candlestick[6];
                        currentDeal.closedate = null;
                        currentDeal.time = null;
                        currentDeal.maxProfit = null;
                        currentDeal.highProfit = null;
                        currentDeal.candleCount = 0;
                        currentDeal.closeReverse = 0;
                        currentDeal.closeReverseIndex = 0;
                        currentDeal.maxReverse = 0;
                        currentDeal.maxReverseIndex = 0;
                        currentDeal.firstReverse = 0;
                        currentDeal.pnl = null;
                        currentDeal.openBollTop = kline.bollTop;
                        currentDeal.openBollBottom = kline.bollBottom;
                        currentDeal.openBollBandwidth = kline.bollBandwidth;
                        currentDeal.isAboveMiddle = comparisonResult.isAboveMiddle;
                        currentDeal.isBelowMiddle = comparisonResult.isBelowMiddle;                        
                    }
                }

                if(ix === symbols[symbol].length - 1 && currentDeal.status !== "CLOSE" && currentDeal.status !== '') {
                    currentDeal.key = kline.candlestick[6] + symbol; 
                    currentDeal.closeprice = Number(kline.candlestick[4]);
                    currentDeal.closedate = kline.candlestick[6];
                    currentDeal.time = (new Date(kline.candlestick[6]).getTime() - new Date(currentDeal.opendate).getTime()) / (1000 * 60 * 60);
                    
                    let pnl = Number(((kline.candlestick[4] - currentDeal.openprice) * 100 / currentDeal.openprice).toFixed(2));
    
                    pnl = pnl < -5 ? -5 : pnl;
                    currentDeal.pnl = currentDeal.status === "SHORT" ? -1 * pnl : pnl; 
                    currentDeal.maxProfit = Number(((currentDeal.highprice - currentDeal.openprice) * 100 / currentDeal.openprice).toFixed(2));
                    currentDeal.highProfit = Number(((currentDeal.topNeedle - currentDeal.openprice) * 100 / currentDeal.openprice).toFixed(2));
    
                    currentDeal.closeType = "NC";
    
                    allPastPositionsList.push({...currentDeal});           
                }

                newObj.position = {
                    status: currentDeal.status,
                    openDate: currentDeal.opendate,
                    openPrice: currentDeal.openprice,
                    highPrice: currentDeal.highprice,
                    highPriceIndex: currentDeal.highpriceIndex,
                    posType: currentDeal.posType,
                    closeType: currentDeal.closeType,
                    candleCount: currentDeal.candleCount,
                    closeReverse: currentDeal.closeReverse,
                    closeReverseIndex: currentDeal.closeReverseIndex,
                    maxReverse: currentDeal.maxReverse,
                    maxReverseIndex: currentDeal.maxReverseIndex,
                    isAboveMiddle: currentDeal.isAboveMiddle,
                    topNeedle: currentDeal.topNeedle,
                    topNeedleIndex: currentDeal.topNeedleIndex,
                    firstReverse: currentDeal.firstReverse
                }
            }

            return {...kline, ...newObj};
        });

        return true;
    });

    return {candlesticks: newDataObj, positions: allPastPositionsList };
}

export const singleCurrencyCandlestickAnalysis = async (klines) => {
    // Tek coinin 1500'den fazla olacak şekilde 2021 başından itibaren datalarını çektiğim analiz ortamı.
    // Macd için oluşturulan veriler henüz buraya eklenmedi.
    let newMaObj = klines.map((kline, ix) => {
        let newObj = {};
        newObj.candlestick = [...kline].splice(0, 7);

        fibonacci.map(fib => {
            if(fib < 56) {
                newObj[`ma${fib}`] = ix >= fib ? movingAverage([...klines].splice((ix + 1) - fib, fib)) : null;
            }
        });

        return newObj;
    });

    return newMaObj;
}

const movingAverage = (data, lastKline, symbol) => {
    let fixedValue;
    let sum;
    let length;

    if(lastKline) {  
        //data.length === 4 && console.log('mAvgData', data, lastKline[4]); 
        fixedValue = data[0].candlestick[4].split('.')[1].length;
        let priceArray = data.map(x => x.candlestick[4]);
        priceArray.push(lastKline[4]);
        //data.length === 4 && console.log(symbol, priceArray);
        length = priceArray.length;
        sum = priceArray.reduce((a, b) => {
            return Number(a) + Number(b);   
        });
        //data.length === 4 && console.log('ma5:', symbol, priceArray, sum, length);
    }
    else {
        fixedValue = data[0][4].split('.')[1].length;
        length = data.length;
        sum = data.map(x => x[4]).reduce((a, b) => {
            return Number(a) + Number(b);
        });
    }

    return Number((sum / length).toFixed(fixedValue));
}

const expMovingAverage = (beforeEma, activeKline, periyod) => {
    //console.log('beforeEma',beforeEma); 
    const fixedValue = activeKline[4].split('.')[1].length;
    const candleCloseValue = activeKline[4];
    
    return Number((candleCloseValue * (2 / (periyod + 1)) + (beforeEma * (1 - (2 / (periyod + 1))))).toFixed(fixedValue));
}

const firstRsiAvgCalculate = (data) => {
    let greenCandlesticks = [];
    let redCandlesticks = [];

    data.map((kline, i) => {
        if(i > 0) {
            let prevKline = data[i - 1];

            let currVal = kline[4] - prevKline[4];
            if(currVal > 0) {
                greenCandlesticks.push(currVal);
            }
            else {
                redCandlesticks.push(-1 * currVal);
            }
        }
    });

    let greenAverage;
    if(greenCandlesticks.length > 0) {
        const greenTotal = greenCandlesticks.reduce((a, b) => {
            return Number(a) + Number(b);
        });
    
        greenAverage = greenTotal / rsiValue; 
    }
    else {
        greenAverage = 0;
    }
    
    let redAverage;
    if(redCandlesticks.length > 0) {
        const redTotal = redCandlesticks.reduce((a, b) => {
            return Number(a) + Number(b);
        });
    
        redAverage = redTotal / rsiValue;
    }
    else {
        redAverage = 0;
    }

    return {greenAvg: greenAverage, redAvg: redAverage};
}

const relativeStrengthIndex = (prevGreenAvg, prevRedAvg, activeKline, prevKline) => {
    //console.log(`prevGreen: ${prevGreenAvg}, prevRed: ${prevRedAvg}, kline: ${activeKline}`);
    let greenAverage;
    let redAverage;

    let currVal = activeKline[4] - prevKline[4];

    if(currVal > 0) {
        //console.log('currVal artı: ', currVal);
        greenAverage = ((prevGreenAvg * (rsiValue - 1)) + currVal) / rsiValue;
        redAverage = ((prevRedAvg * (rsiValue - 1)) + 0) / rsiValue;
    }
    else {
        //console.log('currVal eksi: ', currVal);
        greenAverage = ((prevGreenAvg * (rsiValue - 1)) + 0) / rsiValue;
        redAverage = ((prevRedAvg * (rsiValue - 1)) + (-1 * currVal)) / rsiValue;
    }

    const rsi = Number((100 - (100 / (1 + (greenAverage / redAverage)))).toFixed(2))

    return {greenAvg: greenAverage, redAvg: redAverage, rsi: rsi};
}

export const allCurrencyCandlestickFilter = async (symbols, symbolList) => {
    let deals = {};
    let currentDeals = [];
    let currentEma20Deals = [];
    let currentRsiUp30Deals = [];
    let currentRsiDown30Deals = [];
    let currentEma200Deals = [];
    let currentEma100Deals = [];

    Object.keys(symbols).map((symbol, i) => {
        if(true) {            
            //let currencyInfo = symbolList.find(x => x.symbol === symbol);
            let lastKline = symbols[symbol][symbols[symbol].length - 1];
            let prevKline = symbols[symbol][symbols[symbol].length - 2];

            const currentPosition = lastKline.position.status;
            const prevPosition = prevKline.position.status;

            if(prevPosition !== currentPosition) {
                let currentDeal = {
                    key: symbol,
                    status: '',
                    symbol: symbol,
                    //riskLevel: currencyInfo.riskLevel,
                    //maturity: currencyInfo.maturity,
                    //positionModel: currencyInfo.positionModel,
                    price: lastKline.candlestick[4],
                    date: lastKline.candlestick[6],
                    volume: lastKline.candlestick[5],
                    posType: lastKline.position.posType,
                    closeType: lastKline.position.closeType,
                    ema20: lastKline.ema20,
                    ema50: lastKline.ema50,
                    ema100: lastKline.ema100,
                    ema200: lastKline.ema200,
                    prevRsi: prevKline.rsi,
                    rsi: lastKline.rsi,
                    BbTop: lastKline.bollTop,
                    BbMiddle: lastKline.bollMiddle,
                    BbBottom: lastKline.bollBottom
                };

                if (currentPosition === "CLOSE") {
                    currentDeal.status = 'CLOSE POSITION';
                }
                else {
                    currentDeal.status = `OPEN ${currentPosition}`;
                }
                //console.log('Current Single Deal:', currentDeal);
                currentDeals.push({...currentDeal}); 
            }

            if(calculateEma20Test(lastKline)) {
                let currentEma20Deal = {
                    key: symbol,
                    symbol: symbol,
                    price: lastKline.candlestick[4]
                }

                currentEma20Deals.push(currentEma20Deal);
            }     
            
            if(calculateRsiUpThirthy(prevKline, lastKline)) {
                let currentRsiUp30Deal = {
                    key: symbol,
                    symbol: symbol,
                    price: lastKline.candlestick[4],
                    rsi: lastKline.rsi
                }

                currentRsiUp30Deals.push(currentRsiUp30Deal);
            }  
            
            if(calculateRsiDownThirthy(lastKline)) {
                let currentRsiDown30Deal = {
                    key: symbol,
                    symbol: symbol,
                    price: lastKline.candlestick[4],
                    rsi: lastKline.rsi
                }

                currentRsiDown30Deals.push(currentRsiDown30Deal);
            }

            const ema200Control = calculateEma200(prevKline, lastKline);

            if(ema200Control.condition) {
                let currentEma200Deal = {
                    key: symbol,
                    symbol: symbol,
                    price: lastKline.candlestick[4],
                    type: ema200Control.type
                }

                currentEma200Deals.push(currentEma200Deal);
            }

            const ema100Control = calculateEma100(prevKline, lastKline);

            if(ema100Control.condition) {
                let currentEma100Deal = {
                    key: symbol,
                    symbol: symbol,
                    price: lastKline.candlestick[4],
                    type: ema100Control.type
                }

                currentEma100Deals.push(currentEma100Deal);
            }

            deals = {
                currentDeals: currentDeals.sort((a, b) => ('' + a.symbol).localeCompare(b.symbol)),
                currentEma20Deals,
                currentRsiUp30Deals,
                currentRsiDown30Deals,
                currentEma200Deals,
                currentEma100Deals
            };
        }

        return true;
    });

    return deals;
}

export const allCurrencyLast24CandlestickFilter = async (symbols, symbolList) => {
    // önceden açılıp kahenüz kapanmamış bütün pozisyonları filtreleyecek ve state'te tutacak.
    let last24HoursDeals = [];
    Object.keys(symbols).map((symbol, i) => {
        if(true) {
            //let currencyInfo = symbolList.find(x => x.symbol === symbol);
            let currentDeal = {
                key: symbol,
                status: '',
                symbol: symbol,
                //riskLevel: currencyInfo.riskLevel,
                //maturity: currencyInfo.maturity,
                //positionModel: currencyInfo.positionModel,
                openprice: null,
                currentprice: null,
                date: null,
                time: null,
                pnl: null,
                posType: null,
                closeType: null
            };

            let prevKline = [];
            let lastKline = [];

            const last100kline = [...symbols[symbol]].splice(symbols[symbol].length - 101, 101);
            //console.log(last100kline);
            last100kline.map((kline, ix) => {
                if(ix > 0) {
                    prevKline = last100kline[ix - 1];
                    lastKline = kline;

                    const currentPosition = lastKline.position.status;
                    const prevPosition = prevKline.position.status;
                    const currentOpenprice = lastKline.openPrice;
                    const prevOpenprice = prevKline.openPrice;

                    if(currentDeal.status !== currentPosition && (currentPosition === "LONG" || currentPosition === "SHORT")) {
                        currentDeal.status = currentPosition;
                        currentDeal.openprice = kline.candlestick[4];
                        currentDeal.currentprice = null;
                        currentDeal.date = kline.candlestick[6];
                        currentDeal.time = null;
                        currentDeal.pnl = null;
                        currentDeal.posType = kline.position.posType;
                        currentDeal.closeType = null;
                    }
                    else if (currentDeal.status === currentPosition) {
                        currentDeal.currentprice = kline.candlestick[4];
                        currentDeal.time = (new Date(kline.candlestick[6]).getTime() - new Date(currentDeal.date).getTime()) / (1000 * 60 * 60);
                        
                        let pnl = Number(((kline.candlestick[4] - currentDeal.openprice) * 100 / currentDeal.openprice).toFixed(2));
                        currentDeal.pnl = currentDeal.status === "SHORT" ? -1 * pnl : pnl;
                    }
                    else if(currentPosition === "CLOSE") {
                        currentDeal.status = '';
                        currentDeal.openprice = null;
                        currentDeal.currentprice = null;
                        currentDeal.date = null;
                        currentDeal.time = null;
                        currentDeal.pnl = null;
                        currentDeal.posType = null;
                        currentDeal.closeType = kline.closeType;
                    } 
                }

                return true;
            });

            if(currentDeal.status !== '' && currentDeal.time) {
                currentDeal.status = `OPEN ${currentDeal.status}`;
                last24HoursDeals.push(currentDeal);
            }
        }

        return true;
    });

    return last24HoursDeals.sort((a, b) => ('' + a.symbol).localeCompare(b.symbol));  
}

export const singleCurrencyAllCandlestickFilter = async (symbol, candlesticks, startIndex = 200, analysisModel) => {
    let singleCurrencyHistorical = [];

    let currentDeal = {
        key: symbol,
        status: '',
        symbol: symbol,
        openprice: null,
        highprice: null,
        closeprice: null,
        opendate: null,
        closedate:  null,
        time: null,
        reverse: null,
        pnl: null,
        openBollTop: null,
        openBollBottom: null,
        openBollBandwidth: null,
        isAboveMiddle: null,
        isBelowMiddle: null,
        posType: null
    };

    let beforeComparisonResult = '';
    let prevKline = [];
    let twoPrevKline = [];
    let prevKlines = [];

    candlesticks.map((kline, ix) => {
        if(ix > (startIndex < 5 ? 5 : startIndex)) {
            prevKline = [...candlesticks].splice(ix - 1, 1)[0];
            twoPrevKline = [...candlesticks].splice(ix - 2, 1)[0];
            //prevKlines = [...candlesticks].splice(ix - 5, 5);
            prevKlines = analysisModel === "zombieSpot" ? [...candlesticks].splice(ix - spotPrevKlines, spotPrevKlines) : [...candlesticks].splice(ix - 5, 5);

            let comparisonResult = calculatePositions(kline, prevKlines, beforeComparisonResult, currentDeal, analysisModel);

            beforeComparisonResult = comparisonResult.position; 

            if(currentDeal.status === "LONG") {
                currentDeal.candleCount++;

                if(currentDeal.openprice) {
                    const closeMinusPerc = (Number(kline.candlestick[4]) - currentDeal.openprice) * 100 / currentDeal.openprice;
                    let tempCloseReverse = currentDeal.closeReverse;
                    currentDeal.closeReverse = closeMinusPerc < tempCloseReverse ? Number(closeMinusPerc.toFixed(2)) : currentDeal.closeReverse;
                    currentDeal.closeReverseIndex = closeMinusPerc < tempCloseReverse ? currentDeal.candleCount : currentDeal.closeReverseIndex;

                    const maxMinusPerc = (Number(kline.candlestick[3]) - currentDeal.openprice) * 100 / currentDeal.openprice;
                    let tempMaxReverse = currentDeal.maxReverse;
                    currentDeal.maxReverse = maxMinusPerc < tempMaxReverse ? Number(maxMinusPerc.toFixed(2)) : currentDeal.maxReverse;
                    currentDeal.maxReverseIndex = maxMinusPerc < tempMaxReverse ? currentDeal.candleCount : currentDeal.maxReverseIndex;

                    currentDeal.highpriceIndex = Number(kline.candlestick[4]) > currentDeal.highprice ? currentDeal.candleCount : currentDeal.highpriceIndex;
                    currentDeal.highprice = Number(kline.candlestick[4]) > currentDeal.highprice ? Number(kline.candlestick[4]) : currentDeal.highprice;
                    currentDeal.isAboveMiddle = currentDeal.isAboveMiddle || kline.candlestick[4] > kline.bollMiddle;
                    currentDeal.topNeedleIndex = Number(kline.candlestick[2]) > currentDeal.topNeedle ? currentDeal.candleCount : currentDeal.topNeedleIndex;
                    currentDeal.topNeedle = Number(kline.candlestick[2]) > currentDeal.topNeedle ? Number(kline.candlestick[2]) : currentDeal.topNeedle;
                    if(currentDeal.candleCount === 1) {
                        currentDeal.firstReverse = maxMinusPerc < tempMaxReverse ? Number(maxMinusPerc.toFixed(2)) : currentDeal.maxReverse;
                    }
                }
            }
            else if (currentDeal.status === "SHORT") {
                if(currentDeal.openprice) {
                    const closeMinusPerc = (Number(kline.candlestick[4]) - currentDeal.openprice) * 100 / currentDeal.openprice;
                    currentDeal.closeReverse = -1 * closeMinusPerc < currentDeal.closeReverse ? Number((-1 * closeMinusPerc).toFixed('2')) : currentDeal.closeReverse;

                    const maxMinusPerc = (Number(kline.candlestick[2]) - currentDeal.openprice) * 100 / currentDeal.openprice;
                    currentDeal.maxReverse = -1 * maxMinusPerc < currentDeal.maxReverse ? Number((-1 * maxMinusPerc).toFixed('2')) : currentDeal.maxReverse;

                    currentDeal.highprice = Number(kline.candlestick[4]) < currentDeal.highprice ? Number(kline.candlestick[4]) : currentDeal.highprice;
                    currentDeal.isBelowMiddle = currentDeal.isBelowMiddle || kline.candlestick[4] < kline.bollMiddle;
                    currentDeal.topNeedle = Number(kline.candlestick[2]) < currentDeal.topNeedle ? Number(kline.candlestick[2]) : currentDeal.topNeedle;
                }
            }
       
            if(currentDeal.status !== comparisonResult.position) {     
            
                if(currentDeal.status !== '' && comparisonResult.position === "CLOSE") {
                    currentDeal.key = ix;
                    currentDeal.closeprice = Number(kline.candlestick[4]);
                    currentDeal.closedate = kline.candlestick[6];
                    currentDeal.time = (new Date(kline.candlestick[6]).getTime() - new Date(currentDeal.opendate).getTime()) / (1000 * 60 * 60);
                    
                    
                    let pnl = Number(((kline.candlestick[4] - currentDeal.openprice) * 100 / currentDeal.openprice).toFixed(2));
                    if(comparisonResult.closeType === "xCloseLong3") {
                        pnl = pnl < -3 ? -3 : pnl;
                    }
                    else {
                        pnl = pnl < -5 ? -5 : pnl;
                    }
                    currentDeal.pnl = currentDeal.status === "SHORT" ? -1 * pnl : pnl; 
                    currentDeal.closeType = comparisonResult.closeType;
                    currentDeal.maxProfit = Number(((currentDeal.highprice - currentDeal.openprice) * 100 / currentDeal.openprice).toFixed(2));
                    currentDeal.highProfit = Number(((currentDeal.topNeedle - currentDeal.openprice) * 100 / currentDeal.openprice).toFixed(2));

                    singleCurrencyHistorical.push({...currentDeal});
                    currentDeal.status = "CLOSE";
                    currentDeal.openprice = null;
                    currentDeal.highprice = null;
                    currentDeal.highpriceIndex = 0;
                    currentDeal.topNeedle = null;
                    currentDeal.topNeedleIndex = 0;
                    currentDeal.posType = null;
                    currentDeal.closeReverse = 0;
                    currentDeal.closeReverseIndex = 0;
                    currentDeal.maxReverse = 0;
                    currentDeal.maxReverseIndex = 0;
                    currentDeal.firstReverse = 0;
                    currentDeal.candleCount = 0;

                    comparisonResult = calculatePositions(kline, prevKlines, beforeComparisonResult, currentDeal, analysisModel);
                    beforeComparisonResult = comparisonResult.position;
                }

                if(comparisonResult.position === "LONG" || comparisonResult.position === "SHORT") {       
                    currentDeal.status = comparisonResult.position;
                    currentDeal.posType = comparisonResult.posType;
                    currentDeal.openprice = Number(kline.candlestick[4]);
                    currentDeal.highprice = Number(kline.candlestick[4]);
                    currentDeal.topNeedle = Number(kline.candlestick[4]);
                    currentDeal.closeprice = null;
                    currentDeal.opendate = kline.candlestick[6];
                    currentDeal.closedate = null;
                    currentDeal.time = null;
                    currentDeal.maxProfit = null;
                    currentDeal.highProfit = null;
                    currentDeal.candleCount = 0;
                    currentDeal.closeReverse = 0;
                    currentDeal.closeReverseIndex = 0;
                    currentDeal.maxReverse = 0;
                    currentDeal.maxReverseIndex = 0;
                    currentDeal.firstReverse = 0;
                    currentDeal.pnl = null;
                    currentDeal.openBollTop = kline.bollTop;
                    currentDeal.openBollBottom = kline.bollBottom;
                    currentDeal.openBollBandwidth = kline.bollBandwidth;
                    currentDeal.isAboveMiddle = comparisonResult.isAboveMiddle;
                    currentDeal.isBelowMiddle = comparisonResult.isBelowMiddle;
                }
            }

            if(ix === candlesticks.length - 1 && currentDeal.status !== "CLOSE" && currentDeal.status !== '') {
                currentDeal.key = kline.candlestick[6]; 
                currentDeal.closeprice = Number(kline.candlestick[4]);
                currentDeal.closedate = kline.candlestick[6];
                currentDeal.time = (new Date(kline.candlestick[6]).getTime() - new Date(currentDeal.opendate).getTime()) / (1000 * 60 * 60);
                //currentDeal.time = (new Date(kline.candlestick[6]).getTime() - new Date(currentDeal.opendate).getTime()) / (1000 * 60);
                
                let pnl = Number(((kline.candlestick[4] - currentDeal.openprice) * 100 / currentDeal.openprice).toFixed(2));

                pnl = pnl < -5 ? -5 : pnl;
                currentDeal.pnl = currentDeal.status === "SHORT" ? -1 * pnl : pnl; 
                currentDeal.maxProfit = Number(((currentDeal.highprice - currentDeal.openprice) * 100 / currentDeal.openprice).toFixed(2));
                currentDeal.highProfit = Number(((currentDeal.topNeedle - currentDeal.openprice) * 100 / currentDeal.openprice).toFixed(2));

                currentDeal.closeType = "NC";

                singleCurrencyHistorical.push({...currentDeal});           
            }

        }
        return true;
    });

    //console.log(singleCurrencyHistorical);
    return singleCurrencyHistorical.filter(x => x.status !== "EMPTY").reverse();  
}

export const allCurrencyProfitLossFilter = async (symbols, analysisModel) => {
    //console.log(symbols);
    let allProfitLossList = []; 

    Object.keys(symbols).map((symbol, i) => {
        
        let symbolCandlesticks = [...symbols[symbol]];
        let currencyHistoricalData = {};

        singleCurrencyAllCandlestickFilter(symbol, symbolCandlesticks, 200, analysisModel)
            .then(data => {
                if(data.length > 0) {
                    //let posArray = data.filter(x => x.pnl > 0);
                    //let negArray = data.filter(x => x.pnl < 0);

                    let posArray = data.filter(x => x.highProfit > 1);
                    //let notrArray = data.filter(x => x.highProfit < 1 && x.highProfit > 0.5);
                    let negArray = data.filter(x => x.highProfit <= 1);
                    currencyHistoricalData.key = symbol;
                    currencyHistoricalData.symbol = symbol;
                    currencyHistoricalData.totalPosProcess = posArray.length;
                    currencyHistoricalData.totalNegProcess = negArray.length;
                    currencyHistoricalData.totalNotrProcess = 0;
                    currencyHistoricalData.totalProfits = posArray.length > 0 ? posArray.length === 1 ? posArray[0].highProfit : posArray.map(x => x.highProfit).reduce((a, b) => a + b).toFixed(2) : 0;
                    currencyHistoricalData.totalLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].pnl : negArray.map(x => x.pnl).reduce((a, b) => a + b).toFixed(2) : 0;
                    currencyHistoricalData.pnlRatio = (Number(currencyHistoricalData.totalProfits) + Number(currencyHistoricalData.totalLoss)).toFixed(2);
                    currencyHistoricalData.mostProfit = posArray.length > 0 ? posArray.length === 1 ? posArray[0].highProfit : posArray.map(x => x.highProfit).reduce((a, b) => b > a ? b : a).toFixed(2) : 0;
                    currencyHistoricalData.mostLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].pnl : negArray.map(x => x.pnl).reduce((a, b) => b > a ? a : b).toFixed(2) : 0;

                    currencyHistoricalData.allPositions = data;
                    allProfitLossList.push(currencyHistoricalData);
                }
            })
            .catch(err => {
            });
    });

    return allProfitLossList;
}

export const allCurrencyDailyProfitLossFilter = async (symbols, startDate, endDate, analysisModel) => {
    //console.log(symbols);
    let allDailyProfitLossList = []; 

    Object.keys(symbols).map((symbol, i) => {
        
        let symbolCandlesticks = [];
        if(endDate) {
            symbolCandlesticks = [...symbols[symbol].filter(x => x.candlestick[0] >= startDate && x.candlestick[0] < endDate)];
        }
        else {
            symbolCandlesticks = [...symbols[symbol].filter(x => x.candlestick[0] >= startDate)];
        }
        
        singleCurrencyAllCandlestickFilter(symbol, symbolCandlesticks, -1, analysisModel)
            .then(data => {
                if(data.length > 0) {
                    allDailyProfitLossList.push(...data);
                }
            })
            .catch(err => {
            });
    });

    return allDailyProfitLossList;
}

export const singleCurrencyAllPositionFilter = async (symbol, candlesticks, analysisModel) => {
    //console.log(symbol, candlesticks);
    let allPositionList = []; 
    positionTypes.map((pos, i) => {
        //console.log('Position Pair', pos);
        //console.log('Position Symbol', symbol);
        //console.log('Position Candlesticks', candlesticks);
        let currencyHistoricalData = {};

        singleCurrencyAllCandlestickFilter(symbol, candlesticks, pos, analysisModel)
            .then(data => {
                if(data.length > 0) {
                    let posArray = data.filter(x => x.pnl > 0);
                    let negArray = data.filter(x => x.pnl < 0); 
                    currencyHistoricalData.key = pos.name;
                    currencyHistoricalData.filterType = pos.name;
                    currencyHistoricalData.totalPosProcess = posArray.length;
                    currencyHistoricalData.totalNegProcess = negArray.length;
                    currencyHistoricalData.allPositions = data.length;
                    currencyHistoricalData.totalProfits = posArray.length > 0 ? posArray.length === 1 ? posArray[0].pnl : posArray.map(x => x.pnl).reduce((a, b) => a + b).toFixed(2) : 0;
                    currencyHistoricalData.totalLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].pnl : negArray.map(x => x.pnl).reduce((a, b) => a + b).toFixed(2) : 0;
                    currencyHistoricalData.pnlRatio = (Number(currencyHistoricalData.totalProfits) + Number(currencyHistoricalData.totalLoss)).toFixed(2);
                    currencyHistoricalData.mostProfit = posArray.length > 0 ? posArray.length === 1 ? posArray[0].pnl : posArray.map(x => x.pnl).reduce((a, b) => b > a ? b : a).toFixed(2) : 0;
                    currencyHistoricalData.mostLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].pnl : negArray.map(x => x.pnl).reduce((a, b) => b > a ? a : b).toFixed(2) : 0;
                    currencyHistoricalData.closeReverse = data.map(x => x.closeReverse).reduce((a, b) => b > a ? a : b).toFixed(2);
                    currencyHistoricalData.maxReverse = data.map(x => x.maxReverse).reduce((a, b) => b > a ? a : b).toFixed(2);
                    allPositionList.push(currencyHistoricalData);
                    //console.log(currencyHistoricalData);
                }
            })
            .catch(err => {
            });
    });
    return allPositionList;
}

export const allCurrencyProfitLossGroupAndFilter = (allPositions) => {
    let allProfitLossList = []; 

    const groupBySymbols = allPositions.reduce((group, position) => {
        const { symbol } = position;
        group[symbol] = group[symbol] ?? [];
        group[symbol].push(position);
        return group;
      }, {});

      Object.keys(groupBySymbols).map((symbol, i) => {
        //console.log(symbol);
        let currencyHistoricalData = {};
        /*
        groupBySymbols[symbol].map((pos, i) => {
            console.log(pos, i);
        });
        */
        //console.log(symbol, groupBySymbols[symbol]);
        let posArray = groupBySymbols[symbol].filter(x => x.highProfit > 1);
        let negArray = groupBySymbols[symbol].filter(x => x.highProfit <= 1);
        currencyHistoricalData.key = symbol;
        currencyHistoricalData.symbol = symbol;
        currencyHistoricalData.totalPosProcess = posArray.length;
        currencyHistoricalData.totalNegProcess = negArray.length;
        currencyHistoricalData.totalNotrProcess = 0;
        currencyHistoricalData.totalProfits = posArray.length > 0 ? posArray.length === 1 ? posArray[0].highProfit : posArray.map(x => x.highProfit).reduce((a, b) => a + b).toFixed(2) : 0;
        currencyHistoricalData.totalLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].pnl : negArray.map(x => x.pnl).reduce((a, b) => a + b).toFixed(2) : 0;
        currencyHistoricalData.pnlRatio = (Number(currencyHistoricalData.totalProfits) + Number(currencyHistoricalData.totalLoss)).toFixed(2);
        currencyHistoricalData.mostProfit = posArray.length > 0 ? posArray.length === 1 ? posArray[0].highProfit : posArray.map(x => x.highProfit).reduce((a, b) => b > a ? b : a).toFixed(2) : 0;
        currencyHistoricalData.mostLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].pnl : negArray.map(x => x.pnl).reduce((a, b) => b > a ? a : b).toFixed(2) : 0;

        allProfitLossList.push(currencyHistoricalData);
      });

      //console.log('all profit loss list', allProfitLossList);

      return allProfitLossList;
}

const calculatePositions = (kline, prevKlines, beforeComparison, currentDeal, analysisModel) => {
    let comparisonResult = {};

    const prevKline = [...prevKlines][prevKlines.length - 1];
    const twoPrevKline = [...prevKlines][prevKlines.length - 2];

    let lastKlinePercentage = Number(((kline.candlestick[4] - kline.candlestick[1]) * 100 / kline.candlestick[1]).toFixed(2));

    let bollBandwidthPercentage = Number(((kline.bollTop - kline.bollBottom) * 100 / kline.bollBottom).toFixed(2));
    let prevBollBandwidthPercentage = Number(((prevKline.bollTop - prevKline.bollBottom) * 100 / prevKline.bollBottom).toFixed(2));
    let twoPrevBollBandwidthPercentage = Number(((twoPrevKline.bollTop - twoPrevKline.bollBottom) * 100 / twoPrevKline.bollBottom).toFixed(2));

    let topNeedleVolumeComparison = kline.candlestick[2] - kline.candlestick[4] < kline.candlestick[4] - kline.candlestick[1]; 


    /**********************************/
    /******** OPEN MODELS *************/
    /**********************************/
    // OL-br: Hem Rsi hem boll alt banddan taşıp tekrar yeşil mumla içeri girilmesi ardından ikinci bir yeşil mum beklenir.
    const openLongBollRsi = calculateLongBollRsi(kline, prevKline, twoPrevKline);

    // OL-bb: Son 3 mum yeşil ilk mum bant genişliği %3 altında, son mum bant genişliği %3 üzerinde olmalı. 
    const openLongBollBands = calculateLongBollBandwidth(kline, prevKline, twoPrevKline, bollBandwidthPercentage, twoPrevBollBandwidthPercentage);

    // OL-p3: son kapanan mum yeşil ve %2.8, %3 boyutlarındadır. 
    const openLongPercent3 = calculateLongPercent3(kline, prevKline);

    // OL-ex:
    const openLongExplosion = calculateLongExplosion(kline, prevKline, calculatePrevKlines(prevKlines, analysisModel), 55, 100);

    // OL-ex 50:
    const openLongExplosion5065 = calculateLongExplosion(kline, prevKline, calculatePrevKlines(prevKlines, analysisModel), 50, 65);
    
    // OL-ex 45:
    const openLongExplosion4560 = calculateLongExplosion(kline, prevKline, calculatePrevKlines(prevKlines, analysisModel), 45, 60);

    // OL-eL:
    const openLongExplosionLoose = calculateLongExplosionLoose(kline, prevKline, calculatePrevKlines(prevKlines, "explosionLoose"));

    // OL-eT:
    const openLongExplosionTight = calculateLongExplosionTight(kline, prevKline, calculatePrevKlines(prevKlines, "explosionTight"), 50, 63);

    // OL-fb:
    const openLongFindBottom = calculateLongFindBottom(kline, prevKline, currentDeal);

    // OL-zs:
    const openLongZombieSpot = calculateLongZombieSpot(kline, prevKline, calculatePrevKlines(prevKlines, "zombieSpot"), 50, 63);

    /**********************************/
    /******** CLOSE MODELS ************/
    /**********************************/
    // CP-br: mum kapanışı, boll alt bandından aşağı taşarsa. (Sadece openLongBollRsi için geçerli bir tedbir kapanışı.)
    const closeLongBollRsi = kline.candlestick[4] < kline.bollBottom;
    
    // CP-h2: Güncel fiyat, ulaştığı en yüksek fiyattan %2 geri döndüyse;
    const closeLongHp2 = kline.candlestick[3] < currentDeal.highprice * 0.98;  
    
    // CP-h3: Güncel fiyat, ulaştığı en yüksek fiyattan %3 geri döndüyse;
    const closeLongHp3 = kline.candlestick[3] < currentDeal.highprice * 0.97;  
    
    // CP-h4: Güncel fiyat, ulaştığı en yüksek fiyattan %4 geri döndüyse;
    const closeLongHp4 = kline.candlestick[3] < currentDeal.highprice * 0.96;

    // CP-h5: Güncel fiyat, ulaştığı en yüksek fiyattan %5 geri döndüyse;
    const closeLongHp5 = kline.candlestick[3] < currentDeal.highprice * 0.95;

    // CP-h6: Güncel fiyat, ulaştığı en yüksek fiyattan %6 geri döndüyse;
    const closeLongHp6 = kline.candlestick[3] < currentDeal.highprice * 0.94;

    // CP-r2: son mum kırmızı VE Mum yüzdesi ortalama %2 ise;
    const closeLongRed2 = kline.candlestick[4] < kline.candlestick[1] && lastKlinePercentage < -2.3;

    // CP-o1: son mum kapanışı, açılış fiyatından %2 geri döndüyse;
    const closeLongOp1 = currentDeal.openprice === currentDeal.highprice && kline.candlestick[3] < currentDeal.openprice * 0.99;
    
    // CP-o2: son mum kapanışı, açılış fiyatından %2 geri döndüyse;
    const closeLongOp2 = currentDeal.openprice === currentDeal.highprice && kline.candlestick[3] < currentDeal.openprice * 0.98;
        
    // CP-o3: son mum kapanışı, açılış fiyatından %3 geri döndüyse;
    const closeLongOp3 = currentDeal.openprice === currentDeal.highprice && kline.candlestick[3] < currentDeal.openprice * 0.97;

    // CP-o4: son mum kapanışı, açılış fiyatından %3 geri döndüyse;
    const closeLongOp4 = currentDeal.openprice === currentDeal.highprice && kline.candlestick[3] < currentDeal.openprice * 0.96;    
    
    // CP-o5: son mum kapanışı, açılış fiyatından %5 geri döndüyse;
    const closeLongOp5 = currentDeal.openprice === currentDeal.highprice && kline.candlestick[3] < currentDeal.openprice * 0.95;

    // CP-o6: son mum kapanışı, açılış fiyatından %6 geri döndüyse;
    const closeLongOp6 = currentDeal.openprice === currentDeal.highprice && kline.candlestick[3] < currentDeal.openprice * 0.94;
        
    // CP-o10: son mum kapanışı, açılış fiyatından %10 geri döndüyse;
    const closeLongOp10 = currentDeal.openprice === currentDeal.highprice && kline.candlestick[4] < currentDeal.openprice * 0.90;
    
    // CP-p1: son mum kapanışı, açılış fiyatından %1 yükseğe çıktığında;
    const closeLongMp1 = kline.candlestick[2] > currentDeal.openprice * 1.01;

    // CP-Cc10: 10 mum boyunca %1 kar üstüne cıkamadıysa kapanır.
    const closeLongCc10 = currentDeal.candleCount >= 10 && currentDeal.highprice < currentDeal.openprice * 1.01;
    /**********************************/

    if(beforeComparison === "CLOSE" || beforeComparison === "") {
        // önceki poz close ise;    
        if(analysisModel === "explosion") {
            if(openLongExplosion) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-ex"; 
            }
            else {
                comparisonResult.position = beforeComparison;
            }
        }
        else if(analysisModel === "explosion5065") {
            if(openLongExplosion5065) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-ex50"; 
            }
            else {
                comparisonResult.position = beforeComparison;
            }
        }
        else if(analysisModel === "explosion4560") {
            if(openLongExplosion4560) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-ex45"; 
            }
            else {
                comparisonResult.position = beforeComparison;
            }
        }
        else if(analysisModel === "explosionLoose") {
            if(openLongExplosionLoose) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-eL"; 
            }
            else {
                comparisonResult.position = beforeComparison;
            }
        }
        else if(analysisModel === "explosionTight") {
            if(openLongExplosionTight) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-eT"; 
            }
            else {
                comparisonResult.position = beforeComparison;
            }
        }
        else if(analysisModel === "percent3") {
            if(openLongPercent3) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-p3"; 
            }
            else {
                comparisonResult.position = beforeComparison;
            }           
        }
        else if(analysisModel === "explosionPercent3") {
            if(openLongExplosion) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-ex"; 
            }
            else if(openLongPercent3) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-p3"; 
            }
            else {
                comparisonResult.position = beforeComparison;
            }
        }
        else if(analysisModel === "explosionBundle") {
            if(openLongExplosionTight) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-eT"; 
            }
            else if(openLongExplosion4560) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-ex45"; 
            }
            else if(openLongExplosion5065) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-ex50"; 
            }
            else if(openLongExplosion) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-ex"; 
            }
            else if(openLongExplosionLoose) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-eL"; 
            }
            else {
                comparisonResult.position = beforeComparison;
            }
        }
        else if(analysisModel === "karmaBundle") {
            if(openLongExplosionTight) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-eT"; 
            }
            else if(openLongExplosion4560) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-ex45"; 
            }
            else if(openLongExplosion5065) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-ex50"; 
            }
            else if(openLongExplosion) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-ex"; 
            }
            else if(openLongPercent3) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-p3"; 
            }
            else if(openLongBollBands) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-bb"; 
            }
            else if(openLongExplosionLoose) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-eL"; 
            }
            else {
                comparisonResult.position = beforeComparison;
            }
        }
        else if(analysisModel === "bollRsi") {
            if(openLongBollRsi) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-br"; 
            }
            else {
                comparisonResult.position = beforeComparison;
            }           
        }
        else if(analysisModel === "bollBandwidth") {
            if(openLongBollBands) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-bb"; 
            }
            else {
                comparisonResult.position = beforeComparison;
            }           
        }
        else if(analysisModel === "zombieSpot") {
            if(openLongZombieSpot) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "OL-zs"; 
            }
            else {
                comparisonResult.position = beforeComparison;
            }           
        }
        else {
            comparisonResult.position = beforeComparison;
        }
    }
    else {
        // önceki poz long ya da short ise;
        if(beforeComparison === "LONG") {
            if(currentDeal.posType === "OL-p3" || currentDeal.posType === "OL-bb") {
                if(closeLongHp5 || closeLongOp5) {
                    comparisonResult.position = "CLOSE";
                    if(closeLongHp5) {
                        comparisonResult.closeType = "CP-h5";
                    }/*
                    else if (closeLongRed2) {
                        comparisonResult.closeType = "CP-r2";
                    }*/
                    else if (closeLongOp5) {
                        comparisonResult.closeType = "CP-o5";
                    }
                }
                else {
                    comparisonResult.position = beforeComparison;
                }       
            }
            else if(currentDeal.posType === "OL-br") {
                if(closeLongBollRsi || closeLongHp5 || closeLongOp5) {
                    comparisonResult.position = "CLOSE";
                    if(closeLongHp5) {
                        comparisonResult.closeType = "CP-h5";
                    }/*
                    else if (closeLongRed2) {
                        comparisonResult.closeType = "CP-r2";
                    }*/
                    else if (closeLongBollRsi) {
                        comparisonResult.closeType = "CP-br";
                    }
                    else if (closeLongOp5) {
                        comparisonResult.closeType = "CP-o5";
                    }
                }
                else {
                    comparisonResult.position = beforeComparison;
                } 
            }
            else if(currentDeal.posType === "OL-ex" || currentDeal.posType === "OL-eL" || currentDeal.posType === "OL-eT" || currentDeal.posType === "OL-ex50" || currentDeal.posType === "OL-ex45") {
                if(closeLongHp5 || closeLongOp5) {
                    comparisonResult.position = "CLOSE";
                    if(closeLongHp5) {
                        comparisonResult.closeType = "CP-h5";
                    }/*
                    else if (closeLongRed2) {
                        comparisonResult.closeType = "CP-r2";
                    }*/
                    else if (closeLongOp5) {
                        comparisonResult.closeType = "CP-o5";
                    }/*
                    else if(closeLongMp1) {
                        comparisonResult.closeType = "CP-p1";
                    }
                    else if(closeLongCc10) {
                        comparisonResult.closeType = "CP-cc10";
                    }*/
                }
                else {
                    comparisonResult.position = beforeComparison;
                } 
            }
            else if(currentDeal.posType === "OL-zs") {
                if(closeLongHp5 || closeLongOp5) {
                    comparisonResult.position = "CLOSE";
                    if(closeLongHp5) {
                        comparisonResult.closeType = "CP-h5";
                    }
                    else if (closeLongOp5) {
                        comparisonResult.closeType = "CP-o5";
                    }
                }
                else {
                    comparisonResult.position = beforeComparison;
                }       
            }
            else {
                comparisonResult.position = beforeComparison;
            }
        }
        else {
            comparisonResult.position = beforeComparison;
        }
    }

    return comparisonResult;
}

const calculateRsiPlusBollinger = (kline, prevKline, twoPrevKline, beforeComparison, currentDeal) => {
    let comparisonResult = {};
    let currentKlineCloseVal = kline.candlestick[4];
    let prevKlineCloseVal = prevKline.candlestick[4];

    let lastKlinePercentage = Number(((kline.candlestick[4] - kline.candlestick[1]) * 100 / kline.candlestick[1]).toFixed(2));

    let bollBandwidthPercentage = Number(((kline.bollTop - kline.bollBottom) * 100 / kline.bollBottom).toFixed(2));
    let prevBollBandwidthPercentage = Number(((prevKline.bollTop - prevKline.bollBottom) * 100 / prevKline.bollBottom).toFixed(2));
    let twoPrevBollBandwidthPercentage = Number(((twoPrevKline.bollTop - twoPrevKline.bollBottom) * 100 / twoPrevKline.bollBottom).toFixed(2));

    // OL-br, OL-p3, OL-bb
    // CP-br, CP-h4, CP-r2, CP-o3

    // OL-br: Hem Rsi hem boll alt banddan taşıp tekrar yeşil mumla içeri girilmesi ardından ikinci bir yeşil mum beklenir.
    const openLongBollRsi = ((twoPrevKline.candlestick[4] < twoPrevKline.bollBottom || twoPrevKline.candlestick[1] < twoPrevKline.bollBottom) && twoPrevKline.rsi < 30) &&
                            (prevKline.candlestick[4] > prevKline.candlestick[1] && prevKline.candlestick[4] > prevKline.bollBottom && prevKline.rsi > 30) &&
                            (kline.candlestick[4] > kline.candlestick[1] && kline.candlestick[4] > kline.bollBottom && kline.candlestick[1] > kline.bollBottom && kline.rsi > 30);

    // OL-p3: son kapanan mum yeşil ve %2.8, %3 boyutlarındadır. 
    let openLongPercent3 = prevKline.candlestick[4] > prevKline.candlestick[1] && lastKlinePercentage > 2.8;

    // OL-bb: Son 3 mum yeşil ilk mum bant genişliği %3 altında, son mum bant genişliği %3 üzerinde olmalı. 
    const openLongBollBands = twoPrevKline.candlestick[4] > twoPrevKline.candlestick[1] &&
                      prevKline.candlestick[4] > prevKline.candlestick[1] &&
                      kline.candlestick[4] > kline.candlestick[1] &&
                      kline.candlestick[4] > kline.bollMiddle &&
                      lastKlinePercentage < 4 &&
                      ((twoPrevBollBandwidthPercentage < 3 && bollBandwidthPercentage > 3) ||
                      (twoPrevBollBandwidthPercentage < 4 && bollBandwidthPercentage > 4));

    // bunu güncel fırsatlara ek olarak basit bir liste şekliden altta göstereceğiz
    // sadece son mumun ema21 kontrollerini yapıyor.
    const openLongT21 = kline.ema21 > kline.ema100 &&
                        kline.candlestick[3] <= kline.ema21 &&
                        kline.candlestick[4] > kline.ema21 &&
                        kline.candlestick[4] > kline.candlestick[1];

    const openLong21x100 = prevKline.candlestick[4] > prevKline.ema100 &&
                           prevKline.ema21 < prevKline.ema100 &&
                           kline.ema21 >= kline.ema100 &&
                           kline.candlestick[4] > kline.candlestick[1];

    const openLongRsiUp40 = prevKline.rsi < 40 && kline.rsi > 40;

    //openLongPercent3 = openLongRsiUp40;

    // CP-br: mum kapanışı, boll alt bandından aşağı taşarsa. (Sadece openLongBollRsi için geçerli bir tedbir kapanışı.)
    const closeLongBollRsi = kline.candlestick[4] < kline.bollBottom;
                  
    // CP-h4: Güncel fiyat, ulaştığı en yüksek fiyattan %4 geri döndüyse;
    const closeLongHp4 = kline.candlestick[4] < currentDeal.highprice * 0.96;

    // CP-r2: son mum kırmızı VE Mum yüzdesi ortalama %2 ise;
    const closeLongRed2 = kline.candlestick[4] < kline.candlestick[1] && lastKlinePercentage < -2.3;
    
    // CP-o3: son mum kapanışı, açılış fiyatından %3 geri döndüyse;
    const closeLongOp3 = currentDeal.openprice === currentDeal.highprice && kline.candlestick[4] < currentDeal.openprice * 0.97;
    
    if(beforeComparison === "CLOSE" || beforeComparison === "") {
        // önceki poz close ise;
        /*if(openLongBollBands) {
            comparisonResult.position = "LONG";
            comparisonResult.posType = "OL-bb";            
        }
        else if(openLongBollRsi) {
            comparisonResult.position = "LONG";
            comparisonResult.posType = "OL-br"; 
        }
        else **/if(openLongPercent3) {
            comparisonResult.position = "LONG";
            comparisonResult.posType = "OL-p3"; 
        }
        else {
            comparisonResult.position = beforeComparison;
        }      
    }
    else {
        // önceki poz long ya da short ise;
        if(beforeComparison === "LONG") {
            if(currentDeal.posType === "OL-bb" || currentDeal.posType === "OL-p3") {
                if(closeLongHp4 || closeLongRed2 || closeLongOp3) {
                    comparisonResult.position = "CLOSE";
                    if(closeLongHp4) {
                        comparisonResult.closeType = "CP-h4";
                    }
                    else if (closeLongRed2) {
                        comparisonResult.closeType = "CP-r2";
                    }
                    else if (closeLongOp3) {
                        comparisonResult.closeType = "CP-o3";
                    }
                }
                else {
                    comparisonResult.position = beforeComparison;
                }       
            }/*
            else if(currentDeal.posType === "OL-br") {
                if(closeLongBollRsi || closeLongHp4 || closeLongRed2 || closeLongOp3) {
                    comparisonResult.position = "CLOSE";
                    if(closeLongHp4) {
                        comparisonResult.closeType = "CP-h4";
                    }
                    else if (closeLongRed2) {
                        comparisonResult.closeType = "CP-r2";
                    }
                    else if (closeLongBollRsi) {
                        comparisonResult.closeType = "CP-br";
                    }
                    else if (closeLongOp3) {
                        comparisonResult.closeType = "CP-o3";
                    }
                }
                else {
                    comparisonResult.position = beforeComparison;
                } 
            }*/
            else {
                comparisonResult.position = beforeComparison;
            }
        }
        else {
            comparisonResult.position = beforeComparison;
        }
    }

    //console.log(`kline: ${kline.rsi}, prevKline: ${prevKline.rsi}, twoPrevKline: ${twoPrevKline.rsi}`);
    //console.log(`Time: ${new Date(kline.candlestick[6]).toLocaleString()}, Percent: ${lastKlinePercentage}, Percent2: ${lastKlinePercentage}`);
    //console.log(`Time: ${new Date(kline.candlestick[6]).toLocaleString()}, Comparison Result: ${comparisonResult.position}, Open Price: ${currentDeal.openprice}, HighPrice: ${currentDeal.highprice}`);

    return comparisonResult;
}

const calculateExplosion = (kline, prevKlines, beforeComparison, currentDeal) => {
    // TEK FONSKIYON CAGRILIP POZİSYON KOMBINASYONLARI FONKSIYON İÇERİSİNDE AYARLANACAK.

    //console.log('last Kline', kline);
    //console.log('prev 5 Klines', prevKlines);
    let comparisonResult = {};
    //let currentKlineCloseVal = kline.candlestick[4];
    const prevKline = prevKlines[prevKlines.length - 1];

    let bollBandwidthPercentage = Number(((kline.bollTop - kline.bollBottom) * 100 / kline.bollBottom).toFixed(2));
    let prevBollBandwidthPercentage = Number(((prevKline.bollTop - prevKline.bollBottom) * 100 / prevKline.bollBottom).toFixed(2));

    let lastKlinePercentage = Number(((kline.candlestick[4] - kline.candlestick[1]) * 100 / kline.candlestick[1]).toFixed(2));

    //let bollBandwidthPercentage = Number(((kline.bollTop - kline.bollBottom) * 100 / kline.bollBottom).toFixed(2));

    // New Model Tight Version
    const prevKlinesPercentage = prevKlines.filter(k => {
        let perc = calculatePercentage(k);
        return perc < 1 && perc > -1 && k.candlestick[4] > k.ema21 && k.candlestick[1] > k.ema21 && k.ema21 > k.ema50 && k.ema21 > k.ema100;
    });


    /*
    const greenPrevKlines = [];
    const prevKlinesPercentage = prevKlines.filter(k => {
        let perc = calculatePercentage(k);
        if( k.candlestick[4] > k.candlestick[1]) {
            greenPrevKlines.push(k);
        }
        //return perc < 1 && perc > -1 && k.candlestick[4] > k.ema21 && k.candlestick[1] > k.ema21 && k.ema21 > k.ema50 && k.ema21 > k.ema100;
        return perc < 1 && perc > -1;
    });
*/
    const topNeedleVolumeComparison = kline.candlestick[2] - kline.candlestick[4] < kline.candlestick[4] - kline.candlestick[1]; 

    //console.log('prevKlinesPercentage', prevKlinesPercentage.length);

    let openLongPercent3 = prevKline.candlestick[4] > prevKline.candlestick[1] && lastKlinePercentage > 2.8;

    // New Model Tight Version
    /*
    const openLongExplosion = prevKlinesPercentage.length === 5 &&
                              kline.ema21 > kline.ema50 &&
                              kline.ema21 > kline.ema100 &&
                              kline.candlestick[4] > kline.ema21 &&
                              kline.candlestick[4] > kline.candlestick[1] &&
                              kline.candlestick[4] > kline.bollMiddle &&
                              kline.rsi > 55 &&
                              calculatePercentage(kline) > 1;
    */

    const openLongExplosion = prevKlinesPercentage.length === 5 &&
                              kline.ema21 > kline.ema50 &&
                              kline.ema21 > kline.ema100 &&
                              kline.candlestick[4] > kline.ema21 &&
                              kline.candlestick[4] > kline.candlestick[1] &&
                              kline.candlestick[4] > kline.bollMiddle &&
                              bollBandwidthPercentage > prevBollBandwidthPercentage &&
                              kline.bollTop > prevKline.bollTop &&
                              kline.rsi > 55 &&
                              calculatePercentage(kline) > 1;

/*
    const openLongExplosion = prevKlinesPercentage.length === 5 &&
                              //greenPrevKlines.length < 5 &&
                              //topNeedleVolumeComparison &&
                              //kline.ema21 > kline.ema50 &&
                              //kline.ema21 > kline.ema100 &&
                              //kline.candlestick[4] >= kline.bollTop &&
                              kline.candlestick[4] > kline.ema21 &&
                              kline.candlestick[4] > kline.candlestick[1] &&
                              kline.candlestick[4] > kline.bollMiddle &&
                              bollBandwidthPercentage > prevBollBandwidthPercentage &&
                              kline.bollTop > prevKline.bollTop &&
                              kline.rsi > 55 &&
                              calculatePercentage(kline) > 1 &&
                              calculatePercentage(kline) < 5;
*/
                              //console.log('calculatePercentage', calculatePercentage(kline) > 1);

    // CP-br: mum kapanışı, boll alt bandından aşağı taşarsa. (Sadece openLongBollRsi için geçerli bir tedbir kapanışı.)
    //const closeLongBollRsi = kline.candlestick[4] < kline.bollBottom;
                  
    // CP-h4: Güncel fiyat, ulaştığı en yüksek fiyattan %4 geri döndüyse;
    const closeLongHp4 = kline.candlestick[4] < currentDeal.highprice * 0.96;

    // CP-r2: son mum kırmızı VE Mum yüzdesi ortalama %2 ise;
    const closeLongRed2 = kline.candlestick[4] < kline.candlestick[1] && lastKlinePercentage < -2.3;
    
    // CP-o3: son mum kapanışı, açılış fiyatından %3 geri döndüyse;
    const closeLongOp3 = currentDeal.openprice === currentDeal.highprice && kline.candlestick[4] < currentDeal.openprice * 0.97;
    
    if(beforeComparison === "CLOSE" || beforeComparison === "") {
        // önceki poz close ise;
        /*if(openLongBollBands) {
            comparisonResult.position = "LONG";
            comparisonResult.posType = "OL-bb";            
        }
        else if(openLongBollRsi) {
            comparisonResult.position = "LONG";
            comparisonResult.posType = "OL-br"; 
        }
        else **/if(openLongExplosion) {
            comparisonResult.position = "LONG";
            comparisonResult.posType = "OL-ex"; 
        }
        else {
            comparisonResult.position = beforeComparison;
        }      
    }
    else {
        // önceki poz long ya da short ise;
        if(beforeComparison === "LONG") {
            if(currentDeal.posType === "OL-ex" || currentDeal.posType === "OL-p3") {
                if(closeLongHp4 || closeLongRed2 || closeLongOp3) {
                    comparisonResult.position = "CLOSE";
                    if(closeLongHp4) {
                        comparisonResult.closeType = "CP-h4";
                    }
                    else if (closeLongRed2) {
                        comparisonResult.closeType = "CP-r2";
                    }
                    else if (closeLongOp3) {
                        comparisonResult.closeType = "CP-o3";
                    }
                }
                else {
                    comparisonResult.position = beforeComparison;
                }       
            }/*
            else if(currentDeal.posType === "OL-br") {
                if(closeLongBollRsi || closeLongHp4 || closeLongRed2 || closeLongOp3) {
                    comparisonResult.position = "CLOSE";
                    if(closeLongHp4) {
                        comparisonResult.closeType = "CP-h4";
                    }
                    else if (closeLongRed2) {
                        comparisonResult.closeType = "CP-r2";
                    }
                    else if (closeLongBollRsi) {
                        comparisonResult.closeType = "CP-br";
                    }
                    else if (closeLongOp3) {
                        comparisonResult.closeType = "CP-o3";
                    }
                }
                else {
                    comparisonResult.position = beforeComparison;
                } 
            }*/
            else {
                comparisonResult.position = beforeComparison;
            }
        }
        else {
            comparisonResult.position = beforeComparison;
        }
    }

    //console.log(`kline: ${kline.rsi}, prevKline: ${prevKline.rsi}, twoPrevKline: ${twoPrevKline.rsi}`);
    //console.log(`Time: ${new Date(kline.candlestick[6]).toLocaleString()}, Percent: ${lastKlinePercentage}, Percent2: ${lastKlinePercentage}`);
    //console.log(`Time: ${new Date(kline.candlestick[6]).toLocaleString()}, Comparison Result: ${comparisonResult.position}, Open Price: ${currentDeal.openprice}, HighPrice: ${currentDeal.highprice}`);

    return comparisonResult;
}

const calculateLongBollRsi = (kline, prevKline, twoPrevKline) => {
    // OL-br: Hem Rsi hem boll alt banddan taşıp tekrar yeşil mumla içeri girilmesi ardından ikinci bir yeşil mum beklenir.
    const openLongBollRsi = ((twoPrevKline.candlestick[4] < twoPrevKline.bollBottom || twoPrevKline.candlestick[1] < twoPrevKline.bollBottom) && twoPrevKline.rsi < 30) &&
                            (prevKline.candlestick[4] > prevKline.candlestick[1] && prevKline.candlestick[4] > prevKline.bollBottom && prevKline.rsi > 30) &&
                            (kline.candlestick[4] > kline.candlestick[1] && kline.candlestick[4] > kline.bollBottom && kline.candlestick[1] > kline.bollBottom && kline.rsi > 30);
    return openLongBollRsi;
}

const calculateLongBollBandwidth = (kline, prevKline, twoPrevKline, bollBandwidthPercentage, twoPrevBollBandwidthPercentage) => {
    // OL-bb: Son 3 mum yeşil ilk mum bant genişliği %3 altında, son mum bant genişliği %3 üzerinde olmalı. 
    const openLongBollBands = twoPrevKline.candlestick[4] > twoPrevKline.candlestick[1] &&
                              prevKline.candlestick[4] > prevKline.candlestick[1] &&
                              kline.candlestick[4] > kline.candlestick[1] &&
                              kline.candlestick[4] > kline.bollMiddle &&
                              calculatePercentage(kline) < 4 &&
                              ((twoPrevBollBandwidthPercentage < 3 && bollBandwidthPercentage > 3) ||
                              (twoPrevBollBandwidthPercentage < 4 && bollBandwidthPercentage > 4));

    return openLongBollBands;
}

const calculateLongPercent3 = (kline, prevKline) => {
    // OL-p3: son kapanan mum yeşil ve %2.8, %3 boyutlarındadır. 
    let openLongPercent3 = calculatePercentage(kline) > 2.8;
    return openLongPercent3;
}

const calculateLongExplosion = (kline, prevKline, prevKlines, rsiOne, rsiTwo) => {
    const openLongExplosion = prevKlines.klinesPercentage.length === 5 &&
                              prevKlines.parallelEq === 4 &&
                              kline.ema20 > kline.ema50 &&
                              kline.ema20 > kline.ema100 &&
                              kline.ema20 > kline.ema200 &&
                              kline.candlestick[4] > kline.ema20 &&
                              kline.candlestick[1] > kline.ema50 &&
                              kline.candlestick[4] > kline.candlestick[1] &&
                              kline.candlestick[4] > kline.bollMiddle &&
                              kline.bollTop > prevKline.bollTop &&
                              kline.rsi > rsiOne && kline.rsi < rsiTwo &&
                              calculatePercentage(kline) > 0.9 && calculatePercentage(kline) < 1.5;

    return openLongExplosion;
}

const calculateLongExplosionLoose = (kline, prevKline, prevKlines) => {
    const openLongExplosionLoose = prevKlines.klinesPercentage.length === 5 &&
                                   kline.candlestick[4] > kline.ema20 &&
                                   kline.candlestick[4] > kline.candlestick[1] &&
                                   kline.candlestick[4] > kline.bollMiddle &&
                                   kline.bollTop > prevKline.bollTop &&
                                   kline.rsi > 55 &&
                                   calculatePercentage(kline) > 0.9;
    return openLongExplosionLoose;
}

const calculateLongExplosionTight = (kline, prevKline, prevKlines, rsiOne, rsiTwo) => {
    //console.log('calculateLongExplosionTight', kline, prevKline, prevKlinesPercentage, greenPrevKlines, prevKlinesAverageWidth);
    const openLongExplosionTight = prevKlines.klinesPercentage.length === 5 &&
                              prevKlines.greenKlinesCount < 5 &&
                              prevKlines.klinesAverageWidth < 1 &&
                              //parallelEq === 4 &&
                              kline.ema20 > kline.ema50 &&
                              kline.ema20 > kline.ema100 &&
                              kline.ema20 > kline.ema200 &&
                              kline.candlestick[4] > kline.ema20 &&
                              kline.candlestick[1] > kline.ema50 &&
                              kline.candlestick[4] > kline.candlestick[1] &&
                              kline.candlestick[4] > kline.bollMiddle &&
                              kline.bollTop > prevKline.bollTop &&
                              kline.rsi > rsiOne && kline.rsi < rsiTwo &&
                              calculatePercentage(kline) > 1.1 && calculatePercentage(kline) < 1.5;

    return openLongExplosionTight;
}

const calculateLongZombieSpot = (kline, prevKline, prevKlines, rsiOne, rsiTwo) => {
    //console.log('calculateLongExplosionTight', kline, prevKline, prevKlinesPercentage, greenPrevKlines, prevKlinesAverageWidth);
    const openLongZombieSpot = prevKlines.klinesPercentage.length === spotPrevKlines &&
                               prevKlines.parallelEq === spotPrevKlines - 1;
                              /* &&
                              prevKlines.greenKlinesCount < 5 &&
                              prevKlines.klinesAverageWidth < 1 &&
                              //parallelEq === 4 &&
                              kline.ema20 > kline.ema50 &&
                              kline.ema20 > kline.ema100 &&
                              kline.ema20 > kline.ema200 &&
                              kline.candlestick[4] > kline.ema20 &&
                              kline.candlestick[1] > kline.ema50 &&
                              kline.candlestick[4] > kline.candlestick[1] &&
                              kline.candlestick[4] > kline.bollMiddle &&
                              kline.bollTop > prevKline.bollTop &&
                              kline.rsi > rsiOne && kline.rsi < rsiTwo &&
                              calculatePercentage(kline) > 1.1 && calculatePercentage(kline) < 1.5;
                              */

    return openLongZombieSpot;
}

const calculatePrevKlines = (prevKlines, analysisModel) => {

    const firstPrevKline = [...prevKlines][0];
    // Mum yeşilse açılış fiyatı, kırmızı ise kapanış fiyatı baz alınır.
    const firstPrevBasePrice = firstPrevKline.candlestick[4] > firstPrevKline.candlestick[1] ? firstPrevKline.candlestick[1] : firstPrevKline.candlestick[4];
    // mumun orta fiyatını buluyor. alternatif olarak duracak.
    //const fivePrevBasePrice = fivePrevKline.candlestick[4] > fivePrevKline.candlestick[1] ? fivePrevKline.candlestick[4] - ((fivePrevKline.candlestick[4] - fivePrevKline.candlestick[1]) / 2) : fivePrevKline.candlestick[1] - ((fivePrevKline.candlestick[1] - fivePrevKline.candlestick[4]) / 2);

    let parallelEq = 0;
    let greenPrevKlines = 0;

    let maxTopPrice = 0;
    let maxBottomPrice = 100000;

    const prevKlinesPercentage = prevKlines.filter((k, i) => {
        let perc = calculatePercentage(k);
        let percentage = 0;
        if(k.candlestick[4] > k.candlestick[1]) {
            greenPrevKlines++;

            maxTopPrice = k.candlestick[4] > maxTopPrice ? k.candlestick[4] : maxTopPrice;
            maxBottomPrice = k.candlestick[1] < maxBottomPrice ? k.candlestick[1] : maxBottomPrice;

            // önceki mumların orta fiyatlarını bulup ona göre oranlıyor.
            /*
            const middlePrice = k.candlestick[4] - ((k.candlestick[4] - k.candlestick[1]) / 2);
            percentage = Number(((fivePrevBasePrice - middlePrice) * 100 / middlePrice).toFixed(2));
            */
            percentage = Number(((firstPrevBasePrice - k.candlestick[4]) * 100 / k.candlestick[4]).toFixed(2));
        }
        else {

            maxTopPrice = k.candlestick[1] > maxTopPrice ? k.candlestick[1] : maxTopPrice;
            maxBottomPrice = k.candlestick[4] < maxBottomPrice ? k.candlestick[4] : maxBottomPrice;
            // önceki mumların orta fiyatlarını bulup ona göre oranlıyor.
            /*
            const middlePrice = k.candlestick[1] - ((k.candlestick[1] - k.candlestick[4]) / 2);
            percentage = Number(((fivePrevBasePrice - middlePrice) * 100 / middlePrice).toFixed(2));
            */
            percentage = Number(((firstPrevBasePrice - k.candlestick[1]) * 100 / k.candlestick[1]).toFixed(2));
        }

        if(analysisModel === "zombieSpot") {
            if(i !== 0 && (percentage < 11 && percentage > -1)) {
                parallelEq++;
            }
        }
        else {
            if(i !== 0 && (percentage < 1 && percentage > -1)) {
                parallelEq++;
            }
        }

        if(analysisModel === "explosionLoose" || analysisModel === "zombieSpot") {
            return perc < 1 && perc > -1;
        }
        else if(analysisModel === "explosionTight") {
            return perc < 0.7 && perc > -0.7 && (k.candlestick[4] > k.candlestick[1] ? k.candlestick[4] > k.ema50 : k.candlestick[1] > k.ema50); 
        }

        return perc < 1 && perc > -1 && (k.candlestick[4] > k.candlestick[1] ? k.candlestick[4] > k.ema50 : k.candlestick[1] > k.ema50); 
        //return perc < 1 && perc > -1 && k.candlestick[4] > k.ema21;
    });

    const prevKlinesAverageWidth = Number(((maxTopPrice - maxBottomPrice) * 100 / maxBottomPrice).toFixed(2));
    //console.log('maxTopPrice', maxTopPrice);
    //console.log('maxBottomPrice', maxBottomPrice);

    return {parallelEq: parallelEq, greenKlinesCount: greenPrevKlines, klinesPercentage: prevKlinesPercentage, klinesAverageWidth: prevKlinesAverageWidth}
} 

const calculateLongFindBottom = (kline, prevKline, currentDeal) => {
    return null;
}

const calculateEma20Test = (kline) => {
    const openLongT20 = kline.ema20 > kline.ema100 &&
    kline.candlestick[3] <= kline.ema20 &&
    kline.candlestick[4] > kline.ema20 &&
    kline.candlestick[4] > kline.candlestick[1];
    return openLongT20;
}

const calculateRsiUpThirthy = (prevKline, lastKline) => {
    const upRsiThirthy = prevKline.rsi < 30 && lastKline.rsi >= 30;
    return upRsiThirthy;
}

const calculateRsiDownThirthy = (kline) => {
    const downRsiThirthy = kline.rsi < 30;
    return downRsiThirthy;
}

const calculateEma200 = (prevKline, kline) => {
    let isTrue = false;
    let type = '';

    const prevKlineIsGreen = prevKline.candlestick[4] > prevKline.candlestick[1];
    const klineIsGreen = kline.candlestick[4] > kline.candlestick[1];

    // son mum yeşil ise;
    if(klineIsGreen) {
        // son mum yeşil ise;

        if(!prevKlineIsGreen && prevKline.candlestick[4] < prevKline.ema200 && prevKline.candlestick[1] > prevKline.ema200 && kline.candlestick[4] > kline.ema200 && kline.candlestick[1] < kline.ema200) {
            // önceki mum kırmızı, önceki kapanış ema200 altında, önceki açılış ema200 üstünde, son mum kapanış ema200 üstünde, son mum açılış ema200 altında;
            isTrue = true;
            type = "DownUp";
            console.log('Yeşil', 'Kırmızı', 'Son Ema200', kline.ema200, 'Mum kapanışı', kline.candlestick[4]);
        }
        else if (kline.candlestick[4] > kline.ema200 && kline.candlestick[1] < kline.ema200) {
            // son mum kapanış ema200 üstünde, son mum açılış ema200 altında;
            isTrue = true;
            type = "Up";
            console.log('Yeşil', 'Son Ema200', kline.ema200, 'Mum kapanışı', kline.candlestick[4]);

        }
    }
    else {
        // son mum kırmızı ise;

        if(prevKlineIsGreen && prevKline.candlestick[4] > prevKline.ema200 && prevKline.candlestick[1] < prevKline.ema200 && kline.candlestick[4] < kline.ema200 && kline.candlestick[1] > kline.ema200) {
            // önceki mum yeşil, önceki kapanış em200 üstünde, önceki açılış ema200 altında, son mum kapanış ema200 altında, son mum açılış ema200 üstünde;
            
            isTrue = true;
            type = "UpDown";
            console.log('Kırmızı', 'Yeşil', 'Son Ema200', kline.ema200, 'Mum açılışı', kline.candlestick[1]);
    
        }
        else if(kline.candlestick[4] < kline.ema200 && kline.candlestick[1] > kline.ema200) {
            isTrue = true;
            type = "Down";
            console.log('Kırmızı', 'Son Ema200', kline.ema200, 'Mum açılışı', kline.candlestick[1]);
            // LIT : ema200 yanlış hesaplanıyor. 5 Agu, 14:30 ile başlayan mum. aslı 1.002,487 hesaplanan 1.014
        }

    }
    return {condition: isTrue, type: type};
}

const calculateEma100 = (prevKline, kline) => {
    let isTrue = false;
    let type = '';

    const prevKlineIsGreen = prevKline.candlestick[4] > prevKline.candlestick[1];
    const klineIsGreen = kline.candlestick[4] > kline.candlestick[1];

    // son mum yeşil ise;
    if(klineIsGreen) {
        // son mum yeşil ise;

        if(!prevKlineIsGreen && prevKline.candlestick[4] < prevKline.ema100 && prevKline.candlestick[1] > prevKline.ema100 && kline.candlestick[4] > kline.ema100 && kline.candlestick[1] < kline.ema100) {
            // önceki mum kırmızı, önceki kapanış ema200 altında, önceki açılış ema200 üstünde, son mum kapanış ema200 üstünde, son mum açılış ema200 altında;
            isTrue = true;
            type = "DownUp";
        }
        else if (kline.candlestick[4] > kline.ema100 && kline.candlestick[1] < kline.ema100) {
            // son mum kapanış ema200 üstünde, son mum açılış ema200 altında;
            isTrue = true;
            type = "Up";
        }
    }
    else {
        // son mum kırmızı ise;

        if(prevKlineIsGreen && prevKline.candlestick[4] > prevKline.ema100 && prevKline.candlestick[1] < prevKline.ema100 && kline.candlestick[4] < kline.ema100 && kline.candlestick[1] > kline.ema100) {
            // önceki mum yeşil, önceki kapanış em200 üstünde, önceki açılış ema200 altında, son mum kapanış ema200 altında, son mum açılış ema200 üstünde;
            
            isTrue = true;
            type = "UpDown";
    
        }
        else if(kline.candlestick[4] < kline.ema100 && kline.candlestick[1] > kline.ema100) {
            isTrue = true;
            type = "Down";
        }

    }
    return {condition: isTrue, type: type};
}

const calculatePercentage = (kline) => {
    //console.log('calculatePercentage inside', kline);
    return Number(((kline.candlestick[4] - kline.candlestick[1]) * 100 / kline.candlestick[1]).toFixed(2));
}







    /*
    Mevcut mum:
        boll üst band değeri,
        boll orta band değeri,
        boll alt band değeri,
        rsi değeri (30un altı, 70in üstü kontroller)
        mum kapanış değeri,
        mum açılış değeri,
        mum max değeri,
        mum min değeri,
        mum rengi
    Önceki mum:
        boll üst band değeri,
        boll orta band değeri,
        boll alt band değeri,
        rsi değeri (30un altı, 70in üstü kontroller) 
        mum kapanış değeri,
        mum açılış değeri,
        mum max değeri,
        mum min değeri,
        mum rengi  
    */




/*
    const xOpenShort = twoPrevKline.candlestick[4] < twoPrevKline.candlestick[1] &&
                       prevKline.candlestick[4] < prevKline.candlestick[1] &&
                       kline.candlestick[4] < kline.candlestick[1] &&
                       kline.candlestick[4] < kline.bollMiddle &&
                       ((twoPrevBollBandwidthPercentage < 3 && bollBandwidthPercentage > 3) ||
                       (twoPrevBollBandwidthPercentage < 4 && bollBandwidthPercentage > 4));

    const xCloseShort = kline.candlestick[4] > currentDeal.highprice * 1.04;

*/





    /*
    if(beforeComparison === "CLOSE" || beforeComparison === "") {
        // önceki poz close ise;
        if(prevBollBandwidthPercentage < 3 && bollBandwidthPercentage >= 3) {
            if(twoPrevKline.bollBandwidth < prevKline.bollBandwidth && prevKline.bollBandwidth < kline.bollBandwidth) {
                if(prevKline.candlestick[4] > prevKline.candlestick[1] && prevKline.candlestick[4] > prevKline.bollMiddle && kline.candlestick[4] > kline.candlestick[1] && kline.candlestick[4] > kline.bollMiddle) {
                    comparisonResult.position = "LONG";
                }
                else if(prevKline.candlestick[4] < prevKline.candlestick[1] && prevKline.candlestick[4] < prevKline.bollMiddle && kline.candlestick[4] < kline.candlestick[1] && kline.candlestick[4] < kline.bollMiddle) {
                    comparisonResult.position = "SHORT";
                }
                else {
                    comparisonResult.position = beforeComparison;
                }
            }
            else {
                comparisonResult.position = beforeComparison;   
            }
        }
        else {
            comparisonResult.position = beforeComparison; 
        }
    }
    else {
        // önceki poz long ya da short ise;
        if(beforeComparison === "LONG") {
            if(long2Close2 || long2Close3) {
                comparisonResult.position = "CLOSE";

                if(long2Close2) {
                    comparisonResult.closeType = "long2Close2";
                }
                else if(long2Close3) {
                    comparisonResult.closeType = "long2Close3";
                }
            }
            else {
                comparisonResult.position = beforeComparison;
            }
        }
        else if(beforeComparison === "SHORT") {
            if(short2Close2 || short2Close3) {
                comparisonResult.position = "CLOSE";

                if(short2Close2) {
                    comparisonResult.closeType = "short2Close2";
                }
                else if(short2Close3) {
                    comparisonResult.closeType = "short2Close3";
                }
            }
            else {
                comparisonResult.position = beforeComparison;
            }
        }
        else {
            comparisonResult.position = beforeComparison;
        }
    }
    */











































/*
    // OPEN CONDITIONS
    const longOpenCondition1 = ((twoPrevKline.candlestick[4] < twoPrevKline.bollBottom || twoPrevKline.candlestick[1] < twoPrevKline.bollBottom) && twoPrevKline.rsi < 30) &&
    (prevKline.candlestick[4] > prevKline.candlestick[1] && prevKline.candlestick[4] > prevKline.bollBottom && prevKline.rsi > 30) &&
    (kline.candlestick[4] > kline.candlestick[1] && kline.candlestick[4] > kline.bollBottom && kline.candlestick[1] > kline.bollBottom && kline.rsi > 30);
    
    const shortOpenCondition1 = ((twoPrevKline.candlestick[4] > twoPrevKline.bollTop || twoPrevKline.candlestick[1] > twoPrevKline.bollTop) && twoPrevKline.rsi > 70) &&
     (prevKline.candlestick[4] < prevKline.candlestick[1] && prevKline.candlestick[4] < prevKline.bollTop && prevKline.rsi < 70) &&
     (kline.candlestick[4] < kline.candlestick[1] && kline.candlestick[4] < kline.bollTop && kline.rsi < 70);
    
    const longOpenCondition2 = prevKline.candlestick[4] > prevKline.candlestick[1] && prevKline.candlestick[4] > prevKline.bollTop && prevKline.rsi > 70 && kline.candlestick[4] > kline.candlestick[1] && kline.candlestick[4] > kline.bollTop && kline.rsi > 70 && kline.bollBandwidth > prevKline.bollBandwidth;
    
    const shortOpenCondition2 = prevKline.candlestick[4] < prevKline.candlestick[1] && prevKline.candlestick[4] < prevKline.bollBottom && prevKline.rsi < 30 && kline.candlestick[4] < kline.candlestick[1] && kline.candlestick[4] < kline.bollBottom && kline.rsi < 30 && kline.bollBandwidth > prevKline.bollBandwidth;
    
    //CLOSE CONDITIONS
    // Önceki kapanış, önceki boll üst değerinden büyüktür VE güncel mum kırmızıdır VE güncel kapanış, güncel boll üst değerinden küçüktür.
    const long1Close1 = prevKline.candlestick[4] > prevKline.bollTop && kline.candlestick[4] < kline.candlestick[1] && kline.candlestick[4] < kline.bollTop;

    // Açılıştan bu yana orta bandın üstüne çıkılmıştır VE önceki kapanış, önceki boll orta bandın altındadır VE güncel mum kırmızıdır VE güncel kapanış, güncel boll orta bandın altındadır VE güncel band genişliği ve açılış band genişliğinden küçüktür.
    const long1Close2 = currentDeal.isAboveMiddle && prevKline.candlestick[4] < prevKline.bollMiddle && kline.candlestick[4] < kline.candlestick[1] && kline.candlestick[4] < kline.bollMiddle && kline.bollBandwidth < currentDeal.openBollBandwidth;

    // Açılıştan bu yana orta bandın üstüne çıkılmıştır VE güncel mum kapanışı, güncel boll orta bandın altındadır VE açılış boll alt bandı, güncel boll alt bandından büyüktür.
    //const long1Close3 = currentDeal.isAboveMiddle && kline.candlestick[4] < kline.bollMiddle && currentDeal.openBollBottom > kline.bollBottom;

    // güncel mum kapanış değeri, güncel boll alt band değerinden aşağı inmiştir.
    const long1Close4 = kline.candlestick[4] < kline.bollBottom;


    // açılış fiyatı, en yüksek fiyat ile eşittir VE 2 önceki mum kapanış fiyatı, en yüksek fiyata eşittir VE önceki mum kırmızıdır VE güncel mum kırmızıdır.
    const long2Close1 = currentDeal.openprice === currentDeal.highprice && twoPrevKline.candlestick[4] === currentDeal.openprice && prevKline.candlestick[4] < prevKline.candlestick[1] && kline.candlestick[4] < kline.candlestick[1];

    // önceki mum kapanışı, önceki boll orta bandın altındadır VE güncel mum kapanışı, güncel boll orta bandın altındadır VE açılış band genişliği, güncel band genişliğinden büyüktür.
    const long2Close2 = prevKline.candlestick[4] < prevKline.bollMiddle && kline.candlestick[4] < kline.bollMiddle && currentDeal.openBollBandwidth > kline.bollBandwidth;

    // Son 3 mumun kapanış değerleri kendi boll orta band değerlerinin altındadır.
    const long2Close3 = twoPrevKline.candlestick[4] < twoPrevKline.bollMiddle && prevKline.candlestick[4] < prevKline.bollMiddle && kline.candlestick[4] < kline.bollMiddle;


    // Önceki kapanış, önceki boll alt değerinden küçüktür VE güncel mum yeşildir VE güncel mum kapanışı, güncel boll alt değerinden büyüktür.
    const short1Close1 = prevKline.candlestick[4] < prevKline.bollBottom && kline.candlestick[4] > kline.candlestick[1] && kline.candlestick[4] > kline.bollBottom;

    // Açılıştan bu yana orta bandın altına inilmiştir VE önceki mum kapanışı, öncek boll orta bandın üstündedir Ve güncel mum yeşildir Ve güncel mum kapanışı, güncel boll orta bandının üstündedir VE açılış band genişliği, güncel band genişliğinden büyüktür.
    const short1Close2 = currentDeal.isBelowMiddle && prevKline.candlestick[4] > prevKline.bollMiddle && kline.candlestick[4] > kline.candlestick[1] && kline.candlestick[4] > kline.bollBottom && currentDeal.openBollBandwidth > kline.bollBandwidth;

    // Açılıştan bu yana orta bandın altına inilmiştir VE güncel mum kapanışı, güncel boll orta bandın üstündedir VE açılış boll üst değeri, güncel boll üst değerinden küçüktür.
    //const short1Close3 = currentDeal.isBelowMiddle && kline.candlestick[4] > kline.bollMiddle && currentDeal.openBollTop < kline.bollTop;

    // güncel mum kapanış değeri, güncel boll üst band değerinden yukarı çıkmıştır.
    const short1Close4 = kline.candlestick[4] > kline.bollTop;


    // açılış fiyatı, en yüksek fiyat ile eşittir VE 2 önceki mum kapanış fiyatı, en yüksek fiyata eşittir VE önceki mum yeşildir VE güncel mum yeşildir.
    const short2Close1 = currentDeal.openprice === currentDeal.highprice && twoPrevKline.candlestick[4] === currentDeal.openprice && prevKline.candlestick[4] > prevKline.candlestick[1] && kline.candlestick[4] > kline.candlestick[1];

    // önceki mum kapanışı, önceki boll orta bandın üstündedir VE güncel mum kapanışı, güncel boll orta bandın üstündedir VE açılış band genişliği, güncel band genişliğinden büyüktür.
    const short2Close2 = prevKline.candlestick[4] > prevKline.bollMiddle && kline.candlestick[4] > kline.bollMiddle && currentDeal.openBollBandwidth > kline.bollBandwidth;

    // Son 3 mumun kapanış değerleri kendi boll orta band değerlerinin üstündedir.
    const short2Close3 = twoPrevKline.candlestick[4] > twoPrevKline.bollMiddle && prevKline.candlestick[4] > prevKline.bollMiddle && kline.candlestick[4] > kline.bollMiddle;
*/
/***/
/*
    const nLongOpen1 = kline.candlestick[4] > kline.candlestick[1] &&
                       kline.candlestick[4] > kline.bollTop &&
                       kline.rsi > 70;

    const nLongOpen2 = twoPrevKline.candlestick[4] < twoPrevKline.candlestick[1] &&
                       twoPrevKline.candlestick[4] < twoPrevKline.bollBottom &&
                       prevKline.candlestick[4] > prevKline.candlestick[1] && 
                       prevKline.candlestick[4] > prevKline.bollBottom && 
                       kline.candlestick[4] > kline.candlestick[1] && 
                       kline.candlestick[4] > kline.bollBottom;

    const nShortOpen1 = kline.candlestick[4] < kline.candlestick[1] &&
                        kline.candlestick[4] < kline.bollBottom &&
                        kline.rsi < 30;

    const nShortOpen2 = twoPrevKline.candlestick[4] > twoPrevKline.candlestick[1] &&
                        twoPrevKline.candlestick[4] > twoPrevKline.bollTop &&
                        prevKline.candlestick[4] < prevKline.candlestick[1] && 
                        prevKline.candlestick[4] < prevKline.bollTop && 
                        kline.candlestick[4] < kline.candlestick[1] && 
                        kline.candlestick[4] < kline.bollTop;
    

    const nLongClose1 = kline.candlestick[4] < kline.candlestick[1] &&
                        kline.candlestick[4] < kline.bollTop;

    const nLongClose2 = currentDeal.isAboveMiddle && 
                        kline.candlestick[4] < kline.candlestick[1] &&
                        kline.candlestick[4] < kline.bollMiddle;

    const nLongClose3 = kline.candlestick[4] < kline.candlestick[1] &&
                        kline.candlestick[4] < kline.bollBottom;

    const nLongClose4 = kline.candlestick[4] < kline.candlestick[1] &&
                        kline.candlestick[4] < kline.bollTop &&
                        kline.candlestick[1] > kline.bollTop;

    const nShortClose1 = kline.candlestick[4] > kline.candlestick[1] && 
                         kline.candlestick[4] > kline.bollBottom;

    const nShortClose2 = currentDeal.isBelowMiddle && 
                         kline.candlestick[4] > kline.candlestick[1] && 
                         kline.candlestick[4] > kline.bollMiddle;
                    
    const nShortClose3 = kline.candlestick[4] > kline.candlestick[1] && 
                         kline.candlestick[4] > kline.bollTop;

    const nShortClose4 = kline.candlestick[4] > kline.candlestick[1] && 
                         kline.candlestick[4] > kline.bollBottom &&
                         kline.candlestick[1] < kline.bollBottom;                   

*/












































/**
 4 SAATLIK


    if(beforeComparison === "CLOSE" || beforeComparison === "") {
        // önceki poz close ise;
        if(bollBandwidthPercentage >= 20) {
            if(nLongOpen1) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "nLongOpen1";
                comparisonResult.isAboveMiddle = true;
                comparisonResult.isBelowMiddle = null;
            }
            else if(nLongOpen2) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "nLongOpen2";
                comparisonResult.isAboveMiddle = kline.candlestick[4] > kline.bollMiddle;
                comparisonResult.isBelowMiddle = null;
            }
            else if(nShortOpen1) {
                comparisonResult.position = "SHORT";
                comparisonResult.posType = "nShortOpen1";
                comparisonResult.isAboveMiddle = null;
                comparisonResult.isBelowMiddle = true;
            }
            else if(nShortOpen2) {
                comparisonResult.position = "SHORT";
                comparisonResult.posType = "nShortOpen2";
                comparisonResult.isAboveMiddle = null;
                comparisonResult.isBelowMiddle = kline.candlestick[4] < kline.bollMiddle;
            }
            else {
                comparisonResult.position = beforeComparison;
            }
        }
        else {
            comparisonResult.position = beforeComparison; 
        }
    }
    else {
        // önceki poz long ya da short ise;
        if(beforeComparison === "LONG") {
            if(currentDeal.statusType === "nLongOpen1") {
                if(nLongClose1) {
                    comparisonResult.position = "CLOSE";
                    comparisonResult.closeType = "nLongClose1";
                }
                else {
                    comparisonResult.position = beforeComparison;
                }       
            }
            else if(currentDeal.statusType === "nLongOpen2") {
                if(nLongClose2 || nLongClose3 || nLongClose4) {
                    comparisonResult.position = "CLOSE";

                    if(nLongClose2) {
                        comparisonResult.closeType = "nLongClose2";
                    }
                    else if(nLongClose3) {
                        comparisonResult.closeType = "nLongClose3";
                    }
                    else if(nLongClose4) {
                        comparisonResult.closeType = "nLongClose4";
                    }
                }
                else {
                    comparisonResult.position = beforeComparison;
                }
            }
            else {
                comparisonResult.position = beforeComparison;
            }
        }
        else if(beforeComparison === "SHORT") {
            if(currentDeal.statusType === "nShortOpen1") {
                if(nShortClose1) {
                    comparisonResult.position = "CLOSE";
                    comparisonResult.closeType = "nShortClose1";
                }
                else {
                    comparisonResult.position = beforeComparison;
                }  
            }
            else if(currentDeal.statusType === "nShortOpen2") {
                if(nShortClose2 || nShortClose3 || nShortClose4) {
                    comparisonResult.position = "CLOSE";

                    if(nShortClose2) {
                        comparisonResult.closeType = "nShortClose2";
                    }
                    else if(nShortClose3) {
                        comparisonResult.closeType = "nShortClose3";
                    }
                    else if(nShortClose4) {
                        comparisonResult.closeType = "nShortClose4";
                    }
                }
                else {
                    comparisonResult.position = beforeComparison;
                }
            }
            else {
                comparisonResult.position = beforeComparison;
            }
        }
        else {
            comparisonResult.position = beforeComparison;
        }
    }
 */









/*

    if(beforeComparison === "CLOSE" || beforeComparison === "") {
        // önceki poz close ise;
        if(bollBandwidthPercentage >= 6) {
            if(longOpenCondition1) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "Long1";
                comparisonResult.isAboveMiddle = kline.candlestick[4] > kline.bollMiddle;
                comparisonResult.isBelowMiddle = null;
            }
            else if(longOpenCondition2) {
                comparisonResult.position = "LONG";
                comparisonResult.posType = "Long2";
                comparisonResult.isAboveMiddle = true;
                comparisonResult.isBelowMiddle = null;
            }
            else if(shortOpenCondition1) {
                comparisonResult.position = "SHORT";
                comparisonResult.posType = "Short1";
                comparisonResult.isAboveMiddle = null;
                comparisonResult.isBelowMiddle = kline.candlestick[4] < kline.bollMiddle;
            }
            else if(shortOpenCondition2) {
                comparisonResult.position = "SHORT";
                comparisonResult.posType = "Short2";
                comparisonResult.isAboveMiddle = null;
                comparisonResult.isBelowMiddle = true;
            }
            else {
                comparisonResult.position = beforeComparison;
            }
        }
        else {
            comparisonResult.position = beforeComparison; 
        }
    }
    else {
        // önceki poz long ya da short ise;
        if(beforeComparison === "LONG") {
            if(currentDeal.statusType === "Long1") {
                if(long1Close1 || long1Close2 || long1Close4 || long2Close1) {
                    comparisonResult.position = "CLOSE";

                    if(long1Close1) {
                        comparisonResult.closeType = "long1Close1";
                    }
                    else if(long1Close2) {
                        comparisonResult.closeType = "long1Close2";
                    }
                    else if(long1Close4) {
                        comparisonResult.closeType = "long1Close4";
                    }
                    else if(long2Close1) {
                        comparisonResult.closeType = "long2Close1";
                    }
                }
                else {
                    comparisonResult.position = beforeComparison;
                }       
            }
            else if(currentDeal.statusType === "Long2") {
                if(shortOpenCondition1 || long2Close1 || long2Close2 || long2Close3) {
                    comparisonResult.position = "CLOSE";

                    if(shortOpenCondition1) {
                        comparisonResult.closeType = "shortOpenCondition1";
                    }
                    else if(long2Close1) {
                        comparisonResult.closeType = "long2Close1";
                    }
                    else if(long2Close2) {
                        comparisonResult.closeType = "long2Close2";
                    }
                    else if(long2Close3) {
                        comparisonResult.closeType = "long2Close3";
                    }
                }
                else {
                    comparisonResult.position = beforeComparison;
                }
            }
            else {
                comparisonResult.position = beforeComparison;
            }
        }
        else if(beforeComparison === "SHORT") {
            if(currentDeal.statusType === "Short1") {
                if(short1Close1 || short1Close2 || short1Close4 || short2Close1) {
                    comparisonResult.position = "CLOSE";

                    if(short1Close1) {
                        comparisonResult.closeType = "short1Close1";
                    }
                    else if(short1Close2) {
                        comparisonResult.closeType = "short1Close2";
                    }
                    else if(short1Close4) {
                        comparisonResult.closeType = "short1Close4";
                    }
                    else if(short2Close1) {
                        comparisonResult.closeType = "short2Close1";
                    }
                }
                else {
                    comparisonResult.position = beforeComparison;
                }  
            }
            else if(currentDeal.statusType === "Short2") {
                if(longOpenCondition1 || short2Close1 || short2Close2 || short2Close3) {
                    comparisonResult.position = "CLOSE";

                    if(longOpenCondition1) {
                        comparisonResult.closeType = "longOpenCondition1";
                    }
                    else if(short2Close1) {
                        comparisonResult.closeType = "short2Close1";
                    }
                    else if(short2Close2) {
                        comparisonResult.closeType = "short2Close2";
                    }
                    else if(short2Close3) {
                        comparisonResult.closeType = "short2Close3";
                    }
                }
                else {
                    comparisonResult.position = beforeComparison;
                }
            }
            else {
                comparisonResult.position = beforeComparison;
            }
        }
        else {
            comparisonResult.position = beforeComparison;
        }
    }


*/























/*
const calculateRsiPlusBollinger = (kline, prevKline, twoPrevKline, pos, beforeComparison, openPrice, highPrice) => {
    //console.log(pos);
    let comparisonResult = "";
    let currentKlineCloseVal = kline.candlestick[4];
    let prevKlineCloseVal = prevKline.candlestick[4];

    //console.log(`kline: ${kline.rsi}, prevKline: ${prevKline.rsi}, twoPrevKline: ${twoPrevKline.rsi}`);

    const longCondition = ((twoPrevKline.candlestick[4] < twoPrevKline.bollBottom || twoPrevKline.candlestick[1] < twoPrevKline.bollBottom) && twoPrevKline.rsi < 30) &&
    (prevKline.candlestick[4] > prevKline.candlestick[1] && prevKline.candlestick[4] > prevKline.bollBottom && prevKline.rsi > 30) &&
    (kline.candlestick[4] > kline.candlestick[1] && kline.candlestick[4] > kline.bollBottom && kline.candlestick[1] > kline.bollBottom && kline.rsi > 30);
    
    const shortCondition = ((twoPrevKline.candlestick[4] > twoPrevKline.bollTop || twoPrevKline.candlestick[1] > twoPrevKline.bollTop) && twoPrevKline.rsi > 70) &&
     (prevKline.candlestick[4] < prevKline.candlestick[1] && prevKline.candlestick[4] < prevKline.bollTop && prevKline.rsi < 70) &&
     (kline.candlestick[4] < kline.candlestick[1] && kline.candlestick[4] < kline.bollTop && kline.rsi < 70);
    
    const longCondition2 = prevKline.candlestick[4] > prevKline.candlestick[1] && prevKline.candlestick[4] > prevKline.bollTop && prevKline.rsi > 70 && kline.candlestick[4] > kline.candlestick[1] && kline.candlestick[4] > kline.bollTop && kline.rsi > 70 && kline.bollBandwidth > prevKline.bollBandwidth;
    
    const shortCondition2 = prevKline.candlestick[4] < prevKline.candlestick[1] && prevKline.candlestick[4] < prevKline.booBottom && prevKline.rsi < 30 && kline.candlestick[4] < kline.candlestick[1] && kline.candlestick[4] < kline.bollBottom && kline.rsi < 30 && kline.bollBandwidth > prevKline.bollBandwidth;
    
    if(beforeComparison === "CLOSE" || beforeComparison === "") {
        // önceki poz close ise;
        if(longCondition) {
            comparisonResult = "LONG";
        }
        else if(longCondition2) {
            comparisonResult = "LONG";
        }
        else if(shortCondition) {
            comparisonResult = "SHORT";
        }
        else if(shortCondition2) {
            comparisonResult = "SHORT";
        }
        else {
            comparisonResult = beforeComparison;
        }
    }
    else {
        // önceki poz long ya da short ise;
        if(beforeComparison === "LONG") {
            if(shortCondition || shortCondition2) {
                comparisonResult = "CLOSE";
            }
            else if(highPrice >= openPrice * 1.02 && kline.candlestick[4] < highPrice * 0.99) {
                comparisonResult = "CLOSE";
            }
            else if(kline.candlestick[4] <= openPrice * 0.98) {
                comparisonResult = "CLOSE";
            }
            else {
                comparisonResult = beforeComparison;
            }
        }
        else if(beforeComparison === "SHORT") {
            if(longCondition || longCondition2) {
                comparisonResult = "CLOSE";
            }
            else if(highPrice < openPrice * 0.98 && kline.candlestick[4] > highPrice * 1.01) {
                comparisonResult = "CLOSE";
            }
            else if(kline.candlestick[4] >= openPrice * 1.02) {
                comparisonResult = "CLOSE";
            }
            else {
                comparisonResult = beforeComparison;
            }
        }
        else {
            comparisonResult = beforeComparison;
        }
    
    }





    console.log(`Time: ${new Date(kline.candlestick[6]).toLocaleString()}, Comparison Result: ${comparisonResult}, Open Price: ${openPrice}, HighPrice: ${highPrice}`);

        return comparisonResult;
    }
*/

    /*
    Mevcut mum:
        boll üst band değeri,
        boll orta band değeri,
        boll alt band değeri,
        rsi değeri (30un altı, 70in üstü kontroller)
        mum kapanış değeri,
        mum açılış değeri,
        mum max değeri,
        mum min değeri,
        mum rengi
    Önceki mum:
        boll üst band değeri,
        boll orta band değeri,
        boll alt band değeri,
        rsi değeri (30un altı, 70in üstü kontroller) 
        mum kapanış değeri,
        mum açılış değeri,
        mum max değeri,
        mum min değeri,
        mum rengi  
    */




/*
    if(kline.candlestick[4] <= openPrice * 0.98) {
    comparisonResult = "CLOSE";
    }
    else if(kline.candlestick[4] > openPrice && highPrice > openPrice * 1.02 && kline.candlestick[4] <= highPrice * 0.98) {
    // mum kapanışı, açılış fiyatından büyükse VE en yüksek değer, açılış fiyatından %2 büyükse VE mum kapanışı, en yüksek değerden %2 azalmışsa;
    comparisonResult = "CLOSE";
    }
    else if(shortCondition) {
    comparisonResult = "CLOSE";
    }
    else {
    comparisonResult = beforeComparison;
    }
*/

    /*
    if(kline.candlestick[4] >= openPrice * 1.02) {
    comparisonResult = "CLOSE";
    }
    else if(kline.candlestick[4] < openPrice && highPrice < openPrice * 0.98 && kline.candlestick[4] >= highPrice * 1.02) {
    // mum kapanışı, açılış fiyatından küçükse VE en düşük değer, açılış fiyatından %2 küçükse VE mum kapanışı, en küçük değerden %2 artmışsa;
    comparisonResult = "CLOSE";
    }
    else if(longCondition) {
    comparisonResult = "CLOSE";
    }
    else {
    comparisonResult = beforeComparison;
    }
    */






/*

FENA BI MODEL DEĞİL BU DURSUN

    const longCondition = ((twoPrevKline.candlestick[4] < twoPrevKline.bollBottom || twoPrevKline.candlestick[1] < twoPrevKline.bollBottom) && twoPrevKline.rsi < 30) &&
                          (prevKline.candlestick[4] > prevKline.candlestick[1] && prevKline.candlestick[4] > prevKline.bollBottom && prevKline.rsi > 30) &&
                          (kline.candlestick[4] > kline.candlestick[1] && kline.candlestick[4] > kline.bollBottom && kline.candlestick[1] > kline.bollBottom && kline.rsi > 30);

    const shortCondition = ((twoPrevKline.candlestick[4] > twoPrevKline.bollTop || twoPrevKline.candlestick[1] > twoPrevKline.bollTop) && twoPrevKline.rsi > 70) &&
                           (prevKline.candlestick[4] < prevKline.candlestick[1] && prevKline.candlestick[4] < prevKline.bollTop && prevKline.rsi < 70) &&
                           (kline.candlestick[4] < kline.candlestick[1] && kline.candlestick[4] < kline.bollTop && kline.rsi < 70);


    if(beforeComparison === "CLOSE" || beforeComparison === "") {
        // önceki poz close ise;
        if(longCondition) {
            comparisonResult = "LONG";
        }
        else if(shortCondition) {
            comparisonResult = "SHORT";
        }
        else {
            comparisonResult = beforeComparison;
        }
    }
    else {
        // önceki poz long ya da short ise;
        if(beforeComparison === "LONG") {
            if(shortCondition) {
                comparisonResult = "CLOSE";
            }
            else if(highPrice >= openPrice * 1.02 && kline.candlestick[4] < highPrice * 0.99) {
                comparisonResult = "CLOSE";
            }
            else if(kline.candlestick[4] <= openPrice * 0.95) {
                comparisonResult = "CLOSE";
            }
            else {
                comparisonResult = beforeComparison;
            }
        }
        else if(beforeComparison === "SHORT") {
            if(longCondition) {
                comparisonResult = "CLOSE";
            }
            else if(highPrice < openPrice * 0.98 && kline.candlestick[4] > highPrice * 1.01) {
                comparisonResult = "CLOSE";
            }
            else if(kline.candlestick[4] >= openPrice * 1.05) {
                comparisonResult = "CLOSE";
            }
            else {
                comparisonResult = beforeComparison;
            }
        }
        else {
            comparisonResult = beforeComparison;
        }

    }
*/