export const createCandleStickSocketUrl = (type, symbolList, time = '1h') => {
    let baseUrl;
    if(type === "FUTURES") {
        baseUrl = process.env.REACT_APP_BINANCE_FUTURES_SOCKET_BASE_URL;
    }
    else {
        baseUrl = process.env.REACT_APP_BINANCE_SPOT_SOCKET_BASE_URL;
    }

    const mergeSymbolQuery = symbolList.reduce((prevVal, currVal, i) => {
        return i !== 0 ? `${prevVal}/${currVal.symbol.toLowerCase()}@kline_${time}` : `${currVal.symbol.toLowerCase()}@kline_${time}`;
    }, '');

    return `${baseUrl}stream?streams=${mergeSymbolQuery}`;
    //return `${baseUrl}?streams=btcusdt@kline_1m/avaxusdt@kline_1m/solusdt@kline_1m`;
}

export const createCurrencyPriceSocketUrl = (type, symbol) => {
    let baseUrl;
    if(type === "FUTURES") {
        baseUrl = process.env.REACT_APP_BINANCE_FUTURES_SOCKET_BASE_URL;
        return `${baseUrl}ws/${symbol.toLowerCase()}@markPrice@1s`;
    }
    else {
        baseUrl = process.env.REACT_APP_BINANCE_SPOT_SOCKET_BASE_URL;
        return `${baseUrl}ws/${symbol.toLowerCase()}@trade`;
    }
    
    //return `${baseUrl}ws/${symbol.toLowerCase()}@markPrice@1s`;
    //return `${baseUrl}?streams=btcusdt@kline_1m/avaxusdt@kline_1m/solusdt@kline_1m`;
}

export const convertSocketMessageToCandleStick = (data) => {
    const newArray = [
        data.k.t, 
        data.k.o, 
        data.k.h, 
        data.k.l, 
        data.k.c, 
        data.k.v, 
        data.k.T, 
        data.k.q,
        data.k.n,
        data.k.V,
        data.k.Q,
        data.k.B
      ];

    return newArray;
}

export const epochToDate = (epoch) => {
    if (epoch < 10000000000)
        epoch *= 1000; // convert to milliseconds (Epoch is usually expressed in seconds, but Javascript uses Milliseconds)
    const newEpoch = epoch + (new Date().getTimezoneOffset() * -1); //for timeZone        
    //return new Date(newEpoch);
    return new Date(epoch);

}


//ws = new WebSocket("wss://fstream.binance.com/stream?streams=btcusdt@kline_1m/solusdt@kline_1m/atomusdt@kline_1m/avaxusdt@kline_1m/wavesusdt@kline_1m/arpausdt@kline_1m/hntusdt@kline_1m/galausdt@kline_1m/peopleusdt@kline_1m/duskusdt@kline_1m");
