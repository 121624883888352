import React, {useState, useContext, useEffect, useRef} from 'react';
import {Modal, Table, Input, Tabs, Form, Row, Col} from 'antd';
import { Context } from '../../store';
import './style.scss';

const {TabPane} = Tabs;
const nums = [0.5, 1, 1.5, 2, 2.5, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50];

const CalculatorModal = () => {
    const {state, dispatch} = useContext(Context);
    const [price, setPrice] = useState('');
    const [percentageData, setPercentageData] = useState([]);

    const [priceOne, setPriceOne] = useState('');
    const [priceTwo, setPriceTwo] = useState('');
    const [compareResult, setCompareResult] = useState(0);

    const [entry, setEntry] = useState('');
    const [realPercent, setRealPercent] = useState('');
    const [leverage, setLeverage] = useState('');
    const [period, setPeriod] = useState('');
    const [cumulativeResult, setCumulativeResult] = useState([]);
    const [cumulativeTotal, setCumulativeTotal] = useState(0);


    const percentageColumns = [
        {
            title: 'Profit',
            dataIndex: 'profitPrice',
            key: 'profitPrice'
        },
        {
            title: '%',
            dataIndex: 'percent',
            key: 'percent'
        },
        {
            title: 'Loss',
            dataIndex: 'lossPrice',
            key: 'lossPrice'
        }
    ];

    const cumulativeColumns = [
        {
            title: 'Period',
            dataIndex: 'key',
            key: 'key'
        },
        {
            title: 'Entry',
            dataIndex: 'base',
            key: 'base',
            render: text => text.toLocaleString()
        },
        {
            title: 'Perncet',
            dataIndex: 'perc',
            key: 'perc'
        },
        {
            title: 'Profit',
            dataIndex: 'total',
            key: 'total',
            render: text => text.toLocaleString()
        }
    ];

    useEffect(() => {        
        if(price) {
            const percentData = [];
            let numPrice = Number(price);
            for (const key in nums) {
                const obj = {
                    key: key,
                    percent: nums[key],
                    profitPrice: Number((numPrice / 100) * nums[key] + numPrice).toFixed(4),
                    lossPrice: Number((numPrice / 100) * -nums[key] + numPrice).toFixed(4),
                }
                percentData.push(obj);  
            }
            setPercentageData([...percentData]);
        }
        else {
            setPercentageData([]);
        }
    }, [price]);

    const InputChangeHandler = (e) => {
        if(e.target.value !== "") {
            setPrice(e.target.value);  
        }
        else {
            setPrice('');
        }
    }

    const toggleModal = () => {
        dispatch({type: "SHOW_CALCULATOR", payload: !state.showCalculatorModal});
    }

    useEffect(() => {
        if(priceOne && priceOne > 0 && priceTwo && priceTwo > 0) {
            const result = ((Number(priceTwo) - Number(priceOne)) * 100 / Number(priceOne)).toFixed(2);
            setCompareResult(result);
        }
        else {
            setCompareResult(0);
        }
    }, [priceOne, priceTwo]);

    useEffect(() => {
        if(entry && entry > 0 && realPercent && realPercent > 0 && leverage && leverage > 0 && period && period > 0) {
            let tempTotal = Number(entry);
            let cumulativeResultArray = [];
            let percentage = 1 + ((realPercent * leverage) / 100);
            for (let i = 0; i < period; i++) {
                let result = Number((tempTotal * percentage).toFixed(2));
                let obj = {key: i + 1, base: tempTotal, perc: `%${realPercent * leverage}`, total: result};
                cumulativeResultArray.push(obj);
                tempTotal = result;
            }

            console.log(cumulativeResultArray);
            setCumulativeResult(cumulativeResultArray);
            setCumulativeTotal(tempTotal.toLocaleString());
        }
        else {
            setCumulativeResult([]);
            setCumulativeTotal(0);        
        }
    }, [entry, realPercent, leverage, period]);

    return (
        <>
            <Modal title="Calculator" visible={state.showCalculatorModal} footer={null} onCancel={toggleModal} width={550} height={800}>
                <Tabs defaultActiveKey="1" type="card" size={"large"} onChange={() => {}}>
                    <TabPane tab={`Percentage PnL`} key="1">
                        <div className="percent-calculator">
                            <div className="calc-input">
                                <Form.Item label="Entry Price" name="calcInput">
                                <Input onChange={InputChangeHandler} value={price} />
                                </Form.Item>
                            </div>
                            <div className="calc-table">
                                <Table columns={percentageColumns} dataSource={percentageData} bordered pagination={false} centere />
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tab={`Find % PnL`} key="2">
                        <div className="compare-calculator">
                            <div className="input-holder">
                                <Row gutter="8">
                                    <Col span="12">
                                        <Form.Item label="Entry Price" name="priceOne">
                                            <Input onChange={(e) => setPriceOne(e.target.value)} value={priceOne} />
                                        </Form.Item>
                                    </Col>
                                    <Col span="12">
                                        <Form.Item label="Closing Price" name="priceTwo">
                                            <Input onChange={(e) => setPriceTwo(e.target.value)} value={priceTwo} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                            <div className="compare-result">
                                <p>{compareResult} %</p>
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tab={`Cumulative PnL`} key="3">
                        <div className="cumulative-calculator">
                            <div className="input-holder">
                                <Row gutter="8">
                                    <Col span="6">
                                        <Form.Item label="Entry" name="entry">
                                            <Input onChange={(e) => setEntry(e.target.value)} value={entry} />
                                        </Form.Item>
                                    </Col>
                                    <Col span="6">
                                        <Form.Item label="Real Percent" name="realPercent">
                                            <Input onChange={(e) => setRealPercent(e.target.value)} value={realPercent} />
                                        </Form.Item>
                                    </Col>
                                    <Col span="6">
                                        <Form.Item label="Leverage" name="leverage">
                                            <Input onChange={(e) => setLeverage(e.target.value)} value={leverage} />
                                        </Form.Item>
                                    </Col>
                                    <Col span="6">
                                        <Form.Item label="Period" name="period">
                                            <Input onChange={(e) => setPeriod(e.target.value)} value={period} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                            <div className="cumulative-result">
                                <p>{cumulativeTotal}</p>
                            </div>
                            <div className="cumulative-table">
                                <Table columns={cumulativeColumns} dataSource={cumulativeResult} bordered pagination={false} />
                            </div>
                        </div>
                    </TabPane>
                </Tabs>
            </Modal>
        </>
    );
};

export default CalculatorModal;

/*
50 * (1 + (8*5) / 100)**30
1210071.6177742274
const hesap = [];
undefined
let tempTotal = 50;
for (let i = 0; i < 30; i++) {
  let result = tempTotal * 1.4;
   let obj = {base: tempTotal, perc: '%40', total:result};
    hesap.push(obj);
    tempTotal = result;
}
*/