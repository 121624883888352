import React, {useContext} from 'react';
import './style.scss';
import {Table, Tag} from 'antd';
import {epochToDate} from '../../libs/utility';
import {Context} from '../../store';
import { UpCircleOutlined, DownCircleOutlined, CloseCircleOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';

const CurrencyHistoricalTable = ({data}) => {
    const {state} = useContext(Context);

    const columns = [
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          render: (text, record) => tagBuilder(text, record),
          filters: [
              {
                  text: 'LONG',
                  value: 'OPEN LONG'
              },
              {
                  text: 'SHORT',
                  value: 'OPEN SHORT'
              }
          ],
          onFilter: (value, record) => record.status.indexOf(value) === 0
        },
        {
            title: 'Pos Type',
            dataIndex: 'positionType',
            key: 'positionType',
            render: (text, record) => posBuilder(record),
        },
        {
          title: 'Open Price',
          dataIndex: 'openprice',
          key: 'openprice',
          render: price => `$ ${price}`
        },
        {
            title: 'Close Price',
            dataIndex: 'closeprice',
            key: 'closeprice',
            render: price => `$ ${price}`
          },
        {
          title: 'Open Date',
          key: 'opendate',
          dataIndex: 'opendate',
          render: date => epochToDate(date).toLocaleString()
        },
        {
            title: 'Close Date',
            key: 'closedate',
            dataIndex: 'closedate',
            render: date => epochToDate(date).toLocaleString()
          },
        {
            title: 'Pass Time',
            key: 'time',
            dataIndex: 'time',
            render: time => `${time} ${time === 1 ? 'hour' : 'hours'}`,
            sorter: (a, b) => a.time - b.time,
        },
        {
          title: 'Reverse (C)',
          key: 'closeReverse',
          dataIndex: 'closeReverse',
          render: (closeReverse, record) => reverseBuilder(closeReverse, record.closeReverseIndex),
          sorter: (a, b) => a.closeReverse - b.closeReverse,
        },
        {
            title: 'Reverse (M)',
            key: 'maxReverse',
            dataIndex: 'maxReverse',
            render: (maxReverse, record) => reverseBuilder(maxReverse, record.maxReverseIndex),
            sorter: (a, b) => a.maxReverse - b.maxReverse,
        },
        {
            title: 'First Reverse',
            key: 'firstReverse',
            dataIndex: 'firstReverse',
            render: (firstReverse, record) => reverseBuilder(firstReverse, 1),
            sorter: (a, b) => a.firstReverse - b.firstReverse,
        },
        {
            title: 'Max Profit',
            key: 'maxProfit',
            dataIndex: 'maxProfit',
            render: (maxProfit, record) => reverseBuilder(maxProfit, record.highpriceIndex),
            sorter: (a, b) => a.maxProfit - b.maxProfit,
        },
        {
            title: 'High Profit',
            key: 'highProfit',
            dataIndex: 'highProfit',
            render: (highProfit, record) => reverseBuilder(highProfit, record.topNeedleIndex),
            sorter: (a, b) => a.highProfit - b.highProfit,
        },
        {
            title: 'Profit/Loss',
            key: 'pnl',
            dataIndex: 'pnl',
            render: (pnl, record) => `${pnl} %`,
            sorter: (a, b) => a.pnl - b.pnl,
        }
      ];

    const tagBuilder = (text, record) => {
        let color = null;
        let icon = null;
        switch (text) {
            case 'LONG':
                color = "#87d068";
                icon = <UpCircleOutlined />;
                break;
            case 'SHORT':
                color = "#f50";
                icon = <DownCircleOutlined />;
                break;
            case 'EMPTY':
                color = "#000";
                icon = <CloseCircleOutlined />;
                break;
            default:
                color = "black";
                break;
        }
        return <div className='deals-tag'>
                    <Tag icon={icon} color={color}>{text.toUpperCase()}</Tag><br />
               </div>
    }

    const posBuilder = (record) => {
        return <div className="pos-type">
            <Tag color={"#87d068"}>{record.posType}</Tag>
            <Tag color={"#3b5081"}>{record.closeType}</Tag>
        </div>
    }
    
    const reverseBuilder = (reverse, index) => {
        return <div className="reverse">
            <span className="reverse-value">{reverse} %</span>
            {index > 0 && <span className="reverse-index">{index}</span>}
        </div>
    }

    return (
        <div className='last-24-hours-deals-table'>
            <Table columns={columns} dataSource={data} bordered pagination={false} rowClassName={
                (record, index) => record.highProfit > 1 ? "soft-green" : "soft-red"
            } />
        </div>
    );
};


export default CurrencyHistoricalTable;