import React, { useEffect, useContext, useState, useCallback } from 'react';
import {Button} from 'antd';
import { binanceService } from '../../services';
import { Context } from '../../store';
import {Row, Col} from 'antd';
import AnalysisLoading from '../../components/AnalysisLoading';
import './style.scss';
import { convertSocketMessageToCandleStick, createCandleStickSocketUrl } from '../../libs/utility';
import websocket from '../../libs/websocket';
import Mode15DealsTable from '../../components/Mode15DealsTable';

let allSymbolCandleStickList = {};

const alert = new Audio(process.env.PUBLIC_URL + '/sounds/linn.mp3');

const dataFilter = (symbols, symbolList) => {
    let currentDeals = [];

    Object.keys(symbols).map((symbol, i) => {
        console.log(symbol);
        let currencyInfo = symbolList.find(x => x.symbol === symbol);
        let length = symbols[symbol].length;
        let lastKline = [...symbols[symbol]].splice(length - 1, 1)[0];
        let prevKline = [...symbols[symbol]].splice(length - 2, 1)[0];
        let twoprevKline = [...symbols[symbol]].splice(length - 3, 1)[0];
        let threeprevKline = [...symbols[symbol]].splice(length - 4, 1)[0];
 
        console.log(lastKline);

        let currentDeal = {
            key: symbol,
            status: '',
            symbol: symbol,
            riskLevel: currencyInfo.riskLevel,
            price: lastKline[4],
            date: lastKline[6],
            positionType: [],
            volume: lastKline[5]
        };

        /* */
        // son mum yuzde 1'den fazla ya da az mı öncekinden?
        const percentOnePlus = lastKline[4] > prevKline[4] * 1.01;
        const percentOneMinus = lastKline[4] < prevKline[4] * 0.99;

        if(percentOnePlus) {
            currentDeal.positionType.push('+1');
            currentDeal.status = "LONG";
        } 
        else if(percentOneMinus) {
            currentDeal.positionType.push('-1');
            currentDeal.status = "SHORT";         
        }
        /* */

        /* */
        // Fitil hesaplama
        // üst fitil gövde ve alt fitilin 2 katından büyükse aşağı döner.
        // alt fitil gövde ve üst fitilin 2 katından büyükse yukarı döner.
        let topWick = lastKline[2] - lastKline[4];
        let body = lastKline[4] > lastKline[1] ? lastKline[4] - lastKline[1] : lastKline[1] - lastKline[4];
        let bottomWick = lastKline[1] - lastKline[3];

        console.log(lastKline);
        console.log(`${symbol} topWick: ${topWick}, body: ${body}, bottomWick ${bottomWick}`);

        let returnShort = topWick > body * 2 && topWick > bottomWick * 2;
        let returnLong = bottomWick > body * 2 && bottomWick > topWick * 2;
        if(returnShort) {
            currentDeal.positionType.push('r-S');
            currentDeal.status = "SHORT";
        }
        else if(returnLong) {
            currentDeal.positionType.push('r-L');
            currentDeal.status = "LONG";           
        }
        /* */

        /* */
        // son 3 mumda yeşil mi?
        let isLastGreen = lastKline[4] > lastKline[1];
        let isPrevGreen = prevKline[4] > prevKline[1];
        let isTwoPrevGreen = twoprevKline[4] > twoprevKline[1];

        if(isLastGreen && isPrevGreen && isTwoPrevGreen) {
            currentDeal.positionType.push('3g');
            currentDeal.status = "LONG";
        } 
        /* */

        // son 3 mumda kırmıı mı?
        let isLastRed = lastKline[1] > lastKline[4];
        let isPrevRed = prevKline[1] > prevKline[4];
        let isTwoPrevRed = twoprevKline[1] > twoprevKline[4];

        if(isLastRed && isPrevRed && isTwoPrevRed) {
            currentDeal.positionType.push('3r');
            currentDeal.status = "SHORT";
        } 
        /* */

        /* */
        // başka bir koşul oluştuysa volume artışı da ilave edilir.
        let isIncreaseVol = lastKline[5] > prevKline[5] * 2;

        if(currentDeal.positionType.length > 0) {
            isIncreaseVol && currentDeal.positionType.push('V');
        }
        /* */


        console.log(currentDeal);
        if(currentDeal.positionType.length > 0) {
            currentDeals.push(currentDeal);
            console.log(currentDeal);
        }

        return true;
    });

    return currentDeals.sort((a, b) => b.positionType.length - a.positionType.length);
}

const Mode15m = props => {
    const {state} = useContext(Context);
    const [isButtonVisible, setIsButtonVisible] = useState(true);
    const [symbolIndex, setSymbolIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const [allKlines, setAllKlines] = useState({});
    const [currentKlines, setCurrentKlines] = useState({});
    const [currentDeals, setCurrentDeals] = useState([]);
    const [startSocket, setStartSocket] = useState(false);

    useEffect(() => {
        if(Object.keys(allKlines).length !== 0) {
            // soket false ise çalıştırılır.
            !startSocket && setStartSocket(true);

            // güncel mum verileri filtrenip fırsatlar objesi oluşturulur.
            setCurrentDeals(dataFilter(allKlines, state.symbols));
            setIsLoading(false);
            alert.play();
            console.log(allKlines);
        }
    }, [allKlines, startSocket, state.symbols]); 


    useEffect(() => {
        if(Object.keys(currentKlines).length === state.symbols.length) {
            // güncel veri state'i tamamen dolduysa ana state güncellenir.
            const updatedKlines = {...allKlines};
            Object.keys(updatedKlines).map(kline => {
                const updatdSymbolArray = [...updatedKlines[kline]];
                updatdSymbolArray.push(currentKlines[kline]);
                updatedKlines[kline] = updatdSymbolArray;
                return true;
            });

            setCurrentKlines({});

            setAllKlines({
                ...allKlines,
                ...updatedKlines
            });
            //alert.play();
        }
    }, [currentKlines, allKlines, state.symbols.length]);

    const listenMessage = useCallback((message) => {   
        if(message.data.k.x) {
            // soketten gelen veriler ile güncel veriler ayrı bir state'te toplanır.
            setCurrentKlines(prevState => ({
            ...prevState, 
            [message.data.s]: convertSocketMessageToCandleStick(message.data)
            }));
        }   
    }, []); 

    useEffect(() => {
        if(startSocket) {
            const socketUrl = createCandleStickSocketUrl(state.symbols, '15m');
            websocket.open(socketUrl, listenMessage);
        }
    
        return () => {
          if(websocket.isSocketInitialized()) {
             websocket.close();
          }
        }
      }, [startSocket, listenMessage, state.symbols]);
    

      // Data çekme işlemleri
    const startToFetchData = useCallback((index = 0) => {
        isButtonVisible && setIsButtonVisible(false);
        !isLoading && setIsLoading(true);

        binanceService.getFuturesKlines(state.symbols[index].symbol, "15m", '10')
            .then(res => {
                res.splice(res.length - 1, 1);
                allSymbolCandleStickList[state.symbols[index].symbol] = res;

                if(symbolIndex === state.symbols.length - 1) {
                    // Tüm datalar çekildiğinde state'e atılır.
                    setAllKlines(allSymbolCandleStickList);
                }
                
                setSymbolIndex(symbolIndex + 1);
            });
    }, [isButtonVisible, isLoading, symbolIndex, state.symbols]);

    // Her coin için data çekme işlemi trigger
    useEffect(() => {
        if(symbolIndex > 0 && symbolIndex !== state.symbols.length) {
            startToFetchData(symbolIndex);
        }
    }, [symbolIndex, startToFetchData, state.symbols.length]);

    return (
        <>
            {isLoading && <AnalysisLoading current={symbolIndex} total={state.symbols.length} spin={currentDeals.length > 0 ? true : false} />}
            <div className='mode15 container'>
                <Row gutter="16">
                    <Col span={24}>
                        <p>This is the Mode 15</p>
                        <ul>
                            <li>-1 : son mum bir önceki mumdan %1 düşmüş.</li>
                            <li>+1 : son mum bir önceki mumdan %1 artmış.</li>
                            <li>3g : son 3 mumda yeşil.</li>
                            <li>3r : son 3 mumda kırmızı.</li>
                            <li>r-L : son mumun alt fitili gövdesinden ve üst fitilinden en az 2 kat büyük. (Yukarı dönebilir.)</li>
                            <li>r-S : son mumun üst fitili gövdesinden ve alt fitilinden en az 2 kat büyük. (Aşağı dönebilir.)</li>
                            <li>V : son mumun hacmi öncekinden en az 2 kat artmış.</li>
                        </ul>
                    </Col>
                    <Col span={24}>
                        {(isButtonVisible && Object.keys(allKlines).length === 0) && 
                            <Button type="primary" size='large' className='btn-analysis' onClick={() => startToFetchData(0)}>Start to Analysis</Button>
                        }                

                        <div className="deals">
                            {currentDeals && <Mode15DealsTable data={currentDeals} />}
                        </div>                     
                    </Col>
                </Row>
            </div>
        </>
    );
};


export default React.memo(Mode15m);