import React, {useState, useContext, useEffect, useRef} from 'react';
import {Modal, Table, Input, Button, Row, Col} from 'antd';
import { Context } from '../../store';
import './style.scss';
import {analysisModel} from '../../libs/enums';

const nums = [0.5, 1, 1.5, 2, 2.5, 3, 4, 5, 6, 7, 8, 9, 10];

const AnalysisModelModal = () => {
    const {state, dispatch} = useContext(Context);

    const toggleModal = () => {
        dispatch({type: "SHOW_ANALYSIS_MODAL", payload: !state.showAnalysisModal});
    }

    const handleChangeAnalysisModel = (model) => {
        //console.log(model);
        dispatch({type: "CHANGE_ANALYSIS_MODEL", payload: model});
    }

    const analysisModelList = analysisModel.map((model, i) => {
        return <Col span={8} key={i}><Button block size="large" type={model.value === state.analysisModel ? "danger" : "primary"} disabled={model.value === state.analysisModel ? true : false} onClick={() => handleChangeAnalysisModel(model.value)}>{model.label}</Button></Col>
    });
    return (
        <>
            <Modal title="Change Analysis Model" visible={state.showAnalysisModal} footer={null} onCancel={toggleModal} width={800} height={800}>
                <div className="analysis-model-modal">
                    <Row gutter={16}>
                        {analysisModelList}
                    </Row>
                </div>
            </Modal>
        </>
    );
};

export default AnalysisModelModal;