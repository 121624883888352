import React, {useContext} from 'react';
import './style.scss';
import {Table, Tag, Popover, Button} from 'antd';
import {epochToDate} from '../../libs/utility';
import {Context} from '../../store';
import { CaretUpOutlined, CaretDownOutlined, UpCircleOutlined, DownCircleOutlined, CloseCircleOutlined, StarFilled, PercentageOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';

const CurrentDealsTable = ({data}) => {
    const {state} = useContext(Context);

    const linkPrefix = state.marketType === "FUTURES" ? "futures" : "spot";

    const columns = [
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          render: text => tagBuilder(text),
          filters: [
              {
                  text: 'LONG',
                  value: 'OPEN LONG'
              },
              {
                  text: 'SHORT',
                  value: 'OPEN SHORT'
              },
              {
                  text: 'CLOSE',
                  value: 'CLOSE POSITION'
              }
          ],
          onFilter: (value, record) => record.status.indexOf(value) === 0
        },
        {
          title: 'Symbol',
          dataIndex: 'symbol',
          key: 'symbol',
          render: (symbol, record) => symbolBuilder(symbol, record),
          sorter: (a, b) => ('' + a.symbol).localeCompare(b.symbol),
        },
        {
            title: 'Pos Type',
            dataIndex: 'positionType',
            key: 'positionType',
            render: (text, record) => posBuilder(record),
        },
        /*
        {
            title: 'Maturity',
            dataIndex: 'maturity',
            key: 'maturity',
            render: text => text
        },
        */
        {
          title: 'Close Price',
          dataIndex: 'price',
          key: 'price',
          render: (price, record) => priceBuilder(price, record)
        },
        {
          title: 'Close Date',
          key: 'date',
          dataIndex: 'date',
          render: date => epochToDate(date).toLocaleString()
        },
        {
            title: 'Volume',
            key: 'volume',
            dataIndex: 'volume',
        },
        {
            title: 'EMA 20',
            key: 'ema20',
            dataIndex: 'ema20',
            render: (ema, record) => emaBuilder(ema, record)
        },
        {
            title: 'EMA 50',
            key: 'ema50',
            dataIndex: 'ema50',
            render: (ema, record) => emaBuilder(ema, record)
        },
        {
            title: 'EMA 100',
            key: 'ema100',
            dataIndex: 'ema100',
            render: (ema, record) => emaBuilder(ema, record)
        },
        {
            title: 'EMA 200',
            key: 'ema200',
            dataIndex: 'ema200',
            render: (ema, record) => emaBuilder(ema, record)
        },
        {
            title: 'RSI',
            key: 'rsi',
            dataIndex: 'rsi',
            render: (rsi, record) => <span>{record.prevRsi} / {rsi}</span>
        },
        {
            title: 'Boll Top',
            key: 'BbTop',
            dataIndex: 'BbTop',
            render: (boll, record) => emaBuilder(boll, record)
        },
        {
            title: 'Boll Mid',
            key: 'BbMiddle',
            dataIndex: 'BbMiddle',
            render: (boll, record) => emaBuilder(boll, record)
        }
      ];

    const tagBuilder = (text) => {
        let color = null;
        let icon = null;
        switch (text) {
            case 'OPEN LONG':
                color = "#87d068";
                icon = <UpCircleOutlined />;
                break;
            case 'OPEN SHORT':
                color = "#f50";
                icon = <DownCircleOutlined />;
                break;
            case 'CLOSE POSITION':
                color = "#3b5081";
                icon = <CloseCircleOutlined />;
                break;
            default:
                color = "black";
                break;
        }
        return <div className='deals-tag'>
                    <Tag icon={icon} color={color}>{text.toUpperCase()}</Tag>
               </div>
    }

    const symbolBuilder = (symbol, record) => {
        const imageName = state.marketType === "FUTURES" ? state.symbols.find(x => x.symbol === symbol)?.baseAsset?.toLowerCase() : 'binance';
        return <div className="symbol">
            <Link to={`/${linkPrefix}-currencies/${symbol.replace('USDT', '').toLowerCase()}`}>
                <img src={`${process.env.PUBLIC_URL}/images/crypto-logo/${imageName}.svg`} alt="" />
                <span><strong>{symbol}</strong></span>
                {/*
                <span className={`risk ${record.riskLevel}`}>
                    {record.riskLevel === "star" && <StarFilled style={{color: '#17a5f9', fontSize: 16}}/>}
                </span>
                */}
            </Link>
        </div>
    }

    const posBuilder = (record) => {
        return <div className="pos-type">
            {record.posType && <Tag color={"#87d068"}>{record.posType}</Tag>}
            {record.closeType && record.closeType !== "NC" && <Tag color={"#3b5081"}>{record.closeType}</Tag>}
        </div>
    }

    const emaBuilder = (ema, record) => {
        return <div className="ema">
            <span>{ema}</span>
            {record.price > ema ? <CaretUpOutlined style={{ fontSize: '20px', color: '#0ecb81' }} /> : <CaretDownOutlined style={{ fontSize: '20px', color: '#f6465d' }} />}
        </div>
    }

    const percentageColumns = [
        {
            title: 'Profit',
            dataIndex: 'profitPrice',
            key: 'profitPrice'
        },
        {
            title: '%',
            dataIndex: 'percent',
            key: 'percent'
        },
        {
            title: 'Loss',
            dataIndex: 'lossPrice',
            key: 'lossPrice'
        }
    ];

    const percentTable = (price) => {
        let fixed = price.split('.')[1] || 0;
        const percentData = [];
        const numPrice = Number(price);
        for (let i = 1; i < 6; i++) {
            const obj = {
                key: i,
                percent: i,
                profitPrice: ((numPrice / 100) * i + numPrice).toFixed(fixed.length),
                lossPrice: ((numPrice / 100) * -i + numPrice).toFixed(fixed.length),
            }
            percentData.push(obj);          
        }
        return <Table columns={percentageColumns} dataSource={percentData} bordered pagination={false} />
    }

    const priceBuilder = (price, record) => {
        return <div className="price">
            <span className='price-text'>$ {price}</span>
            {record.status !== "CLOSE POSITION" &&
                <Popover placement="rightTop" title={"Percentages"} content={percentTable(price)} trigger="click" key={price}>
                    <Button type="primary" size="small" shape="circle" icon={<PercentageOutlined />}></Button>
                </Popover>
            }
        </div>
    }

    return (
        <div className='current-deals-table'>
            <Table columns={columns} dataSource={data} bordered pagination={false} />
        </div>
    );
};


export default CurrentDealsTable;