import { Button, Col, Row, Card, Statistic } from 'antd';
import { all } from 'mathjs';
import React, { useContext, useEffect, useState } from 'react';
import AllProfitLossTable from '../../components/AllProfitLossTable';
import ProfitRatesTable from '../../components/ProfitRatesTable';
import { allCurrencyProfitLossFilter, allCurrencyProfitLossGroupAndFilter } from '../../libs/analysis';
import {positionTypes} from '../../libs/enums'
import { Context } from '../../store';
import './style.scss';

const ProfitLossStats = () => {
    const { state } = useContext(Context);
    const [allProfitLoss, setAllProfitLoss] = useState([]);
    const [profitRates, setProfitRates] = useState([]);
    //const [activePosition, setActivePosition] = useState(positionTypes[0]);
    //const [isLoading, setIsLoading] = useState(false);

    const [totalValues, setTotalValues] = useState({
        totalPosProcess : 0,
        totalNegProcess : 0,
        totalProfits : 0,
        totalLoss: 0,
        mostProfit: 0,
        mostLoss: 0
    });

    useEffect(() => {
        /*
        allCurrencyProfitLossFilter(state.kLinesList, state.analysisModel).then(data => {
            setAllProfitLoss(data);        
        });
        */
        setAllProfitLoss(allCurrencyProfitLossGroupAndFilter(state.allPositions));
    }, [state.allPositions]);

    /*
    const getSelectedPositionType = (pos) => {
        allCurrencyProfitLossFilter(state.kLinesList, pos).then(data => {
            //console.log(data);
            setAllProfitLoss(data); 
            setActivePosition(pos);    
        });
    }

    const positionTypeButtons = positionTypes.map((pos, i) => {
        return <Button key={i} type='default' className={activePosition.name === pos.name ? "active" : ""} onClick={() => getSelectedPositionType(pos)}>
            {pos.name}
        </Button>
    }); 
    */
    useEffect(() => {        
        if(allProfitLoss.length > 0) {
            let posArray = allProfitLoss.map(x => x.totalPosProcess).reduce((a, b) => a + b).toFixed(2);
            let negArray = allProfitLoss.map(x => x.totalNegProcess).reduce((a, b) => a + b).toFixed(2);
            let notrArray = allProfitLoss.map(x => x.totalNotrProcess).reduce((a, b) => a + b).toFixed(2);
            let totalProfits = allProfitLoss.map(x => Number(x.totalProfits)).reduce((a, b) => a + b);
            let totalLoss = allProfitLoss.map(x => Number(x.totalLoss)).reduce((a, b) => a + b);
            setTotalValues({
                totalPosProcess: posArray,
                totalNegProcess: negArray,
                totalNotrProcess: notrArray,
                totalProfits: totalProfits,
                totalLoss: totalLoss,
                pnlRatio: (Number(totalProfits) + Number(totalLoss)).toFixed(2),          
                mostProfit: allProfitLoss.map(x => Number(x.mostProfit)).reduce((a, b) => b > a ? b : a),
                mostLoss: allProfitLoss.map(x => Number(x.mostLoss)).reduce((a, b) => b > a ? a : b),
            });
        }
    }, [allProfitLoss]); 

    const calculateProfitRatesWithStop = () => {
        let profitArray = [];

        if (allProfitLoss.length > 0) {
            let allPos = [];
            allProfitLoss.map(x => {
                x.allPositions.map(pos => allPos.push(pos));
                return true;
            });

            for (let i = 1; i < 6; i++) {
                let percentArray = allPos.filter(x => x.maxProfit > 1 && x.maxReverse > -i);
                let totalProfit = percentArray.map(x => x.maxProfit).reduce((a, b) => a + b);
                let totalLoss = (allPos.length - percentArray.length) * i
                const percentObj = {
                    percent: i,
                    profitPos: percentArray.length,
                    lossPos: allPos.length - percentArray.length,
                    totalProfit: Number(totalProfit).toFixed(2),
                    totalLoss: Number(totalLoss).toFixed(2),
                    pnlRatio: Number(totalProfit - totalLoss).toFixed(2)
                }
                profitArray.push(percentObj);
            }

        }
        setProfitRates(profitArray);
    }

    return (
        <div className='profit-loss container-fluid'>
            <Row gutter="16">
                <Col span={24} style={{marginBottom: 20}}>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Card title="Total Position">
                                <span className="total-pos-count">
                                    {Number(totalValues.totalPosProcess) + Number(totalValues.totalNegProcess)}
                                </span>
                                <span className='success-ratio'>
                                    {((Number(totalValues.totalPosProcess) / (Number(totalValues.totalPosProcess) + Number(totalValues.totalNegProcess))) * 100).toFixed(2)} %
                                </span>
                                <Statistic 
                                    title="Profit"
                                    value={totalValues.totalPosProcess}
                                    precision={0}
                                    valueStyle={{ color: '#3f8600' }}
                                    prefix={''}
                                    />
                                <Statistic
                                    title="Loss"
                                    value={totalValues.totalNegProcess}
                                    precision={0}
                                    valueStyle={{ color: '#cf1322' }}
                                    prefix={''}
                                    />
                                <Statistic
                                    title="Notr"
                                    value={totalValues.totalNotrProcess}
                                    precision={0}
                                    valueStyle={{ color: '#000' }}
                                    prefix={''}
                                    />
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card title="Total Profit / Loss">
                                <span className='pnl-ratio'>
                                    {totalValues.pnlRatio} %
                                </span>
                                <Statistic 
                                    title="Profit"
                                    value={totalValues.totalProfits}
                                    precision={2}
                                    valueStyle={{ color: '#3f8600' }}
                                    prefix={''}
                                    suffix="%"
                                    />
                                <Statistic
                                    title="Loss"
                                    value={totalValues.totalLoss}
                                    precision={2}
                                    valueStyle={{ color: '#cf1322' }}
                                    prefix={''} 
                                    suffix="%"
                                    />
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card title="Most Profit / Loss">
                                <Statistic 
                                    title="Profit"
                                    value={totalValues.mostProfit}
                                    precision={2}
                                    valueStyle={{ color: '#3f8600' }}
                                    prefix={''}
                                    suffix="%"
                                    />
                                <Statistic
                                    title="Loss"
                                    value={totalValues.mostLoss}
                                    precision={2}
                                    valueStyle={{ color: '#cf1322' }}
                                    prefix={''}
                                    suffix="%"
                                    />
                            </Card>
                        </Col>
                    </Row>
                </Col>

                <Col span={24}>
                    {profitRates.length > 0 ?
                    <ProfitRatesTable data={profitRates} />
                    :
                    <Button type="primary" onClick={calculateProfitRatesWithStop}>Calculate Profit Rates with Stop</Button>
                    }
                </Col>

                <Col span={24}>
                    <div className="position-buttons">
                        {/*positionTypeButtons*/}
                    </div>

                    {allProfitLoss && <AllProfitLossTable data={allProfitLoss} />}
                </Col>
            </Row>
        </div>
    );
};

export default ProfitLossStats;