import axios from 'axios';

const headers = {
    "X-MBX-APIKEY": process.env.REACT_APP_BINANCE_API_KEY,
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": true
    //"Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS"
}

const instance = axios.create({
    baseURL: process.env.REACT_APP_BINANCE_FUTURES_API_BASE_URL
});

export default instance;