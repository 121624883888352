// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBuh2LG0xMbRkQauj8pAj_mwJ3uxoKybLI",
  authDomain: "cryptoverse-55488.firebaseapp.com",
  projectId: "cryptoverse-55488",
  storageBucket: "cryptoverse-55488.appspot.com",
  messagingSenderId: "193314516333",
  appId: "1:193314516333:web:5eb5812d76cb651327fe64"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);