import './App.scss';

import { AuthContext } from './contexts/AuthContext';
import { useContext } from 'react';
import AuthApp from './containers/AuthApp';
import Authentication from './containers/Authentication';

function App() {
  const {user, privateKey} = useContext(AuthContext);
  
  return user && user.accessToken && privateKey === process.env.REACT_APP_PRIVATE_KEY ? <AuthApp /> : <Authentication />
}

export default App;