import React, { useEffect, useContext, useState, useCallback } from 'react';
import { binanceService } from '../../services';
import { Context } from '../../store';
import {Row, Col, Tabs, Radio, Button, DatePicker, Checkbox} from 'antd';
import AnalysisLoading from '../../components/AnalysisLoading';
import CurrentDealsTable from '../../components/CurrentDealsTable';
import Last24HoursDealsTable from '../../components/Last24HoursDealsTable';
import AllDailyProfitLossTable from '../../components/AllDailyProfitLossTable';
import CoinCurrentStatus from '../../components/CoinCurrentStatus';
import {Link} from 'react-router-dom';
import { analysisModel } from '../../libs/enums';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';

import {
    allCurrencyCandlestickAnalysis, 
    allCurrencyCandlestickFilter, 
    allCurrencyLast24CandlestickFilter,
    allCurrencyDailyProfitLossFilter
} from '../../libs/analysis';
//import allCandleStickData from '../../data/candlesticks.json';
import './style.scss';
//import oneInch from '../../data/candlesticks/1inch.json';
//import {allData} from '../../libs/candlesticks.js';

const {TabPane} = Tabs;

let allSymbolCandleStickList = {};

const SpotAnalysis = props => {
    const {state, dispatch} = useContext(Context);
    const [isButtonVisible, setIsButtonVisible] = useState(true);
    const [symbolIndex, setSymbolIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    //const [currentDeals, setCurrentDeals] = useState([]);
    const [last24HoursDeals, setLast24HoursDeals] = useState([]);
    const [todayAllDeals, setTodayAllDeals] = useState([]);
    const [yesterdayAllDeals, setYesterdayAllDeals] = useState([]);
    const [tdbYesterdayAllDeals, setTdbYesterdayAllDeals] = useState([]);
    const [customDayAllDeals, setCustomDayAllDeals] = useState([]);

    console.log(state.kLinesList);

    /*
    useEffect(() => {
        console.log('oneInch', oneInch);
        //allCurrencyCandlestickAnalysis(allCandleStickData);
        console.log('ALL DATA', allData);
    }, []);
    */

    useEffect(() => {
        dispatch({type: "MARKET_TYPE", payload: "SPOT"});
    }, [dispatch]);

    const filterAllPositionWithDate = useCallback((date) => {
        return state.allPositions.filter(p => new Date(p.opendate).toDateString() === date.toDateString()).sort((a, b) => b.opendate - a.opendate);
    }, [state.allPositions]);

    useEffect(() => {
        const activePositions = state.allPositions.filter(p => p.closeType === "NC").sort((a, b) => b.opendate - a.opendate);
        //console.log('Active Positions', activePositions);
        setLast24HoursDeals(activePositions);

        const currentDate = new Date();
        const todayStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

        setTodayAllDeals(filterAllPositionWithDate(todayStartDate));
        setIsLoading(false);

    }, [state.kLinesList, state.allPositions, filterAllPositionWithDate]);


    useEffect(() => {
        const currentDate = new Date();
        const todayStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
        const yesterdayStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1);
        const tdbYesterdayStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 2);

        setYesterdayAllDeals(filterAllPositionWithDate(yesterdayStartDate));
        setTdbYesterdayAllDeals(filterAllPositionWithDate(tdbYesterdayStartDate));
        setCustomDayAllDeals([]);
        setIsLoading(false);

    }, [state.allPositions, filterAllPositionWithDate]);


    const runAnalysisAndSaveData = useCallback(() => {
        allCurrencyCandlestickAnalysis(allSymbolCandleStickList, state.spotSymbols, state.analysisModel).then(data => {
            //console.log('Then içinden gelen data', data);
            dispatch({type: "SAVE_KLINES_LIST", payload: data});
        });
    }, [dispatch,  state.spotSymbols, state.analysisModel]);


    const startToFetchData = useCallback((index = 0) => {
        isButtonVisible && setIsButtonVisible(false);
        !isLoading && setIsLoading(true);
        //let triggerAnalysis = false;

        binanceService.getSpotKlines(state.spotSymbols[index].symbol, "15m", '1000')
            .then(res => {
                res.splice(res.length - 1, 1);
                allSymbolCandleStickList[state.spotSymbols[index].symbol] = res;

                if(symbolIndex === state.spotSymbols.length - 1) {
                    //setSymbolIndex(symbolIndex + 1);
                    setTimeout(() => {
                        runAnalysisAndSaveData();
                    }, 1000);
                    //triggerAnalysis = true;
                    //veri işlemlerini burada yap ondan sonra state'e ekle. async await dene.
                    //dispatch({type: "SAVE_KLINES_LIST", payload: allSymbolCandleStickList});
                }
                
                setSymbolIndex(symbolIndex + 1);

                //if(triggerAnalysis) {

                //}

            });
    }, [isButtonVisible, isLoading, symbolIndex, state.spotSymbols, runAnalysisAndSaveData]);

    useEffect(() => {
        //console.log('Symbol Index', symbolIndex);
        if(symbolIndex > 0 && symbolIndex !== state.spotSymbols.length) {
            startToFetchData(symbolIndex);
        }
        //if(symbolIndex === state.symbols.length) {
        //    runAnalysisAndSaveData();
        //}
    }, [symbolIndex, startToFetchData, state.spotSymbols.length]);

    const tabOnChange = (activeKey) => {

    }

    const ema200UpDownRender = (dealType) => {
        let iconRender = "";
        switch(dealType) {
            case "DownUp":
                iconRender = <div>
                    <CaretDownOutlined style={{ fontSize: '22px', color: '#f6465d' }} />
                    <CaretUpOutlined style={{ fontSize: '22px', color: '#0ecb81' }} />
                </div>;
                break;
            case "Down":
                iconRender = <div>
                    <CaretDownOutlined style={{ fontSize: '22px', color: '#f6465d' }} />
                </div>;
                break;
            case "UpDown":
                iconRender = <div>
                    <CaretUpOutlined style={{ fontSize: '22px', color: '#0ecb81' }} />
                    <CaretDownOutlined style={{ fontSize: '22px', color: '#f6465d' }} />
                </div>;
                break;
            case "Up":
                iconRender = <div>
                    <CaretUpOutlined style={{ fontSize: '22px', color: '#0ecb81' }} />
                </div>;
                break;
            default:
                iconRender = "";
        }

        return iconRender;
    }

    const renderOtherDeals = (dealsArray, col) => {
        let deals = [];
        if(dealsArray.length > 0) {
            deals = dealsArray.map(deal => {
                //const imageName = state.spotSymbols.find(x => x.symbol === deal.symbol)?.baseAsset?.toLowerCase();
                return (
                    <Col xs={12} sm={8} md={6} lg={6} xl={col} key={deal.key}>
                        <div className="other-deal-symbol">
                            <Link to={`/spot-currencies/${deal.symbol.replace('USDT', '').toLowerCase()}`}>
                                <img src={`${process.env.PUBLIC_URL}/images/crypto-logo/${'binance'}.svg`} alt="" />
                                <span><strong>{deal.symbol}</strong></span>
                                {deal.rsi && <span className="rsi">[ {deal.rsi} ]</span>}
                                {deal.type && <div className="ema200-up-down">{ema200UpDownRender(deal.type)}</div>}
                            </Link>
                        </div>
                    </Col>
                );    
            })
        }
        return deals;
    }

    const getCustomDayDeals = (date, dateString) => {
        //console.log(date, dateString);
        let startDate = new Date(date._d.getFullYear(), date._d.getMonth(), date._d.getDate());
        //let endDate = new Date(date._d.getFullYear(), date._d.getMonth(), date._d.getDate() + 1);
        //console.log(startDate, endDate);

        setCustomDayAllDeals(filterAllPositionWithDate(startDate));
        setIsLoading(false);
    }

    const changeAnalysisModel = ({ target: { value } }) => {
        //console.log(value);
        dispatch({type: "CHANGE_ANALYSIS_MODEL", payload: value});
    }

    return (
        <>
            {isLoading && <AnalysisLoading current={symbolIndex} total={state.spotSymbols.length} spin={state.currentDeals.length > 0 ? true : false} />}
            <div className='analysis container'>
                <Row gutter="16">
                {Object.keys(state.kLinesList).length > 0 && 
                    <>
                        <Col span={12}>
                            <CoinCurrentStatus marketType={"FUTURES"} symbol={"BTCUSDT"} />
                        </Col>
                        <Col span={12}>
                            <CoinCurrentStatus marketType={"FUTURES"} symbol={"ETHUSDT"} />
                        </Col>
                    </>
                    }
                    <Col span={24}>
                        {(isButtonVisible && Object.keys(state.kLinesList).length === 0) && state.marketType === "SPOT" && 
                            <Row>
                                <Col span={24} className="select-model">
                                    <p>Please Select Analysis Model!</p>
                                    <Radio.Group options={analysisModel} onChange={changeAnalysisModel} value={state.analysisModel} optionType="button" buttonStyle="solid" />
                                </Col>
                                <Col span={24}>
                                    <Button type="primary" size='large' className='btn-analysis' onClick={() => startToFetchData(0)}>Start to Spot Analysis</Button>
                                </Col>
                                {/*
                                <Col span={24} style={{marginBottom: 20}}>
                                    <Checkbox onChange={onChangeSimulator}>with Simulator</Checkbox>
                                </Col>                             
                                */}
                            </Row>     
                        }
                        <div className="deals">
                            <Tabs defaultActiveKey="1" type="card" size={"large"} onChange={tabOnChange}>
                                <TabPane tab={`Current Deals ( ${state.currentDeals.length} )`} key="1">
                                    {state.currentDeals && <CurrentDealsTable data={state.currentDeals} />}
                                </TabPane>
                                <TabPane tab={`Active Deals ( ${last24HoursDeals.length} )`} key="2">
                                    {last24HoursDeals && <Last24HoursDealsTable data={last24HoursDeals} />}
                                </TabPane>
                                <TabPane tab={`Today All Deals ( ${todayAllDeals.length} )`} key="3">
                                    {todayAllDeals && <AllDailyProfitLossTable data={todayAllDeals} />}
                                </TabPane>
                                <TabPane tab={`Yesterday All Deals ( ${yesterdayAllDeals.length} )`} key="4">
                                    {yesterdayAllDeals && <AllDailyProfitLossTable data={yesterdayAllDeals} />}
                                </TabPane>
                                <TabPane tab={`TDB Yesterday All Deals ( ${tdbYesterdayAllDeals.length} )`} key="5">
                                    {tdbYesterdayAllDeals && <AllDailyProfitLossTable data={tdbYesterdayAllDeals} />}
                                </TabPane>
                                <TabPane tab={`Custom Day All Deals ( ${customDayAllDeals.length} )`} key="6">
                                    <DatePicker size="large" onChange={getCustomDayDeals} style={{ marginBottom: 15}} />
                                    {customDayAllDeals && <AllDailyProfitLossTable data={customDayAllDeals} />}
                                </TabPane>
                            </Tabs>
                        </div>                     
                    </Col>
                    <Col span={24}>
                        <div className="other-deals">
                            <Tabs defaultActiveKey="0" type="card" size={"large"}>
                                <TabPane tab={`Ema 200 Deals ( ${state.currentEma200Deals.length} )`} key="0">
                                    <Row gutter="16">
                                        {state.currentEma200Deals && renderOtherDeals(state.currentEma200Deals, 4)}
                                    </Row>
                                </TabPane>
                                <TabPane tab={`Ema 100 Deals ( ${state.currentEma100Deals.length} )`} key="1">
                                    <Row gutter="16">
                                        {state.currentEma100Deals && renderOtherDeals(state.currentEma100Deals, 4)}
                                    </Row>
                                </TabPane>
                                <TabPane tab={`Above EMA 20 Deals ( ${state.currentEma20Deals.length} )`} key="2">
                                    <Row gutter="16">
                                        {state.currentEma20Deals && renderOtherDeals(state.currentEma20Deals, 3)}
                                    </Row>
                                </TabPane>
                                <TabPane tab={`Above RSI 30 Deals ( ${state.currentRsiUp30Deals.length} )`} key="3">
                                    <Row gutter="16">
                                        {state.currentRsiUp30Deals && renderOtherDeals(state.currentRsiUp30Deals, 4)}
                                    </Row>
                                </TabPane>
                                <TabPane tab={`Below RSI 30 Deals ( ${state.currentRsiDown30Deals.length} )`} key="4">
                                    <Row gutter="16">
                                        {state.currentRsiDown30Deals && renderOtherDeals(state.currentRsiDown30Deals, 4)}
                                    </Row>
                                </TabPane>
                            </Tabs>
                        </div>                     
                    </Col>
                </Row>
            </div>
        </>
    );
};


export default React.memo(SpotAnalysis);

/*
0: 1643223600000
1: "97.9700"
2: "103.7800"
3: "97.7300"
4: "103.5900"
5: "632860"
6: 1643227199999
7: "64244408.6990"
8: 54456
9: "338654"
10: "34380733.6690"
11: "0"


0: 1643223600000
1: "97.9700"
2: "104.4500"
3: "97.7300"
4: "102.4700"
5: "782514"
6: 1643227199999
7: "79770413.7290"
8: 68117
9: "403935"
10: "41160566.0590"
11: "0"



0: 1643223600000
1: "97.9700"
2: "104.4500"
3: "97.7300"
4: "100.6400"
5: "1040881"
6: 1643227199999
7: "105988501.6890"
8: 92328
9: "524255"
10: "53378373.3690"
11: "0"




0: 1637827200000
1: "205.9310"
2: "207.8000"
3: "203.6460"
4: "204.1340"
5: "170520"
6: 1637830799999
7: "35099793.9800"
8: 25499
9: "70383"
10: "14509038.0950"
11: "0"



0: 1637827200000
1: "205.9310"
2: "207.8000"
3: "203.6460"
4: "204.1340"
5: "170520"
6: 1637830799999
7: "35099793.9800"
8: 25499
9: "70383"
10: "14509038.0950"
11: "0"









0: 1643223600000
1: "97.9700"
2: "104.4500"
3: "97.4700"
4: "98.7900"
5: "1395653"
6: 1643227199999
7: "141252754.1640"
8: 121511
9: "686921"
10: "69540904.2790"
11: "0"



0: 1643220000000
1: "98.3300"
2: "99.2600"
3: "96.2900"
4: "97.9400"
5: "849309"
6: 1643223599999
7: "82550921.5800"
8: 81274
9: "410973"
10: "39969913.2400"
11: "0"





*/