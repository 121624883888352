import '../../App.scss';
import {Layout} from 'antd';
import Router from '../../router';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import { Context } from '../../store';
import { useCallback, useContext, useEffect, useState } from 'react';
//import websocket from '../../libs/websocket';
import Websocket from '../../libs/websocket-new';
import {createCandleStickSocketUrl, convertSocketMessageToCandleStick} from '../../libs/utility';
import {allCurrencyCandlestickFilter, allCurrencyLastCandlestickAnalysis, allCurrencyUpdateNewModelAnalysis} from '../../libs/analysis';
import InfoModal from '../../components/InfoModal';
import CalculatorModal from '../../components/CalculatorModal';
import AnalysisModelModal from '../../components/AnalysisModelModal';
import AnalysisLoading from '../../components/AnalysisLoading';

//let currentCandleSticks = {};

const alert = new Audio(process.env.PUBLIC_URL + '/sounds/linn.mp3');
//const vuvuzela = new Audio(process.env.PUBLIC_URL + '/sounds/vuvuzela.mp3');
const vikingHorn = new Audio(process.env.PUBLIC_URL + '/sounds/viking_horn.mp3');

function AuthApp() {
  const {state, dispatch} = useContext(Context);
  const [currentCandleSticks, setCurrentCandleSticks] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  //console.log('Auth App Render', state.kLinesList);

  const listenMessage = useCallback((message) => {   
    if(message.data.k.x) {
      //console.log('Socket Message', message);   
      setCurrentCandleSticks(prevState => ({
        ...prevState, 
        [message.data.s]: convertSocketMessageToCandleStick(message.data)
      }));
    }   
  }, []);

  useEffect(() => {
    //console.log('Current Deals State', state.currentDeals);
    //console.log(!!state.currentDeals?.find(c => c.status === 'OPEN LONG'));
    if(!!state.currentDeals?.find(c => c.status === 'OPEN LONG')) {
      vikingHorn.play(); 
    }
  }, [state.currentDeals]);

  useEffect(() => {
    //console.log('Current EMA 200 Deals State', state.currentEma200Deals);
    //console.log(!!state.currentDeals?.find(c => c.status === 'OPEN LONG'));
    if(state.currentEma200Deals.length > 0) {
      alert.play(); 
    }
  }, [state.currentEma200Deals]);

  useEffect(() => {
    if(Object.keys(state.kLinesList).length !== 0) {
      allCurrencyCandlestickFilter(state.kLinesList, state.symbols).then(data => {
        //console.log('CURRENT DEALS', data);
        dispatch({type: "UPDATE_CURRENT_DEALS", payload: data});
    });
    }
  }, [state.kLinesList, state.symbols, dispatch]);

  useEffect(() => {
    if(state.marketType === "FUTURES") {
      //console.log(Object.keys(currentCandleSticks).length, state.symbols.length);
      if(Object.keys(currentCandleSticks).length === state.symbols.length) {
        allCurrencyLastCandlestickAnalysis(state.kLinesList, currentCandleSticks, state.symbols, state.analysisModel, state.allPositions).then(data => {
          //console.log('işlenmiş güncel data', data);
          setCurrentCandleSticks({});  
          dispatch({type: "UPDATE_KLINES_LIST", payload: data});
          //alert.play();
        }); 
      }
    }
    else {
      if(Object.keys(currentCandleSticks).length === state.spotSymbols.length) {
        allCurrencyLastCandlestickAnalysis(state.kLinesList, currentCandleSticks, state.spotSymbols, state.analysisModel, state.allPositions).then(data => {
          //console.log('işlenmiş güncel data', data);
          setCurrentCandleSticks({});  
          dispatch({type: "UPDATE_KLINES_LIST", payload: data});
          //alert.play();
        }); 
      }
    }

  }, [currentCandleSticks, state.symbols, state.spotSymbols, state.marketType, dispatch, state.kLinesList, state.analysisModel]);

  useEffect(() => {
    //console.log('APP USEEFFECT');
    const coinSocket = new Websocket();
    if(state.startListenSocket) {
      let socketUrl;
        if(state.marketType === "FUTURES") {
          socketUrl = createCandleStickSocketUrl("FUTURES", state.symbols, '15m');
        }
        else {
          socketUrl = createCandleStickSocketUrl("SPOT", state.spotSymbols, '15m');
        }
        //console.log('START LISTEN SOCKET');
        coinSocket.open(socketUrl, listenMessage);
    }

    return () => {
      if(coinSocket.isSocketInitialized()) {
        coinSocket.close();
      }
    }
  }, [state.startListenSocket, listenMessage, state.symbols, state.spotSymbols, state.marketType]);

  const changeAnalysisModel = () => {
    //console.log('Change Analysis Model');
    dispatch({type: "SHOW_ANALYSIS_MODAL", payload: true});
  } 

  useEffect(() => {
    console.log('Yeni Değişen Model', state.analysisModel);
    dispatch({type: "SHOW_ANALYSIS_MODAL", payload: false});
    if(Object.keys(state.kLinesList).length !== 0) {
      setIsLoading(true);
      setTimeout(() => {
        allCurrencyUpdateNewModelAnalysis(state.kLinesList, state.analysisModel).then(data => {
          //console.log('Then içinden gelen data', data);
          dispatch({type: "CHANGE_KLINES_LIST", payload: data});
          setIsLoading(false)
          //console.log(data);
        });
      }, 1000);
    }

  }, [state.analysisModel, dispatch]);

  return (
    <div className="App">
      <Layout className='layout'>
        <Layout.Header>
          <Header onChangeModel={changeAnalysisModel} />
        </Layout.Header>
        <Layout.Content>
          {isLoading && <AnalysisLoading spin={true} />}
          <div className='site-content'>
            <Router />
          </div> 
        </Layout.Content>
        <Layout.Footer>
          <Footer />
        </Layout.Footer>
        <InfoModal />
        <CalculatorModal />
        <AnalysisModelModal />
      </Layout>
    </div>
  );
}

export default AuthApp;
