import React from 'react';
import {Routes, Route} from 'react-router-dom';
import Home from '../pages/Home';
import Calculator from '../pages/Calculator';
import FuturesAnalysis from '../pages/FuturesAnalysis';
import Futures from '../pages/Futures';
import FuturesDetails from '../pages/Futures/FuturesDetails';
import SpotAnalysis from '../pages/SpotAnalysis';
import Spot from '../pages/Spot';
import SpotDetails from '../pages/Spot/SpotDetails';
import ProfitLossStats from '../pages/ProfitLossStats';
import Simulator from '../pages/Simulator';
import EditSymbols from '../pages/EditSymbols';
import FuturesDetails2 from '../pages/Futures/FuturesDetails2';
import Mode15m from '../pages/Mode15m';

const Router = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/futures-analysis" element={<FuturesAnalysis />} />
            <Route path="/futures-currencies" element={<Futures />} />
            <Route path="/futures-currencies/:id" element={<FuturesDetails />} />
            <Route path="/spot-analysis" element={<SpotAnalysis />} />
            <Route path="/spot-currencies" element={<Spot />} />
            <Route path="/spot-currencies/:id" element={<SpotDetails />} />
            <Route path="/calculator" element={<Calculator />} />
            <Route path="/all-pnl" element={<ProfitLossStats />} />
            <Route path="/simulator" element={<Simulator />} />
            {/*<Route path="/edit-symbols" element={<EditSymbols />} />*/}
            {/*<Route path="/15m-mode" element={<Mode15m />} />*/}
        </Routes>
    );
};

export default Router;