import React, {useContext, useState, useEffect} from 'react';
import './style.scss';
import {Table, Tag, Col, Row, Statistic, Card} from 'antd';
import {epochToDate} from '../../libs/utility';
import {Context} from '../../store';
import { UpCircleOutlined, DownCircleOutlined, CloseCircleOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';

const AllDailyProfitLossTable = ({data}) => {
    const {state} = useContext(Context);
    const [totalValues, setTotalValues] = useState({
        totalPosProcess : 0,
        totalNegProcess : 0,
        totalProfits : 0,
        totalLoss: 0,
        mostProfit: 0,
        mostLoss: 0
    });

    useEffect(() => {
        if(data.length > 0) {
            let posArray = data.filter(x => x.highProfit > 1);
            let negArray = data.filter(x => x.highProfit <= 1);
            //let notrArray = data.filter(x => x.maxProfit < 1 && x.maxProfit > 0);
            let totalProfits = posArray.length > 0 ? posArray.length === 1 ? posArray[0].highProfit : posArray.map(x => x.highProfit).reduce((a, b) => a + b).toFixed(2) : 0;
            let totalLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].pnl : negArray.map(x => x.pnl).reduce((a, b) => a + b).toFixed(2) : 0;
            setTotalValues({
                totalPosProcess: posArray.length,
                totalNegProcess: negArray.length,
                totalNotrProcess: 0,
                totalProfits: totalProfits,
                totalLoss: totalLoss,
                pnlRatio: (Number(totalProfits) + Number(totalLoss)).toFixed(2),          
                mostProfit: posArray.length > 0 ? posArray.length === 1 ? posArray[0].highProfit : posArray.map(x => x.highProfit).reduce((a, b) => b > a ? b : a).toFixed(2) : 0,
                mostLoss: negArray.length > 0 ? negArray.length === 1 ? negArray[0].pnl : negArray.map(x => x.pnl).reduce((a, b) => b > a ? a : b).toFixed(2) : 0,
            });
        }
        else {
            setTotalValues({
                totalPosProcess : 0,
                totalNegProcess : 0,
                totalProfits : 0,
                totalLoss: 0,
                mostProfit: 0,
                mostLoss: 0
            });
        }

    }, [data]);

    const linkPrefix = state.marketType === "FUTURES" ? "futures" : "spot";

    const columns = [
        {
            title: 'Symbol',
            dataIndex: 'symbol',
            key: 'symbol',
            render: (symbol, record) => symbolBuilder(symbol, record),
            sorter: (a, b) => ('' + a.symbol).localeCompare(b.symbol),
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          render: (text, record) => tagBuilder(text, record),
          filters: [
              {
                  text: 'LONG',
                  value: 'OPEN LONG'
              },
              {
                  text: 'SHORT',
                  value: 'OPEN SHORT'
              }
          ],
          onFilter: (value, record) => record.status.indexOf(value) === 0
        },
        {
            title: 'Pos Type',
            dataIndex: 'positionType',
            key: 'positionType',
            render: (text, record) => posBuilder(record),
        },
        {
          title: 'Open Price',
          dataIndex: 'openprice',
          key: 'openprice',
          render: price => `$ ${price}`
        },
        {
            title: 'Close Price',
            dataIndex: 'closeprice',
            key: 'closeprice',
            render: price => `$ ${price}`
          },
        {
          title: 'Open Date',
          key: 'opendate',
          dataIndex: 'opendate',
          render: date => epochToDate(date).toLocaleString()
        },
        {
            title: 'Close Date',
            key: 'closedate',
            dataIndex: 'closedate',
            render: date => epochToDate(date).toLocaleString()
          },
        {
            title: 'Pass Time',
            key: 'time',
            dataIndex: 'time',
            render: (time, record) => `${time} ${time === 1 ? 'hour' : 'hours'}`,
            sorter: (a, b) => a.time - b.time,
        },
        {
            title: 'Reverse (C)',
            key: 'closeReverse',
            dataIndex: 'closeReverse',
            render: (closeReverse, record) => reverseBuilder(closeReverse, record.closeReverseIndex),
            sorter: (a, b) => a.closeReverse - b.closeReverse,
        },
        {
              title: 'Reverse (M)',
              key: 'maxReverse',
              dataIndex: 'maxReverse',
              render: (maxReverse, record) => reverseBuilder(maxReverse, record.maxReverseIndex),
              sorter: (a, b) => a.maxReverse - b.maxReverse,
        },
        {
            title: 'First Reverse',
            key: 'firstReverse',
            dataIndex: 'firstReverse',
            render: (firstReverse, record) => reverseBuilder(firstReverse, 1),
            sorter: (a, b) => a.firstReverse - b.firstReverse,
        },
        {
            title: 'Max Profit',
            key: 'maxProfit',
            dataIndex: 'maxProfit',
            render: (maxProfit, record) => reverseBuilder(maxProfit, record.highpriceIndex),
            sorter: (a, b) => a.maxProfit - b.maxProfit,
        },
        {
            title: 'High Profit',
            key: 'highProfit',
            dataIndex: 'highProfit',
            render: (highProfit, record) => reverseBuilder(highProfit, record.topNeedleIndex),
            sorter: (a, b) => a.highProfit - b.highProfit,
        },
        {
            title: 'Profit/Loss',
            key: 'pnl',
            dataIndex: 'pnl',
            render: (pnl, record) => `${pnl} %`,
            sorter: (a, b) => a.pnl - b.pnl,
        }
      ];

      const symbolBuilder = (symbol, record) => {
        const imageName = state.marketType === "FUTURES" ? state.symbols.find(x => x.symbol === symbol)?.baseAsset?.toLowerCase() : 'binance';
        return <div className="symbol">
            <Link to={`/${linkPrefix}-currencies/${symbol.replace('USDT', '').toLowerCase()}`}>
                <img src={`${process.env.PUBLIC_URL}/images/crypto-logo/${imageName}.svg`} alt="" />
                <span><strong>{symbol}</strong></span>
                {/*
                <span className={`risk ${record.riskLevel}`}>
                    {record.riskLevel === "star" && <StarFilled style={{color: '#17a5f9', fontSize: 16}}/>}
                </span>
                */}
            </Link>
        </div>
    }

    const tagBuilder = (text, record) => {
        let color = null;
        let icon = null;
        switch (text) {
            case 'LONG':
                color = "#87d068";
                icon = <UpCircleOutlined />;
                break;
            case 'SHORT':
                color = "#f50";
                icon = <DownCircleOutlined />;
                break;
            case 'EMPTY':
                color = "#000";
                icon = <CloseCircleOutlined />;
                break;
            default:
                color = "black";
                break;
        }
        return <div className='deals-tag'>
                    <Tag icon={icon} color={color}>{text.toUpperCase()}</Tag>
               </div>
    }

    const posBuilder = (record) => {
        return <div className="pos-type">
            <Tag color={"#87d068"}>{record.posType}</Tag>
            <Tag color={"#3b5081"}>{record.closeType}</Tag>
        </div>
    }

    const reverseBuilder = (reverse, index) => {
        return <div className="reverse">
            <span className="reverse-value">{reverse} %</span>
            {index > 0 && <span className="reverse-index">{index}</span>}
        </div>
    }

    return (
        <div className='all-daily-profit-loss-table'>
                <Row gutter={16} style={{marginBottom: 20}}>
                    <Col span={8}>
                        <Card title="Total Position">
                            <Statistic 
                                title="Profit"
                                value={totalValues.totalPosProcess}
                                precision={0}
                                valueStyle={{ color: '#3f8600' }}
                                prefix={''}
                                suffix="Process"
                                />
                            <Statistic
                                title="Loss"
                                value={totalValues.totalNegProcess}
                                precision={0}
                                valueStyle={{ color: '#cf1322' }}
                                prefix={''}
                                suffix="Process"
                                />
                            <Statistic
                                title="Notr"
                                value={totalValues.totalNotrProcess}
                                precision={0}
                                valueStyle={{ color: '#000' }}
                                prefix={''}
                                suffix="Process"
                                />
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card title="Total Profit / Loss">
                            <span className='pnl-ratio'>
                                {totalValues.pnlRatio} %
                            </span>
                            <Statistic 
                                title="Profit"
                                value={totalValues.totalProfits}
                                precision={2}
                                valueStyle={{ color: '#3f8600' }}
                                prefix={''}
                                suffix="%"
                                />
                            <Statistic
                                title="Loss"
                                value={totalValues.totalLoss}
                                precision={2}
                                valueStyle={{ color: '#cf1322' }}
                                prefix={''} 
                                suffix="%"
                                />
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card title="Most Profit / Loss">
                            <Statistic 
                                title="Profit"
                                value={totalValues.mostProfit}
                                precision={2}
                                valueStyle={{ color: '#3f8600' }}
                                prefix={''}
                                suffix="%"
                                />
                            <Statistic
                                title="Loss"
                                value={totalValues.mostLoss}
                                precision={2}
                                valueStyle={{ color: '#cf1322' }}
                                prefix={''}
                                suffix="%"
                                />
                        </Card>
                    </Col>
                </Row>
            <Table columns={columns} dataSource={data} bordered pagination={false} rowClassName={
                (record, index) => record.highProfit > 1 ? "soft-green" : "soft-red"
            } />
        </div>
    );
};


export default AllDailyProfitLossTable;