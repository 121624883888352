import { createContext, useState } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({children}) => {
    const [user, setUser] = useState({});
    const [privateKey, setPrivateKey] = useState("");

    const googleLogin = (authUser) => {
        setUser(authUser);
    }

    const googleLogout = () => {
        setUser({});
    }

    return (
        <AuthContext.Provider value={{user, privateKey, googleLogin, googleLogout, setPrivateKey}}>
            {children}
        </AuthContext.Provider>
    );
}