import { createContext, useReducer } from "react";
import reducer from "./reducer";
import Symbols from '../data/symbols.json';
import SpotSymbols from '../data/spot-symbols.json';

const simulatorConfig = {
    balance: 0,
    activeBalance: 0,
    leverage: 1,
    fixedEntryPrice: 0,
}

const initialState = {
    symbols: Symbols.symbols,
    spotSymbols: SpotSymbols.symbols,
    marketType: "FUTURES",
    startListenSocket: false,
    kLinesList: {},
    allPositions: [],
    currentDeals: [],
    currentEma20Deals: [],
    currentRsiUp30Deals: [],
    currentRsiDown30Deals: [],
    currentEma100Deals: [],
    currentEma200Deals: [],
    showCalculatorModal: false,
    showAnalysisModal: false,
    analysisModel: 'percent3',
    enabledSimulator: false,
    simulatorConfig: simulatorConfig
};

/*
const editedSymbols = Object.keys(Symbols.symbols).map(symbol => {
    console.log(Symbols.symbols[symbol]);
    Symbols.symbols[symbol].positionModel = "dema34 - dema89";
    Symbols.symbols[symbol].maturity = "L";
    Symbols.symbols[symbol].riskLevel = "green";

    return Symbols.symbols[symbol];
});

console.log(editedSymbols);
*/
export const Context = createContext(initialState);

export const Provider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <Context.Provider value={{state, dispatch}}>
            {children}
        </Context.Provider>
    );
}

