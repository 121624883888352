const reducer = (state, action) => {
    //console.log(action);
    switch (action.type) {
        case "MARKET_TYPE":
            return {
                ...state,
                marketType: action.payload
            }
        case "SAVE_KLINES_LIST":
            return {
                ...state,
                kLinesList: action.payload.candlesticks,
                allPositions: action.payload.positions,
                startListenSocket: true
            }
        case "CHANGE_KLINES_LIST":
            return {
                ...state,
                kLinesList: action.payload.candlesticks,
                allPositions: action.payload.positions,
            }
        case "UPDATE_KLINES_LIST":
            const updatedKlines = {...state.kLinesList};
            Object.keys(updatedKlines).map(kline => {
                const updatdSymbolArray = [...updatedKlines[kline]];
                updatdSymbolArray.push(action.payload.candlesticks[kline]);
                updatedKlines[kline] = updatdSymbolArray;
                return true;
            });
            return {
                ...state,
                kLinesList: {...updatedKlines},
                allPositions: action.payload.positions,
            }
        case "UPDATE_CURRENT_DEALS":
            return {
                ...state,
                currentDeals: action.payload.currentDeals,
                currentEma20Deals: action.payload.currentEma20Deals,
                currentRsiUp30Deals: action.payload.currentRsiUp30Deals,
                currentRsiDown30Deals: action.payload.currentRsiDown30Deals,
                currentEma100Deals: action.payload.currentEma100Deals,
                currentEma200Deals: action.payload.currentEma200Deals,
            }
        case "SHOW_CALCULATOR": 
            return {
                ...state,
                showCalculatorModal: action.payload
            }
        case "SHOW_ANALYSIS_MODAL": 
            return {
                ...state,
                showAnalysisModal: action.payload
            }
        case "CHANGE_ANALYSIS_MODEL": 
            return {
                ...state,
                analysisModel: action.payload
            }
        case "CHECK_SIMULATOR": 
            return {
                ...state,
                enabledSimulator: action.payload
            }
        default:
            return state;
    }
}

export default reducer;
