import React, {useState} from 'react';
import { InfoCircleFilled} from '@ant-design/icons';
import {Modal, Table, Tag} from 'antd';
import './style.scss';

const InfoModal = () => {
    const [showModal, setShowModal] = useState(false);

    const columns = [
        {
          title: 'Type',
          dataIndex: 'type',
          key: 'type',
          render: (text) => posBuilder(text)
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: name => name
        },
        {
          title: 'Description',
          dataIndex: 'desc',
          key: 'desc',
          render: desc => desc
        }
    ];

    const openPositions = [
        {
            key:1,
            type: 'OL-br',
            name: 'Open Long Boll & Rsi',
            desc: 'Son 3 mum kontol edilir. 2 önceki mum boll alt bandtan taşmış ve rsi değeri 30un altındadır. 1 önceki mum yeşildir, boll alt banttan geri girmiş ve rsi değeri 30un üstündedir. son mum yeşildir, boll alt bandın üstünde ve rsi değer 30un üstündedir.'
        },
        {
            key:2,
            type: 'OL-bb',
            name: 'Open Long Bollinger Bands',
            desc: 'son 3 mum kontrol edilir, 3 mum da yeşil olmalıdır. 2 önceki mumun bollinger band genişliği %3 ten azdır. son mumun bollinger band genişliği %3 ten büyüktür.'
        },
        {
            key: 3,
            type: 'OL-p3',
            name: 'Open Long Percent 3',
            desc: 'Son kapanan mum yeşil ve %3 büyüklüğündedir.'
        },
        {
            key: 4,
            type: 'OL-ex',
            name: 'Open Long Explosion',
            desc: "Al mumu %0.9 ile %1.5 arasında ve yeşil mumdur. Önceki 5 mum %1'den büyük değildir ve kırmızı ise açılış fiyatı, yeşil ise kapanış fiyatı ema50'sinin üzerindedir. Önceki 4 mum önceki 5. muma göre maximum %1 yukarı veya %1 aşağı salınım yapmıştır. son mumun ema20'si ema50, ema100 ve ema200'ün üzerindedir. Son mum kapanışı boll orta bandın üzerindedir. son mumun boll üst bandı bir önceki mumun boll üst bandından yukarıdadır. son mum rsi değeri 55 üzerindedir."
        },
        {
            key: 5,
            type: 'OL-ex45',
            name: 'Open Long Explosion Rsi 45-60',
            desc: "Bütün koşullar explosion ile aynıdır. Yalnızda son mum rsi değeri 45 ile 60 arasındadır."
        },
        {
            key: 6,
            type: 'OL-ex50',
            name: 'Open Long Explosion Rsi 50-65',
            desc: "Bütün koşullar explosion ile aynıdır. Yalnızda son mum rsi değeri 50 ile 65 arasındadır."
        },
        {
            key: 7,
            type: 'OL-eL',
            name: 'Open Long Explosion (Loose)',
            desc: "Al mumu %0.9 üzerinde ve yeşil mumdur. Son mum kapanışı, ema20'nin üzerindedir. Son mum kapanışı boll orta bandın üzerindedir. son mumun boll üst bandı bir önceki mumun boll üst bandından yukarıdadır. Önceki 5 mum %1'den büyük değildir. son mum rsi değeri 55 üzerindedir."
        },
        {
            key: 8,
            type: 'OL-eT',
            name: 'Open Long Explosion (Tight)',
            desc: "Al mumu %1.1 ile %1.5 arasında ve yeşil mumdur. Önceki 5 mumum tamamı aynı renk olamaz. Önceki 5 mumum en yüksek ve en düşük fiyatları arasında %1'den az fark vardır. Önceki 5 mum %0.7'den büyük değildir ve kırmızı ise açılış fiyatı, yeşil ise kapanış fiyatı ema50'sinin üzerindedir. son mumun ema20'si ema50, ema100 ve ema200'ün üzerindedir. Son mumun kapanışı ema20'den, açılışı ise ema50'den büyüktür. Son mum kapanışı boll orta bandın üzerindedir. son mumun boll üst bandı bir önceki mumun boll üst bandından yukarıdadır. son mum rsi değeri 50 ile 63 arasındadır."
        }
    ]

    const closePositions = [
        {
            key: 1,
            type: 'CP-br',
            name: 'Close Position Boll & Rsi',
            desc: 'Sadece OL-br ile açılan pozisyonlarda geçerli olur. Alt boll bandından kırmızı taşan mum gelirse kapanır.'
        },
        {
            key: 2,
            type: 'CP-h(x)',
            name: 'Close Position High Price Percent (x)',
            desc: 'Açık poziyonun ulaştığı en yüksek fiyattan %(x) geri düştüğünde sistem bu model ile pozisyonu kapatır.'
        },
        {
            key: 3,
            type: 'CP-r2',
            name: 'Close Position Red Kline Percent 2',
            desc: 'Tek mumda %2 lik sert kırmızı mum gelirse sistem bu model ile pozisyonu kapatır.'
        },
        {
            key: 4,
            type: 'CP-o(x)',
            name: 'Close Position Open Price Percent (x)',
            desc: 'Pozisyon açılından itibaren herhangi bir artış yaşanmadıysa, Açılış fiyatından %(x) geri düştüğünde sistem bu model ile pozisyonu kapatır.'
        },
        {
            key: 5,
            type: 'NC',
            name: 'Not Closed',
            desc: 'Henüz kapanmamış pozisyondur.'
        }
    ]    

    // OL-br, OL-p3, OL-bb
    // CP-br, CP-h4, CP-r2, CP-o3


    const openModal = () => {
        setShowModal(!showModal);
    }

    const posBuilder = (text) => {
        let color = text.includes('OL') ? "#87d068" : "#3b5081";
        return <div className="pos-type">
            <Tag color={color}>{text}</Tag>
        </div>
    }

    const posInfoModal = <Modal title="Position Info" visible={showModal} footer={null} onCancel={openModal} width={800}>
            <h4>Open Long Model</h4>
            <Table columns={columns} dataSource={openPositions} bordered pagination={false} />
            <hr />
            <h4>Close Position Model</h4>
            <Table columns={columns} dataSource={closePositions} bordered pagination={false} />
        </Modal>

    return (
        <>
        <div className="info-modal-component">
            <div className="info-box" onClick={openModal}>
                <InfoCircleFilled style={{fontSize: 30, color:"#39a9f9"}} className="info-icon" />
            </div>
        </div>
        {posInfoModal}
        </>
    );
};

export default InfoModal;