import { Table } from 'antd';
import React from 'react';
import './style.scss';

const ProfitTable = ({data}) => {

    const columns = [
        {
          title: 'Stop',
          dataIndex: 'percent',
          key: 'percent',
          render: percent => <span>{percent} %</span>,
        },
        {
          title: 'Profit Position',
          dataIndex: 'profitPos',
          key: 'profitPos',
          render: profitPos => <span style={{color: '#3f8600'}}>{profitPos} Process</span>,
          sorter: (a, b) => a.totalPosProcess - b.totalPosProcess
        },
        {
            title: 'Loss Position',
            dataIndex: 'lossPos',
            key: 'lossPos',
            render: lossPos => <span style={{color: '#cf1322'}}>{lossPos} Process</span>,
            sorter: (a, b) => a.totalNegProcess - b.totalNegProcess,
        },
        {
            title: 'Total Profits',
            dataIndex: 'totalProfit',
            key: 'totalProfit',
            render: totalProfit => <span style={{color: '#3f8600'}}>{totalProfit} %</span>,
            sorter: (a, b) => a.totalProfits - b.totalProfits,
        },
        {
            title: 'Total Loss',
            dataIndex: 'totalLoss',
            key: 'totalLoss',
            render: totalLoss => <span style={{color: '#cf1322'}}>{totalLoss} %</span>,
            sorter: (a, b) => a.totalLoss - b.totalLoss,
        },
        {
            title: 'Total PNL Ratio',
            dataIndex: 'pnlRatio',
            key: 'pnlRatio',
            render: pnlRatio => <span>{pnlRatio} %</span>,
            sorter: (a, b) => a.pnlRatio - b.pnlRatio,
        }
    ];

    return (
        <div className='profit-rates-table'>
            <Table columns={columns} dataSource={data} bordered pagination={false} />
        </div>
    );
};

export default ProfitTable;